import { graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { isDefined } from "../../isDefined.ts";

const CalculatePlanGivenSlotQuery = graphql(`
  query calculatePlanGivenSlotByParams(
    $endedAtWeekUtc: String!
    $lessonsPerWeek: Int!
    $startedAtWeekUtc: String!
  ) {
    calculatePlanGivenSlotByParams(
      endedAtWeekUtc: $endedAtWeekUtc
      lessonsPerWeek: $lessonsPerWeek
      startedAtWeekUtc: $startedAtWeekUtc
    )
  }
`);
export function useCalculatePlanGivenSlot({
  startedAtWeekUtc,
  endedAtWeekUtc,
  lessonsPerWeek,
}: Partial<{
  startedAtWeekUtc: string;
  endedAtWeekUtc: string;
  lessonsPerWeek: number;
}>) {
  const { data, loading } = useQuery(CalculatePlanGivenSlotQuery, {
    variables: {
      startedAtWeekUtc: startedAtWeekUtc as string,
      endedAtWeekUtc: endedAtWeekUtc as string,
      lessonsPerWeek: lessonsPerWeek as number,
    },
    skip:
      !isDefined(startedAtWeekUtc) ||
      !isDefined(endedAtWeekUtc) ||
      !isDefined(lessonsPerWeek),
    fetchPolicy: "cache-and-network",
  });

  return {
    calculatedGivenSlot: data?.calculatePlanGivenSlotByParams,
    loading,
  };
}
