import { getFragmentData, graphql } from "../../gql";
import { useQuery } from "@apollo/client";
import { UserFragment } from "../UserFragment.ts";
import { Dayjs } from "dayjs";

const GetUsersEligibleForWarranty = graphql(`
  query getUsersEligibleForWarranty(
    $plansEndBefore: String
    $plansEndAfter: String
  ) {
    getUsersEligibleForWarranty(
      plansEndBefore: $plansEndBefore
      plansEndAfter: $plansEndAfter
    ) {
      ...UserFragment
    }
  }
`);

function getPlanEndBeforeString(date: Dayjs | undefined): string | undefined {
  if (!date) return undefined;
  return date.endOf("week").utc(true).toISOString();
}

function getPlanEndAfterString(date: Dayjs | undefined): string | undefined {
  if (!date) return undefined;
  return date.startOf("week").utc(true).toISOString();
}
export function useUsersEligibleForWarranty({
  plansEndBefore,
  plansEndAfter,
}: {
  plansEndBefore?: Dayjs;
  plansEndAfter?: Dayjs;
} = {}) {
  const pEndAfter = getPlanEndAfterString(plansEndAfter);
  const pEndBefore = getPlanEndBeforeString(plansEndBefore);
  const { data, loading } = useQuery(GetUsersEligibleForWarranty, {
    variables: {
      plansEndAfter: pEndAfter,
      plansEndBefore: pEndBefore,
    },
  });

  return {
    loading,
    users:
      getFragmentData(UserFragment, data?.getUsersEligibleForWarranty) ?? [],
  };
}
