import { Layout } from "../../UI/Layout/Layout.tsx";
import { GeneralAvailabilityReportChart } from "./GeneralAvailabilityReportChart.tsx";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { Card } from "../../UI/Card.tsx";
import { Select } from "@normasteaching/norma-ui";
import { useState } from "react";
import dayjs from "dayjs";
import { useAvailabilityEntries } from "./useAvailabilityEntries.ts";
import { SpinnerContainer } from "../../UI/Loading/SpinnerContainer.tsx";
import { MonthKey } from "./MonthKey.tsx";
import { generateMonthOptions } from "./generateMonthOptions.tsx";
import { MissingSlotsToBookGraph } from "./MissingSlotsToBookGraph.tsx";

export const AvailabilityReportScreenPath = "/availability/report";
export function AvailabilityReportScreen() {
  const options = generateMonthOptions();
  const [selectedMonth, setSelectedMonth] = useState<string>(options[0].id);

  const monthKey = MonthKey.fromString(selectedMonth);
  const fromDate = dayjs()
    .month(monthKey.month)
    .year(monthKey.year)
    .startOf("month")
    .toDate();

  const toDate = dayjs()
    .month(monthKey.month)
    .year(monthKey.year)
    .endOf("month")
    .toDate();

  const { entries, isLoading } = useAvailabilityEntries({
    fromDate,
    toDate,
  });

  return (
    <Layout>
      <div className={"space-y-4"}>
        <Card>
          <ScreenTitleBlock
            title={"Availability report"}
            subTitle={
              entries.length > 0
                ? `Data updated on ${dayjs(entries[0].reportDate).format("dddd DD MMM YYYY [at] HH:mm")}`
                : undefined
            }
          />
        </Card>
        <Card>
          <div>
            <Select
              options={options}
              value={selectedMonth}
              onChange={(v) => {
                setSelectedMonth(v ?? options[0].id);
              }}
            />
          </div>
          <SpinnerContainer loading={isLoading}>
            <div className={"flex items-center justify-center"}>
              <GeneralAvailabilityReportChart entries={entries} />
            </div>
          </SpinnerContainer>
        </Card>

        <Card>
          <MissingSlotsToBookGraph />
        </Card>
      </div>
    </Layout>
  );
}
