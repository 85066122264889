import { getFragmentData, graphql } from "../../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { GetFreezableWeeksResponseFragment } from "./GetFreezableWeeksResponseFragment.ts";

export const GetUserFreezableWeeksQuery = graphql(`
  query getFreezableWeeksByUser($userId: String!) {
    getFreezableWeeksByUser(userId: $userId) {
      ...GetFreezableWeeksResponse
    }
  }
`);

export function useUserFreezableWeeks(userId: string) {
  const { data, loading } = useQuery(GetUserFreezableWeeksQuery, {
    variables: {
      userId,
    },
  });

  return {
    freezableWeeksResponse: getFragmentData(
      GetFreezableWeeksResponseFragment,
      data?.getFreezableWeeksByUser,
    ),
    loading,
  };
}
