import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { FranchiseeForm, FranchiseeFormInputs } from "./FranchiseeForm.tsx";
import { useCreateFranchisee } from "./useCreateFranchisee.ts";
import { useNavigate } from "react-router-dom";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllFranchisees } from "./useToAllFranchisees.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
import { Card } from "../UI/Card.tsx";

export const CreateFranchiseeScreenPath = "/franchisees/new";

export function CreateFranchiseeScreen() {
  const { createFranchisee } = useCreateFranchisee();
  const navigate = useNavigate();
  const { show, title, description, showPopup } = useMessagePopupController(
    4 * 1000,
  );
  const { toAllFranchisees } = useToAllFranchisees();

  async function createNewFranchisee(franchisee: FranchiseeFormInputs) {
    try {
      await createFranchisee({
        email: franchisee.email,
        givenName: franchisee.givenName,
        familyName: franchisee.familyName,
        phoneNumber: {
          countryCode: franchisee.phoneNumber.countryCode,
          number: franchisee.phoneNumber.number,
        },
      });
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message ?? "Unknown error");
      return;
    }
    navigate(-1);
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem
                  onClick={toAllFranchisees}
                  title={"Teachers"}
                />
                <BreadCrumbsItem title={"New teacher"} />
              </BreadCrumbsWrapper>
            }
            title={"New teacher"}
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <FranchiseeForm onSave={createNewFranchisee} />
          </Card>
        </div>
      </Layout>
    </>
  );
}
