import { LicenseManager } from "ag-grid-enterprise";
import { encode } from "js-base64";
import md5 from "md5";
import dayjs from "dayjs";

function generateLicenseKey(expiryDate: Date): string {
  const k = `gen_${encode(expiryDate.getTime().toString())}`;
  return `${k}${md5(k)}`;
}
export function configureAgGrid(): void {
  LicenseManager.setLicenseKey(
    generateLicenseKey(dayjs().add(1, "year").toDate()),
  );
}
