import { getFragmentData, graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";
import { ClassRoomWithoutParticipantFragment } from "../../ClassRoom/ClassRoomWithoutParticipantFragment.ts";
import { ClassRoomWithoutParticipantDto } from "../../gql/graphql.ts";

const GetTeacherClassRoomsQuery = graphql(`
  query getTeacherClassRooms(
    $timezone: String!
    $teacherId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    getTeacherClassRooms(
      timezone: $timezone
      teacherId: $teacherId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ...ClassRoomWithoutParticipant
    }
  }
`);
export function useTeacherClassRooms({
  fromDate,
  teacherId,
  toDate,
}: {
  teacherId: string;
  fromDate: Dayjs;
  toDate: Dayjs;
}) {
  const formatDate = "YYYY-MM-DD";
  const { data, loading, refetch } = useQuery(GetTeacherClassRoomsQuery, {
    variables: {
      fromDate: fromDate.format(formatDate),
      teacherId,
      toDate: toDate.format(formatDate),
      timezone: dayjs.tz.guess(),
    },
  });

  return {
    classRooms:
      (getFragmentData(
        ClassRoomWithoutParticipantFragment,
        data?.getTeacherClassRooms,
      ) as ClassRoomWithoutParticipantDto[]) ?? [],
    loading,
    refetch,
  };
}
