import { Layout } from "../../UI/Layout/Layout.tsx";
import { Card } from "../../UI/Card.tsx";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { BreadCrumbsWrapper } from "../../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllUsers } from "../useToAllUsers.ts";
import { useToAllUserClassRoomPlan } from "./useToAllUserClassRoomPlan.ts";
import { useUserById } from "../useUserById.ts";
import { FullPageLoading } from "../../UI/Loading/FullPageLoading.tsx";
import { useUserIdParamsOrThrow } from "../useUserIdParamsOrThrow.ts";
import { usePlanIdParamsOrThrow } from "./usePlanIdParamsOrThrow.ts";
import { usePlanByIdAndUserId } from "./usePlanByIdAndUserId.ts";
import { useUpdateUserClassRoomPlan } from "./useUpdateUserClassRoomPlan.ts";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import {
  UpdateUserClassRoomPlanForm,
  UpdateUserClassRoomPlanFormInputs,
} from "./UpdateUserClassRoomPlanForm.tsx";

export const UpdateClassRoomPlanScreenPath =
  "/users/:userId/class-room-plans/:planId/update";

export function UpdateClassRoomPlanScreen() {
  const { userId } = useUserIdParamsOrThrow();
  const { planId } = usePlanIdParamsOrThrow();
  const { toAllUsers } = useToAllUsers();
  const { toAllUserClassRoomPlan } = useToAllUserClassRoomPlan();
  const { user, loading: loadingUser } = useUserById(userId);
  const { plan, loading: loadingPlan } = usePlanByIdAndUserId({
    planId,
    userId,
  });
  const { updateUserClassRoomPlan } = useUpdateUserClassRoomPlan();
  const { show, showPopup, title, description } = useMessagePopupController(
    5 * 1000,
  );
  async function updatePlan(data: UpdateUserClassRoomPlanFormInputs) {
    try {
      await updateUserClassRoomPlan({
        planId: planId,
        planToRecoverId: data.planToRecoverId ?? undefined,
        warrantyConfig: data.planToRecoverId
          ? undefined
          : data.warrantyConfig
            ? {
                enabled: data.warrantyConfig.enabled ?? false,
                minAttendedLessons: data.warrantyConfig.minAttendedLessons,
              }
            : undefined,
      });
    } catch (e) {
      const error = e as Error;
      showPopup("Error", error?.message ?? "Unknown error");
    }
  }

  const loading = loadingUser || loadingPlan;
  if (loading) return <FullPageLoading />;
  if (!user) return null; //TODO return not found
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={`Update plan`}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllUsers} title={"Users"} />
                <BreadCrumbsItem
                  onClick={() => toAllUserClassRoomPlan(userId)}
                  title={`${user.givenName}'s active plans`}
                />
                <BreadCrumbsItem title={"Update plan"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <Card>
          <UpdateUserClassRoomPlanForm
            defaultValues={{
              planToRecoverId: plan?.planToRecoverId ?? undefined,
              warrantyConfig: plan?.warrantyConfig
                ? {
                    enabled: plan.warrantyConfig.enabled,
                    minAttendedLessons:
                      plan.warrantyConfig.minAttendedLessons ?? undefined,
                  }
                : undefined,
            }}
            onSave={updatePlan}
            userId={userId}
            planId={planId}
          />
        </Card>
      </Layout>
    </>
  );
}
