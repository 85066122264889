import { useCallback, useState } from "react";

export function useWithLoading<T = void>() {
  const [loading, setLoading] = useState(false);

  const withLoading = useCallback(async (exec: () => Promise<T>) => {
    try {
      setLoading(true);
      return await exec();
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    withLoading,
  };
}
