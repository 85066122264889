import { getFragmentData, graphql } from "../../gql/index.ts";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "@apollo/client";
import { AvailabilitySlotFragment } from "./AvailabilitySlotFragment.ts";
import { AvailabilitySlotDto } from "../../gql/graphql.ts";

export const GetTeacherAvailabilityQuery = graphql(`
  query getAvailabilityByTeacherForWeek(
    $fromDate: String!
    $toDate: String!
    $teacherId: String!
    $timezone: String!
  ) {
    getAvailabilityByTeacherForWeek(
      fromDate: $fromDate
      toDate: $toDate
      teacherId: $teacherId
      timezone: $timezone
    ) {
      ...AvailabilitySlotFragment
    }
  }
`);
export function useTeacherAvailability({
  fromDate,
  toDate,
  teacherId,
}: {
  teacherId: string;
  fromDate: Dayjs;
  toDate: Dayjs;
}) {
  const formatString = "YYYY-MM-DD";

  const { data, loading, refetch } = useQuery(GetTeacherAvailabilityQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      teacherId,
      fromDate: fromDate.format(formatString),
      toDate: toDate.format(formatString),
      timezone: dayjs.tz.guess(),
    },
  });

  return {
    slots:
      (getFragmentData(
        AvailabilitySlotFragment,
        data?.getAvailabilityByTeacherForWeek,
      ) as AvailabilitySlotDto[]) ?? [],
    loading,
    refetch,
  };
}
