import { PropsWithChildren } from "react";
import { Menu } from "@headlessui/react";

type Props = PropsWithChildren<{
  onClick: () => void;
}>;
export function DropDownItem({ children, onClick }: Props) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={onClick}
          className={`${
            active ? "bg-primary text-white" : "text-slate-600"
          } group flex w-full items-center rounded-md px-2 py-2 text-sm whitespace-nowrap`}
        >
          {children ?? null}
        </button>
      )}
    </Menu.Item>
  );
}
