import { graphql } from "../../gql/index.ts";

export const AvailabilitySlotFragment = graphql(`
  fragment AvailabilitySlotFragment on AvailabilitySlotDto {
    slotId
    startDateUtc
    endDateUtc
    slotTimezone
  }
`);
