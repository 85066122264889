import { graphql } from "../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetUserFrozenWeeksQuery } from "./FreezeWeek/useUserFrozenWeeks.ts";
import { GetUserFreezableWeeksQuery } from "./FreezeWeek/useUserFreezableWeeks.ts";

const DeleteClassRoomPlanMutation = graphql(`
  mutation deleteClassRoomPlan($planId: String!) {
    deleteClassRoomPlan(planId: $planId)
  }
`);
export function useDeleteClassRoomPlan() {
  const [deleteClassRoomPlanMutation, { loading }] = useMutation(
    DeleteClassRoomPlanMutation,
    {
      refetchQueries: [GetUserFrozenWeeksQuery, GetUserFreezableWeeksQuery],
    },
  );

  const deleteClassRoomPlan = useCallback(
    async (planId: string) => {
      await deleteClassRoomPlanMutation({
        variables: {
          planId,
        },
      });
    },
    [deleteClassRoomPlanMutation],
  );

  return { deleteClassRoomPlan, loading };
}
