import { useClassRoomIdOrThrow } from "./useClassRoomIdOrThrow.ts";
import { useClassRoomById } from "./useClassRoomById.ts";
import { Layout } from "../UI/Layout/Layout.tsx";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useNavigate } from "react-router-dom";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import dayjs from "dayjs";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { Modal } from "../UI/Modal/Modal.tsx";
import { useState } from "react";
import { TextAreaInput } from "../UI/TextAreaInput.tsx";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { useAddParticipantsToClassRoom } from "./useAddParticipantsToClassRoom.ts";
import EmailValidator from "email-validator";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
import {
  EllipsisHorizontalIcon,
  TrashIcon,
} from "@heroicons/react/24/outline/index.js";
import { DropDownItem } from "../UI/DropDown/DropDownItem.tsx";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import { useRemoveParticipantFromClassRoom } from "./useRemoveParticipantFromClassRoom.ts";
import { Card } from "../UI/Card.tsx";
import { SwapTeacherModal } from "./SwapTeacherModal.tsx";
import { useAllFranchisees } from "../Franchisee/useAllFranchisees.ts";
import { AgGridClassRoomListPath } from "./AgGridClassRoomListScreen.tsx";

export const ClassRoomDetailScreenPath = "/class-rooms/:classRoomId";

export function ClassRoomDetailScreen() {
  const { classRoomId } = useClassRoomIdOrThrow();
  const { classRoom, loading, refetch } = useClassRoomById(classRoomId);
  const navigate = useNavigate();
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const { removeParticipantFromClassRoom } =
    useRemoveParticipantFromClassRoom();
  const { addParticipantsToClassRoom, loading: addingParticipantToClassRoom } =
    useAddParticipantsToClassRoom();
  const [emailToAddError, setEmailToAddError] = useState("");
  const [userEmailsToAdd, setUserEmailsToAdd] = useState("");
  const [showSwapTeacherModal, setShowSwapTeacherModal] = useState(false);
  const { franchisees } = useAllFranchisees();
  const {
    show,
    title: errorTitle,
    description,
    showPopup,
  } = useMessagePopupController(4 * 1000);
  function toAllClassRooms() {
    navigate(AgGridClassRoomListPath);
  }

  function getEmailsToAdd() {
    return userEmailsToAdd
      .split("\n")
      .map((e) => e.trim())
      .filter((s) => s.length > 0);
  }
  function validateEmails(): {
    isValid: boolean;
    emails: string[];
  } {
    const emails = getEmailsToAdd();
    if (emails.length <= 0) {
      setEmailToAddError("You must specify at least on email");
      return { isValid: false, emails: [] };
    }
    for (const email of emails) {
      if (!EmailValidator.validate(email)) {
        setEmailToAddError(`Email is invalid: ${email}`);
        return { isValid: false, emails: [] };
      }
    }
    setEmailToAddError("");
    return { isValid: true, emails };
  }

  async function addParticipants() {
    const { isValid, emails } = validateEmails();
    if (!isValid) return;
    try {
      await addParticipantsToClassRoom({
        classRoomId,
        emails,
      });
      setShowAddParticipantModal(false);
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message ?? "Unknown error");
      throw e;
    }
  }

  const formatString = "DD MMM YYYY [at] HH:mm";
  const startedInPast = dayjs(classRoom?.startedAtUtc).isBefore(dayjs());
  async function confirmRemoveUser(p: {
    id: string;
    givenName: string;
    familyName: string;
  }) {
    if (
      window.confirm(
        `Are you sure you want to remove ${p.givenName} ${p.familyName} from this classroom?`,
      )
    ) {
      try {
        const res = await removeParticipantFromClassRoom({
          userId: p.id,
          classRoomId,
        });
        if (res.deletedClassRoom) {
          return navigate(-1);
        } else {
          await refetch();
        }
      } catch (e) {
        const error = e as ApolloError;
        showPopup("Error", error?.message ?? "Unknown error");
        throw e;
      }
    }
  }
  if (!loading && !classRoom) {
    return null; //TODO return not found
  }
  const title = loading
    ? ""
    : `Classroom on ${dayjs(classRoom?.startedAtUtc).format(formatString)} with level ${classRoom?.speakingLevel}`;

  return (
    <>
      {classRoom ? (
        <SwapTeacherModal
          onChanged={async () => {
            await refetch();
          }}
          isOpen={showSwapTeacherModal}
          onClose={() => setShowSwapTeacherModal(false)}
          allTeachers={franchisees}
          classRoom={classRoom}
        />
      ) : null}
      <ErrorMessagePopup
        title={errorTitle}
        description={description}
        show={show}
      />
      <Modal
        isOpen={showAddParticipantModal}
        onClose={() => setShowAddParticipantModal(false)}
        title={"Add participants"}
      >
        <InputWithLabel
          smallText={
            "Enter the emails of the users you want to add to the class, one per line"
          }
          label={"Email"}
        >
          <TextAreaInput
            errorMessage={emailToAddError}
            value={userEmailsToAdd}
            onChange={(e) => {
              setUserEmailsToAdd(e.target.value);
              setEmailToAddError("");
            }}
            placeholder={"email1@test.com\nemail2@test.com\n..."}
          />
        </InputWithLabel>
        <div className={"mt-2 flex justify-end"}>
          <PrimaryButton
            label={"Add"}
            loading={addingParticipantToClassRoom}
            onClick={addParticipants}
          />
        </div>
      </Modal>
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={title}
            subTitle={
              classRoom ? (
                <div>
                  <p>
                    Teacher: {classRoom.teacher.givenName}{" "}
                    {classRoom.teacher.familyName} -{" "}
                    <a
                      className={"text-blue-400 underline cursor-pointer"}
                      href={`mailto:classRoom.teacher.email`}
                    >
                      {classRoom.teacher.email}
                    </a>{" "}
                    -{" "}
                    <a
                      className={"text-blue-400 underline cursor-pointer"}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowSwapTeacherModal(true);
                      }}
                    >
                      Change teacher
                    </a>
                  </p>
                  <p>
                    Meeting uri:{" "}
                    <a
                      className={"text-blue-400 underline cursor-pointer"}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      href={classRoom.conferenceUri}
                    >
                      {classRoom.conferenceUri}
                    </a>{" "}
                  </p>
                  <p>
                    Feedback url:{" "}
                    <a
                      className={"text-blue-400 underline cursor-pointer"}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      href={classRoom.feedbackUrl}
                    >
                      {classRoom.feedbackUrl}
                    </a>{" "}
                  </p>
                  <p>
                    Feedback history url:{" "}
                    <a
                      className={"text-blue-400 underline cursor-pointer"}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      href={classRoom.feedbackReportUrl}
                    >
                      {classRoom.feedbackReportUrl}
                    </a>{" "}
                  </p>
                </div>
              ) : undefined
            }
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem
                  onClick={toAllClassRooms}
                  title={"Classrooms"}
                />
                <BreadCrumbsItem title={title} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            {loading ? (
              <CenteredLoading />
            ) : (
              <div className={"space-y-2"}>
                <h2 className={"font-bold"}>
                  Participants ({classRoom?.participants.length}/
                  {classRoom?.capacity})
                </h2>
                <div className={"space-y-2"}>
                  {classRoom?.participants.map((p) => {
                    return (
                      <div
                        className={
                          "p-2 rounded-lg bg-slate-100 border border-slate-200 flex items-center justify-between space-x-2"
                        }
                        key={p.id}
                      >
                        <div>
                          <p className={"flex-1"}>
                            {p.givenName} {p.familyName} - {p.email}
                          </p>
                        </div>
                        <div>
                          <DropDown
                            activator={
                              <EllipsisHorizontalIcon
                                className={
                                  "text-slate-800 w-5 h-5 group-hover:text-white"
                                }
                              />
                            }
                          >
                            <DropDownItem onClick={() => confirmRemoveUser(p)}>
                              <TrashIcon className={"w-5 h-5 mr-2"} />
                              Remove
                            </DropDownItem>
                          </DropDown>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={"flex justify-end"}>
                  <PrimaryButton
                    disabled={startedInPast}
                    disabledReason={
                      startedInPast
                        ? "You can't add participants to a class that has already started."
                        : undefined
                    }
                    onClick={() => setShowAddParticipantModal(true)}
                    label={"Add participants"}
                  />
                </div>
              </div>
            )}
          </Card>
        </div>
      </Layout>
    </>
  );
}
