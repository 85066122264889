import { graphql } from "../../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { FrozenWeekInput } from "../../../gql/graphql.ts";
import { GetUserFrozenWeeksQuery } from "./useUserFrozenWeeks.ts";
import { GetUserFreezableWeeksQuery } from "./useUserFreezableWeeks.ts";
import { GetUserClassRoomPlansQuery } from "../useUserClassRoomPlans.ts";

const FreezeUserWeekMutation = graphql(`
  mutation addFrozenWeekToUser($week: FrozenWeekInput!, $userId: String!) {
    addFrozenWeekToUser(week: $week, userId: $userId)
  }
`);

export function useFreezeUserWeek() {
  const [freezeUserWeekMutation, { loading }] = useMutation(
    FreezeUserWeekMutation,
    {
      refetchQueries: [
        GetUserFrozenWeeksQuery,
        GetUserFreezableWeeksQuery,
        GetUserClassRoomPlansQuery,
      ],
    },
  );

  const freezeUserWeek = useCallback(
    async (userId: string, week: FrozenWeekInput) => {
      await freezeUserWeekMutation({
        variables: {
          userId,
          week,
        },
      });
    },
    [freezeUserWeekMutation],
  );

  return { freezeUserWeek, loading };
}
