import { Layout } from "../../UI/Layout/Layout.tsx";
import { Card } from "../../UI/Card.tsx";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { useUsersEligibleForWarranty } from "./useUsersEligibleForWarranty.ts";
import { CenteredLoading } from "../../UI/Loading/CenteredLoading.tsx";
import { UserTable } from "../UserTable.tsx";
import { UserFragmentFragment } from "../../gql/graphql.ts";
import { useToEditUser } from "../useToEditUser.ts";
import { useToUserLessonFeedbackReport } from "../LessonFeedback/useToUserLessonFeedbackReport.ts";
import { useToAllUserClassRoomPlan } from "../ClassRoomPlan/useToAllUserClassRoomPlan.ts";
import { WeekInput, Interval } from "@normasteaching/norma-ui";
import dayjs from "dayjs";
import { InputWithLabel } from "../../UI/InputWithLabel.tsx";
import { useFilterController } from "../../QueryFilterUtils/useFilterController.ts";

export const UsersEligibleForWarrantyScreenPath =
  "/users/eligible-for-warranty";

type PlanEndFilter = {
  fromDate?: string;
  toDate?: string;
};

function filterToInterval(
  filter: PlanEndFilter,
  defaultInterval: Interval,
): Interval {
  if (!filter.fromDate || !filter.toDate) return defaultInterval;
  return new Interval(dayjs(filter.fromDate), dayjs(filter.toDate));
}
export function UsersEligibleForWarrantyScreen() {
  const { filter, setFilter } = useFilterController<PlanEndFilter>();
  const { loading, users } = useUsersEligibleForWarranty({
    plansEndAfter: dayjs(filter.fromDate),
    plansEndBefore: dayjs(filter.toDate),
  });

  function createDefaultInterval(): Interval {
    const now = dayjs();
    return new Interval(now.subtract(1, "month"), now);
  }
  const { toEditUser } = useToEditUser();

  const { toUserLessonFeedbackReport } = useToUserLessonFeedbackReport();

  const { toAllUserClassRoomPlan } = useToAllUserClassRoomPlan();

  function goToUserClassRoomPlan(user: UserFragmentFragment): void {
    toAllUserClassRoomPlan(user.id);
  }

  function goToEditUser(user: UserFragmentFragment): void {
    toEditUser(user.id);
  }

  function goToLessonFeedbackReport(user: UserFragmentFragment): void {
    toUserLessonFeedbackReport(user.id);
  }
  const titleParts = [`Users eligible for warranty`];
  if (!loading) titleParts.push(`(${users.length})`);
  return (
    <Layout>
      <Card>
        <ScreenTitleBlock title={titleParts.join(" ")} />
      </Card>
      <Card>
        <div>
          <div className={"mb-4 flex space-x-2"}>
            <div className={"w-full"}>
              <InputWithLabel label={"Plan end date between"}>
                <WeekInput
                  value={filterToInterval(filter, createDefaultInterval())}
                  onChange={(v) =>
                    setFilter({
                      fromDate: v.fromDate.toISOString(),
                      toDate: v.toDate.toISOString(),
                    })
                  }
                />
              </InputWithLabel>
            </div>
          </div>
          {loading ? (
            <CenteredLoading />
          ) : (
            <UserTable
              enableCsvExport
              toUserClassRoomPlan={goToUserClassRoomPlan}
              toFeedbackReport={goToLessonFeedbackReport}
              editUser={goToEditUser}
              users={users}
            />
          )}
        </div>
      </Card>
    </Layout>
  );
}
