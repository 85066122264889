import { Interval } from "./Interval.tsx";
import { CalendarItemMap } from "./CalendarItemMap.ts";
import { ReactNode, useState } from "react";
import { DesktopCalendarComponent } from "./DesktopCalendarComponent.tsx";
import dayjs, { Dayjs } from "dayjs";
import { CalendarDatePicker } from "../UI/Date/CalendarDatePicker.tsx";
import { maxDate } from "../DataUtils/maxDate.tsx";
import { MobileCalendarComponent } from "./MobileCalendarComponent.tsx";

export function WeeklyCalendar<T>({
  interval,
  items,
  loading,
  onChangeInterval,
  itemRenderer,
  emptyDayComponent,
  canGoToPrevWeek,
}: {
  interval: Interval;
  items: CalendarItemMap<T>;
  loading: boolean;
  onChangeInterval: (interval: Interval) => void;
  itemRenderer: (item: T) => ReactNode;
  emptyDayComponent: ReactNode;
  canGoToPrevWeek: boolean;
}) {
  const selectedDate = maxDate(dayjs(), interval.fromDate).toDate();
  const [selectedDay, setSelectedDay] = useState<Dayjs>();
  const datePickerComponent: (renderProps: {
    close: () => void;
  }) => ReactNode = ({ close }) => (
    <CalendarDatePicker
      isSelectedDay={(d) => dayjs(selectedDate).isSame(d, "week")}
      selectedDate={selectedDate}
      onChange={(date) => {
        const d = dayjs(date);
        onChangeInterval(new Interval(d.startOf("week"), d.endOf("week")));
        setSelectedDay(d);
        close();
      }}
      canNavigateBack={(interval) =>
        dayjs(interval.fromDate).isAfter(dayjs(), "month")
      }
      isDisabled={(day) => dayjs(day).isBefore(dayjs(), "day")}
    />
  );
  return (
    <>
      <div className={"hidden md:flex flex-1 flex-col"}>
        <DesktopCalendarComponent
          canGoToNextPeriod
          canGoToPreviousPeriod={canGoToPrevWeek}
          emptyDayComponent={emptyDayComponent}
          previousPeriodLabel={"Previous Week"}
          nextPeriodLabel={"Next Week"}
          interval={interval}
          onChangeInterval={onChangeInterval}
          loading={loading}
          items={items}
          itemRenderer={itemRenderer}
          datePickerComponent={datePickerComponent}
        />
      </div>
      <div className={"flex flex-col md:hidden flex-1"}>
        <MobileCalendarComponent
          scrollToDay={selectedDay}
          items={items}
          interval={interval}
          itemRenderer={itemRenderer}
          onChangeInterval={onChangeInterval}
          loading={loading}
          emptyDayComponent={emptyDayComponent}
          datePickerComponent={datePickerComponent}
        />
      </div>
    </>
  );
}
