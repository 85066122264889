import { graphql } from "../../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { FrozenWeekInput } from "../../../gql/graphql.ts";
import { useCallback } from "react";
import { GetUserFrozenWeeksQuery } from "./useUserFrozenWeeks.ts";
import { GetUserFreezableWeeksQuery } from "./useUserFreezableWeeks.ts";
import { GetUserClassRoomPlansQuery } from "../useUserClassRoomPlans.ts";

const UnfreezeUserWeekMutation = graphql(`
  mutation removeFrozenWeekForUser($week: FrozenWeekInput!, $userId: String!) {
    removeFrozenWeekForUser(week: $week, userId: $userId)
  }
`);

export function useUnfreezeUserWeek() {
  const [unfreezeUserWeekMutation, { loading }] = useMutation(
    UnfreezeUserWeekMutation,
    {
      refetchQueries: [
        GetUserFrozenWeeksQuery,
        GetUserFreezableWeeksQuery,
        GetUserClassRoomPlansQuery,
      ],
    },
  );

  const unfreezeUserWeek = useCallback(
    async (userId: string, week: FrozenWeekInput) => {
      await unfreezeUserWeekMutation({
        variables: {
          userId,
          week,
        },
      });
    },
    [unfreezeUserWeekMutation],
  );

  return { unfreezeUserWeek, loading };
}
