import { useParams } from "react-router-dom";

export function useFranchiseeIdParamsOrThrow() {
  const { franchiseeId } = useParams<{
    franchiseeId: string;
  }>();
  if (!franchiseeId) throw new Error("franchiseeId is required");

  return {
    franchiseeId,
  };
}
