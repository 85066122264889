import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AllFranchiseeScreenPath } from "./FranchiseeScreen.tsx";

export function useToAllFranchisees() {
  const navigate = useNavigate();
  const toAllFranchisees = useCallback(() => {
    navigate(AllFranchiseeScreenPath);
  }, [navigate]);

  return {
    toAllFranchisees,
  };
}
