import dayjs from "dayjs";

export function formatWeek({
  week,
  year,
}: {
  week: number;
  year: number;
}): string {
  const formatString = "ddd DD MMM YYYY";
  const startDateUtc = dayjs().week(week).year(year).startOf("week");
  const endDateUtc = startDateUtc.endOf("week");
  return `${startDateUtc.format(formatString)} -> ${endDateUtc.format(
    formatString,
  )}`;
}
