import { FrozenWeekFragment } from "../../../gql/graphql.ts";
import dayjs from "dayjs";
import { PrimaryLoadingIndicator } from "../../../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../../../UI/Loading/LoaderSize.tsx";
import { XCircleIcon } from "@heroicons/react/24/outline/index.js";
import { useUnfreezeUserWeek } from "./useUnfreezeUserWeek.ts";
import { useMessagePopupController } from "../../../UI/MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../../../UI/MessagePopup/ErrorMessagePopup.tsx";

export function FrozenWeekItem({
  week,
  userId,
}: {
  week: FrozenWeekFragment;
  userId: string;
}) {
  const parseDateFormat = "YYYY-MM-DD";

  const { loading, unfreezeUserWeek } = useUnfreezeUserWeek();
  const { title, description, show, showPopup } = useMessagePopupController(
    5 * 1000,
  );

  function formatDate(dateString: string): string {
    return dayjs(dateString, parseDateFormat)
      .local()
      .locale("en")
      .format("ddd DD MMM YYYY");
  }

  async function unfreeze() {
    try {
      await unfreezeUserWeek(userId, {
        startOfWeekDate: week.startOfWeekUtc,
        endOfWeekDate: week.endOfWeekUtc,
      });
    } catch (e) {
      console.error(e);
      showPopup(
        "Ops",
        "Something went wrong when trying to unfreeze this week. Please try again",
      );
    }
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <div
        className={
          "border rounded-lg p-3 border-slate-100 flex justify-between space-x-2 items-center"
        }
      >
        <p className={""}>
          From {formatDate(week.startOfWeekUtc)} to{" "}
          {formatDate(week.endOfWeekUtc)}
        </p>
        <div>
          {loading ? (
            <PrimaryLoadingIndicator size={LoaderSize.Small} />
          ) : (
            <XCircleIcon
              onClick={unfreeze}
              className={"w-7 text-red-400 cursor-pointer"}
            />
          )}
        </div>
      </div>
    </>
  );
}
