import { graphql } from "../gql";
import { ApolloClient } from "@apollo/client";
import { ColumnVO } from "ag-grid-enterprise";

const SpeakingClassServiceAgGridQuery = graphql(`
  query speakingClassServiceAgGridQuery($params: AgGridParamsInput!) {
    speakingClassServiceAgGridQuery(params: $params) {
      hasNext
      data
    }
  }
`);
export async function sendSpeakingClassServiceAgGridQuery({
  endRow,
  startRow,
  queryId,
  apolloClient,
  filterModel,
  sortModel,
  rowGroupCols,
  groupKeys,
  valueCols,
}: {
  endRow: number;
  startRow: number;
  queryId: string;
  apolloClient: ApolloClient<object>;
  filterModel: Record<string, unknown>;
  sortModel: unknown[];
  groupKeys: string[];
  rowGroupCols: ColumnVO[];
  valueCols: ColumnVO[];
}) {
  const { data } = await apolloClient.query({
    query: SpeakingClassServiceAgGridQuery,
    fetchPolicy: "network-only",
    variables: {
      params: {
        endRow,
        startRow,
        filterModel,
        sortModel,
        queryId,
        rowGroupCols,
        groupKeys,
        valueCols,
      },
    },
  });

  return {
    result: data?.speakingClassServiceAgGridQuery
      ? JSON.parse(data.speakingClassServiceAgGridQuery?.data)
      : [],
    hasNext: data?.speakingClassServiceAgGridQuery?.hasNext ?? false,
  };
}
