import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { TeacherAvailabilityScreenPath } from "./TeacherAvailabilityScreen.tsx";

export function useToTeacherAvailability() {
  const navigate = useNavigate();
  const toTeacherAvailability = useCallback(
    (teacherId: string) => {
      navigate(TeacherAvailabilityScreenPath.replace(":teacherId", teacherId));
    },
    [navigate],
  );

  return { toTeacherAvailability };
}
