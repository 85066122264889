import { ApolloClient } from "@apollo/client";
import { getFragmentData, graphql } from "../../gql";
import {
  SpeakingClassLevel,
  TopicItemFragment,
  TopicItemFragmentDoc,
} from "../../gql/graphql.ts";

const GetMinUsedTopicInLevelAmongParticipants = graphql(`
  query GetMinUsedTopicInLevelAmongParticipants(
    $participantIds: [String!]!
    $speakingLevel: SpeakingClassLevel!
  ) {
    getMinUsedTopicsInLevelAmongParticipants(
      participantIds: $participantIds
      speakingLevel: $speakingLevel
    ) {
      ...TopicItem
    }
  }
`);
export async function getMinUsedTopicInLevelAmongParticipants({
  apolloClient,
  participantIds,
  speakingLevel,
}: {
  apolloClient: ApolloClient<object>;
  participantIds: string[];
  speakingLevel: SpeakingClassLevel;
}): Promise<TopicItemFragment[]> {
  const { data } = await apolloClient.query({
    query: GetMinUsedTopicInLevelAmongParticipants,
    variables: {
      participantIds,
      speakingLevel,
    },
  });

  return getFragmentData(
    TopicItemFragmentDoc,
    data?.getMinUsedTopicsInLevelAmongParticipants ?? [],
  );
}
