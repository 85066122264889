import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { CreateFranchiseeScreenPath } from "./CreateFranchiseeScreen.tsx";

export function useToCreateFranchisee() {
  const navigate = useNavigate();

  const toCreateFranchisee = useCallback(() => {
    navigate(CreateFranchiseeScreenPath);
  }, [navigate]);

  return {
    toCreateFranchisee,
  };
}
