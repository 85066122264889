import {
  GetFreezableWeeksResponse,
  WeekWithPlan,
} from "../../../gql/graphql.ts";
import { useState } from "react";
import _groupBy from "lodash.groupby";
import _keyBy from "lodash.keyby";
import dayjs from "dayjs";
import { useWithLoading } from "../../../UI/Loading/useWithLoading.ts";
import { MultipleSelect } from "../../../UI/Select/MultipleSelect.tsx";
import { PrimaryButton } from "../../../UI/PrimaryButton.tsx";
import { useFreezeUserWeek } from "./useFreezeUserWeek.ts";
import { ErrorMessagePopup } from "../../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../../UI/MessagePopup/useMessagePopupController.ts";
import { FreezeWeekGraphqlErrorCode } from "./FreezeWeekGraphqlErrorCode.tsx";
import { ApolloError } from "@apollo/client";

export function FreezeWeekComponent({
  freezableWeeks,
  userId,
}: {
  freezableWeeks: GetFreezableWeeksResponse;
  userId: string;
}) {
  const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
  const [weekNotSelectedError, setWeekNotSelectedError] = useState("");
  const { freezeUserWeek } = useFreezeUserWeek();
  const { withLoading, loading } = useWithLoading();
  const { title, show, showPopup, description } = useMessagePopupController(
    5 * 1000,
  );

  function printGenericError() {
    showPopup(
      "Ops",
      "Something went wrong when trying to suspend the week. Please try again.",
    );
  }

  async function suspend() {
    const weeksToFreeze = freezableWeeks.weeks.filter((w) =>
      selectedWeeks.includes(createWeekId(w)),
    );
    if (weeksToFreeze.length <= 0) {
      setWeekNotSelectedError("You must select at least one week to suspend");
      return;
    }

    await withLoading(async () => {
      for (const week of weeksToFreeze) {
        try {
          await freezeUserWeek(userId, {
            startOfWeekDate: week.week.startOfWeekUtc,
            endOfWeekDate: week.week.endOfWeekUtc,
          });
        } catch (e) {
          if (!(e instanceof ApolloError)) {
            printGenericError();
            continue;
          }
          if (
            e.graphQLErrors?.some((e) => {
              return (
                e.extensions?.code ===
                FreezeWeekGraphqlErrorCode.ClassRoomAlreadyBookedInWeek
              );
            })
          )
            showPopup(
              "Can't suspend this week",
              `This user has already booked some classes in the week that goes from ${formatDate(week.week.startOfWeekUtc)} to  ${formatDate(week.week.endOfWeekUtc)}`,
            );
          else printGenericError();
        }
      }
    });
  }

  function createWeekId(w: WeekWithPlan): string {
    return `${w.week.startOfWeekUtc}|${w.week.endOfWeekUtc}|${w.planId}`;
  }

  function parseId(id: string): {
    startOfWeekUtc: string;
    endOfWeekUtc: string;
    planId: string;
  } {
    const [startOfWeekUtc, endOfWeekUtc, planId] = id.split("|");
    return {
      startOfWeekUtc,
      endOfWeekUtc,
      planId,
    };
  }

  const selectedWeekByPlanId = _groupBy(selectedWeeks, (wId) => {
    const { planId } = parseId(wId);
    return planId;
  });
  const remainingWeeksByPlanId = _keyBy(
    freezableWeeks.remainingWeeksToFreeze,
    "planId",
  );

  const parseDateFormat = "YYYY-MM-DD";

  function formatDate(dateString: string): string {
    return dayjs(dateString, parseDateFormat)
      .local()
      .locale("en")
      .format("ddd DD MMM YYYY");
  }

  return (
    <div className={"space-y-4"}>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <h3 className={"font-bold"}>Suspend weeks</h3>
      <MultipleSelect
        placeholder={"Select one or more weeks to suspend"}
        errorMessage={weekNotSelectedError}
        onChange={(value) => {
          setSelectedWeeks(value);
          setWeekNotSelectedError("");
        }}
        value={selectedWeeks}
        options={freezableWeeks.weeks.map((w) => {
          const remainingByPlan = remainingWeeksByPlanId[w.planId];
          const remaining =
            (remainingByPlan?.remainingCount ?? 0) -
            (selectedWeekByPlanId[w.planId]?.length ?? 0);
          return {
            label: `From ${formatDate(w.week.startOfWeekUtc)} to ${formatDate(w.week.endOfWeekUtc)}`,
            id: createWeekId(w),
            disabled:
              remaining <= 0
                ? {
                    isDisabled: true,
                    message:
                      "It's not possible to suspend more weeks for that plan",
                  }
                : undefined,
          };
        })}
      />
      <div className={"flex"}>
        <div className={"ml-auto"}>
          <PrimaryButton
            label={"Suspend"}
            onClick={suspend}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
