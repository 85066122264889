import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const RemoveParticipantFromClassRoomMutation = graphql(`
  mutation removeParticipantFromClassRoom(
    $classRoomId: String!
    $userId: String!
  ) {
    removeParticipantFromClassRoom(classRoomId: $classRoomId, userId: $userId) {
      classRoomDeleted
      classRoom {
        ...ClassRoomWithParticipants
      }
    }
  }
`);
export function useRemoveParticipantFromClassRoom() {
  const [removeParticipantFromClassRoomMutation, { loading }] = useMutation(
    RemoveParticipantFromClassRoomMutation,
  );

  const removeParticipantFromClassRoom = useCallback(
    async ({
      classRoomId,
      userId,
    }: {
      classRoomId: string;
      userId: string;
    }) => {
      const { data } = await removeParticipantFromClassRoomMutation({
        variables: { classRoomId, userId },
      });

      return {
        deletedClassRoom:
          data?.removeParticipantFromClassRoom.classRoomDeleted ?? false,
      };
    },
    [removeParticipantFromClassRoomMutation],
  );

  return {
    removeParticipantFromClassRoom,
    loading,
  };
}
