import { Modal } from "../UI/Modal/Modal.tsx";
import {
  CompleteClassRoomFragment,
  FranchiseeItemFragment,
} from "../gql/graphql.ts";
import { Select } from "@normasteaching/norma-ui";
import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { useSwapTeacher } from "./useSwapTeacher.ts";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { ApolloError } from "@apollo/client";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { BooleanInput } from "../UI/BooleanInput.tsx";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  allTeachers: FranchiseeItemFragment[];
  classRoom: CompleteClassRoomFragment;
  onChanged: () => Promise<void>;
};
export function SwapTeacherModal({
  isOpen,
  onClose,
  allTeachers,
  classRoom,
  onChanged,
}: Props) {
  const [selectedId, setSelectedId] = useState<string>();
  const { swapTeacher } = useSwapTeacher();
  const [makeOldTeacherAvailableAgain, setMakeOldTeacherAvailableAgain] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { description, title, show, showPopup } = useMessagePopupController(
    5 * 1000,
  );
  const selected = allTeachers.find((t) => t.id === selectedId);
  useEffect(() => {
    if (!isOpen) {
      setSelectedId(undefined);
      setMakeOldTeacherAvailableAgain(false);
    }
  }, [isOpen]);
  async function saveNewTeacher() {
    if (!selectedId) return;
    try {
      setLoading(true);
      await swapTeacher({
        teacherId: selectedId,
        classRoomId: classRoom.id,
        doNotMakeOldTeacherAvailableAgain: !makeOldTeacherAvailableAgain,
      });
      await onChanged();
      onClose();
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Modal
        overridePanel={({ title, children }) => {
          return (
            <Dialog.Panel className="flex-1 md:flex-auto md:max-w-md transform md:rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className={"mt-4"}>{children ?? null}</div>
            </Dialog.Panel>
          );
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={`Change teacher`}
      >
        <div className={"space-y-4"}>
          <InputWithLabel label={"Select new teacher"}>
            <Select<string>
              value={selectedId}
              options={allTeachers
                .filter((t) => t.id !== classRoom.teacher.id)
                .map((t) => ({
                  id: t.id,
                  name: `${t.givenName} ${t.familyName} (${t.email})`,
                }))}
              onChange={(t) => setSelectedId(t ?? undefined)}
            />
          </InputWithLabel>
          <InputWithLabel label={"Make old teacher available again"}>
            <BooleanInput
              value={makeOldTeacherAvailableAgain}
              onChange={(v) => setMakeOldTeacherAvailableAgain(v)}
            />
          </InputWithLabel>
          {selected ? (
            <p className={"text-sm"}>
              You will assign{" "}
              <b>
                {selected.givenName} {selected.familyName}
              </b>{" "}
              to this class room and remove{" "}
              <b>
                {classRoom.teacher.givenName} {classRoom.teacher.familyName}
              </b>{" "}
              from it. Both teachers will be notified
            </p>
          ) : null}
          <div className={"flex justify-center"}>
            <PrimaryButton
              disabled={!selectedId}
              loading={loading}
              label={"Save new Teacher"}
              onClick={saveNewTeacher}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
