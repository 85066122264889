import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SpeakingClassLevel } from "../gql/graphql.ts";
import { Col } from "../UI/Layout/Col.tsx";
import { Row } from "../UI/Layout/Row.tsx";
import { TextInput } from "../UI/TextInput.tsx";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { Select } from "@normasteaching/norma-ui";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { getAllSpeakingClassLevelOptions } from "./getAllSpeakingClassLevelOptions.ts";

export type TopicFormInputs = {
  name: string;
  level: SpeakingClassLevel;
  pdfUrl: string;
};

type Props = {
  defaultValues?: TopicFormInputs;
  onSave: (data: TopicFormInputs) => Promise<void>;
  disabledMap?: {
    [key in keyof TopicFormInputs]?: boolean;
  };
};

const schema = yup
  .object({
    name: yup.string().required("Specifica un'nome"),
    level: yup
      .mixed<SpeakingClassLevel>()
      .oneOf(Object.values(SpeakingClassLevel))
      .required("Specifica un livello"),
    pdfUrl: yup
      .string()
      .url("Specifica un URL valido")
      .required("Specifica un URL per il PDF"),
  })
  .required();

export function TopicForm({ defaultValues, onSave, disabledMap }: Props) {
  const levelOptions = getAllSpeakingClassLevelOptions();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<TopicFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      level: levelOptions[0].id,
      ...(defaultValues ?? {}),
    },
  });

  const { withLoading, loading } = useWithLoading();
  const onSubmit: SubmitHandler<TopicFormInputs> = async (data) => {
    await withLoading(async () => onSave(data));
  };

  return (
    <form>
      <Col>
        <Row>
          <InputWithLabel label="Name">
            <TextInput
              errorMessage={errors.name?.message}
              {...register("name")}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label={"Level"}>
            <Controller
              control={control}
              render={({ field }) => {
                return (
                  <Select<SpeakingClassLevel>
                    disabled={disabledMap?.level}
                    errorMessage={errors.level?.message}
                    onChange={field.onChange}
                    value={field.value}
                    options={levelOptions}
                  />
                );
              }}
              name={"level"}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label="PDF url">
            <TextInput
              errorMessage={errors.pdfUrl?.message}
              {...register("pdfUrl")}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <div className={"flex w-full justify-end"}>
            <PrimaryButton
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={"Save"}
            />
          </div>
        </Row>
      </Col>
    </form>
  );
}
