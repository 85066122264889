import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function AccessTokenScreen() {
  const [token, setToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    (async function getToken() {
      const token = await getAccessTokenSilently();
      setToken(token);
    })();
  }, [getAccessTokenSilently]);
  return <code>{token}</code>;
}
