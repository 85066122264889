import { graphql } from "../gql/index.ts";

export const TopicFragment = graphql(`
  fragment TopicItem on Topic {
    id
    name
    level
    pdfUrl
  }
`);
