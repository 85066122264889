import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetAllTopicsQuery } from "./useAllTopics.ts";

const LogicallyDeleteTopicMutation = graphql(`
  mutation logicallyDeleteTopic($topicId: String!) {
    logicallyDeleteTopic(id: $topicId)
  }
`);
export function useLogicallyDeleteTopic() {
  const [logicallyDeleteTopicMutation, { loading }] = useMutation(
    LogicallyDeleteTopicMutation,
    {
      refetchQueries: [GetAllTopicsQuery],
    },
  );

  const logicallyDeleteTopic = useCallback(
    async (topicId: string) => {
      await logicallyDeleteTopicMutation({
        variables: {
          topicId,
        },
      });
    },
    [logicallyDeleteTopicMutation],
  );

  return {
    loading,
    logicallyDeleteTopic,
  };
}
