import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { CreateClassRoomPlanScreenPath } from "./CreateClassRoomPlanScreen.tsx";

export function useToCreateClassRoomPlanScreen() {
  const navigate = useNavigate();

  const toCreateClassRoomPlan = useCallback(
    (userId: string) => {
      navigate(CreateClassRoomPlanScreenPath.replace(":userId", userId));
    },
    [navigate],
  );

  return {
    toCreateClassRoomPlan,
  };
}
