import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const SwapTeacherMutation = graphql(`
  mutation swapTeacher(
    $classRoomId: String!
    $teacherId: String!
    $doNotMakeOldTeacherAvailableAgain: Boolean!
  ) {
    swapTeacher(
      classRoomId: $classRoomId
      teacherToAddId: $teacherId
      doNotMakeOldTeacherAvailableAgain: $doNotMakeOldTeacherAvailableAgain
    )
  }
`);
export function useSwapTeacher() {
  const [swapTeacherMutation, { loading }] = useMutation(SwapTeacherMutation);

  const swapTeacher = useCallback(
    async ({
      teacherId,
      doNotMakeOldTeacherAvailableAgain,
      classRoomId,
    }: {
      teacherId: string;
      classRoomId: string;
      doNotMakeOldTeacherAvailableAgain: boolean;
    }) => {
      await swapTeacherMutation({
        variables: {
          teacherId,
          classRoomId,
          doNotMakeOldTeacherAvailableAgain,
        },
      });
    },
    [swapTeacherMutation],
  );

  return {
    swapTeacher,
    loading,
  };
}
