import { Layout } from "../UI/Layout/Layout.tsx";
import { useAllFranchisees } from "./useAllFranchisees.ts";
import { useToCreateFranchisee } from "./useToCreateFranchisee.ts";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { FranchiseeTable } from "./FranchiseeTable.tsx";
import { useToEditFranchisee } from "./useToEditFranchisee.ts";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import { Card } from "../UI/Card.tsx";
export const AllFranchiseeScreenPath = "/";
export function FranchiseeScreen() {
  const { franchisees, loading } = useAllFranchisees();
  const { toCreateFranchisee } = useToCreateFranchisee();
  const { toEditFranchisee } = useToEditFranchisee();
  return (
    <Layout>
      <Card>
        <ScreenTitleBlock
          title={"Teachers"}
          ctaOnClick={() => toCreateFranchisee()}
          ctaLabel={"New Teacher"}
        />
      </Card>
      <div className={"mt-4"}>
        <Card>
          {loading ? (
            <CenteredLoading />
          ) : (
            <FranchiseeTable
              editFranchisee={(f) => toEditFranchisee(f.id)}
              franchisees={franchisees}
            />
          )}
        </Card>
      </div>
    </Layout>
  );
}
