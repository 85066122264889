import { ClassRoomPlanWarrantyConfigDTO } from "./ClassRoomPlanWarrantyConfigDTO.tsx";
import { BooleanInput } from "../../UI/BooleanInput.tsx";
import { InputWithLabel } from "../../UI/InputWithLabel.tsx";
import { TextInput } from "@normasteaching/norma-ui";
type Props = {
  warrantyConfig?: ClassRoomPlanWarrantyConfigDTO;
  onChange: (warrantyConfig: ClassRoomPlanWarrantyConfigDTO) => void;
};
export function ClassRoomPlanWarrantyConfigInput({
  warrantyConfig,
  onChange,
}: Props) {
  const wc = warrantyConfig ?? {};
  return (
    <>
      <div className={"flex flex-col space-y-4"}>
        <InputWithLabel label={"Enabled"}>
          <BooleanInput
            value={warrantyConfig?.enabled ?? false}
            onChange={(v) =>
              onChange({
                ...wc,
                enabled: v,
              })
            }
          />
        </InputWithLabel>
        <InputWithLabel label={"Min attended lessons"}>
          <TextInput
            type={"number"}
            disabled={!warrantyConfig?.enabled}
            value={warrantyConfig?.minAttendedLessons ?? ""}
            onChange={(e) =>
              onChange({
                ...wc,
                minAttendedLessons: e.target.value
                  ? parseInt(e.target.value)
                  : undefined,
              })
            }
          />
        </InputWithLabel>
      </div>
    </>
  );
}
