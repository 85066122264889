import { useSearchParams } from "react-router-dom";
import { UrlBase64Obj } from "../UrlUtils/UrlBase64Obj.ts";
import { useCallback } from "react";
import _filter from "lodash.filter";
import { isDefined } from "../isDefined.ts";

export function useFilterAndPaginationController<T extends object>() {
  const [searchParams, setSearchParams] = useSearchParams();
  const nextPageTokenParamName = "npt";
  const prevPageTokenParamName = "ppt";
  const filterParamName = "f";
  const filterString = searchParams.get(filterParamName);
  const filter: Partial<T> = filterString
    ? UrlBase64Obj.fromString<T>(filterString).value
    : {};
  const nToken = searchParams.get(nextPageTokenParamName);
  const pToken = searchParams.get(prevPageTokenParamName);

  function toNextPage(nextPageToken: string) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(nextPageTokenParamName, nextPageToken);
    newSearchParams.delete(prevPageTokenParamName);
    setSearchParams(newSearchParams);
  }

  function toPrevPage(prevPageToken: string) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(prevPageTokenParamName, prevPageToken);
    newSearchParams.delete(nextPageTokenParamName);
    setSearchParams(newSearchParams);
  }

  const setFilter = useCallback(
    (filter: T) => {
      const newSearchParams = new URLSearchParams(searchParams);

      if (
        _filter(filter, (f) => {
          if (!isDefined(f)) return false;
          return !(typeof f === "string" && f.length <= 0);
        }).length <= 0
      ) {
        newSearchParams.delete(filterParamName);
        setSearchParams(newSearchParams);
        return;
      }

      newSearchParams.delete(nextPageTokenParamName);
      newSearchParams.set(filterParamName, new UrlBase64Obj(filter).toString());
      setSearchParams(newSearchParams);
    },
    [setSearchParams, searchParams],
  );

  return {
    toNextPage,
    currentNextPageToken: nToken ?? undefined,
    currentPrevPageToken: pToken ?? undefined,
    toPrevPage,
    setFilter,
    filter,
  };
}
