import { useMutation } from "@apollo/client";
import { graphql } from "../../gql/index.ts";
import { useCallback } from "react";

const RemoveAvailabilitySlotMutation = graphql(`
  mutation removeAvailabilitySlot($slotId: String!) {
    removeAvailabilitySlot(slotId: $slotId)
  }
`);
export function useRemoveAvailabilitySlot() {
  const [removeAvailabilitySlotMutation, { loading }] = useMutation(
    RemoveAvailabilitySlotMutation,
  );

  const removeAvailabilitySlot = useCallback(
    async (slotId: string) => {
      await removeAvailabilitySlotMutation({
        variables: {
          slotId,
        },
      });
    },
    [removeAvailabilitySlotMutation],
  );

  return {
    removeAvailabilitySlot,
    loading,
  };
}
