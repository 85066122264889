import { Layout } from "../../UI/Layout/Layout.tsx";
import { useNotResolvedSpeakingLevelSuggestions } from "./useNotResolvedSpeakingLevelSuggestions.ts";
import { SpeakingLevelSuggestionTable } from "./SpeakingLevelSuggestionTable.tsx";
import { LevelChangeSuggestionDto } from "../../gql/graphql.ts";
import { Card } from "../../UI/Card.tsx";
import { SpinnerContainer } from "../../UI/Loading/SpinnerContainer.tsx";
import { useToEditUser } from "../useToEditUser.ts";
import { useResolveLevelSuggestion } from "./useResolveLevelSuggestion.ts";

export const SpeakingLevelChangeSuggestionScreenPath = `/users/speaking-level-change-suggestions`;
export function SpeakingLevelChangeSuggestionScreen() {
  const { loading, suggestions } = useNotResolvedSpeakingLevelSuggestions();
  const { toEditUser } = useToEditUser();
  const { resolveLevelSuggestion, loading: resolvingSuggestion } =
    useResolveLevelSuggestion();
  async function resolve(suggestion: LevelChangeSuggestionDto) {
    if (window.confirm(`Are you sure you want to resolve this suggestion?`)) {
      await resolveLevelSuggestion(suggestion.id);
    }
  }
  return (
    <Layout>
      <Card>
        <SpinnerContainer loading={loading || resolvingSuggestion}>
          <SpeakingLevelSuggestionTable
            onResolveSuggestion={resolve}
            toUser={(user) => toEditUser(user.id)}
            suggestions={suggestions as LevelChangeSuggestionDto[]}
          />
        </SpinnerContainer>
      </Card>
    </Layout>
  );
}
