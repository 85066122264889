import ReactDOM from "react-dom";
import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "./LoaderSize.tsx";

type Props = {
  loading: boolean;
  message: string;
};
export function GlobalSpinner({ loading, message }: Props) {
  return loading
    ? ReactDOM.createPortal(
        <div className={"fixed top-10 w-full flex justify-center"}>
          <div
            className={
              "bg-white rounded-lg shadow-md shadow-slate-200 p-4 flex items-center space-x-2"
            }
          >
            <PrimaryLoadingIndicator size={LoaderSize.Small} />
            <p>{message}</p>
          </div>
        </div>,
        document.body,
      )
    : null;
}
