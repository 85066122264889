import { getFragmentData, graphql } from "../../gql";
import { useQuery } from "@apollo/client";
import { LevelChangeSuggestionFragment } from "./LevelChangeSuggestionFragment.ts";

export const GetNotResolvedSpeakingLevelSuggestionsQuery = graphql(`
  query listNotResolvedLevelChangeSuggestions {
    listNotResolvedLevelChangeSuggestions {
      ...LevelChangeSuggestion
    }
  }
`);
export function useNotResolvedSpeakingLevelSuggestions() {
  const { data, loading } = useQuery(
    GetNotResolvedSpeakingLevelSuggestionsQuery,
  );

  return {
    suggestions:
      getFragmentData(
        LevelChangeSuggestionFragment,
        data?.listNotResolvedLevelChangeSuggestions,
      ) ?? [],
    loading,
  };
}
