import { ClassRoomType } from "../../gql/graphql.ts";
import { TopicDTO } from "./TopicDTO.ts";

export enum CapacityReportEntryStatus {
  MissingCapacity = "MissingCapacity",
  OverCapacity = "OverCapacity",
}

export type MissingCapacityReportEntryDTO = {
  status: CapacityReportEntryStatus.MissingCapacity;
  classroomType: ClassRoomType;
  topic: TopicDTO;
};
export type OverCapacityReportEntryDTO = {
  status: CapacityReportEntryStatus.OverCapacity;
};
export type CapacityReportEntryDTO = {
  week: number;
  year: number;
  count: number;
  reportId: string;
  reportDate: Date;
} & (MissingCapacityReportEntryDTO | OverCapacityReportEntryDTO);
