import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SpeakingLevel } from "../gql/graphql.ts";

const UpdateUserMutation = graphql(`
  mutation updateUser(
    $familyName: String
    $givenName: String
    $userId: String!
    $speakingLevel: SpeakingLevel
    $activateAccount: Boolean
    $hideBookSpeakingClassSection: Boolean
    $hideBookProgressCheckSection: Boolean
  ) {
    updateUser(
      familyName: $familyName
      givenName: $givenName
      userId: $userId
      speakingLevel: $speakingLevel
      activateAccount: $activateAccount
      hideBookSpeakingClassSection: $hideBookSpeakingClassSection
      hideBookProgressCheckSection: $hideBookProgressCheckSection
    ) {
      ...UserFragment
    }
  }
`);
export function useUpdateUser() {
  const [updateUserMutation, { loading }] = useMutation(UpdateUserMutation);
  const updateUser = useCallback(
    async (params: {
      userId: string;
      givenName?: string;
      familyName?: string;
      speakingLevel?: SpeakingLevel;
      activateAccount?: boolean;
      hideBookSpeakingClassSection?: boolean;
      hideBookProgressCheckSection?: boolean;
    }) => {
      await updateUserMutation({
        variables: params,
      });
    },
    [updateUserMutation],
  );

  return {
    updateUser,
    loading,
  };
}
