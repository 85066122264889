import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { ClassRoomType, SpeakingClassLevel } from "../../gql/graphql.ts";
import { UnableToCreateClassRoomError } from "./Error/UnableToCreateClassRoomError.ts";

const CreateClassRoomMutation = graphql(`
  mutation createClassRoom(
    $capacity: Int!
    $classRoomType: ClassRoomType!
    $participantIds: [String!]!
    $speakingLevel: SpeakingClassLevel!
    $startUtc: String!
    $teacherId: String!
    $topicId: String!
  ) {
    createClassRoom(
      capacity: $capacity
      classRoomType: $classRoomType
      participantIds: $participantIds
      speakingLevel: $speakingLevel
      startUtc: $startUtc
      teacherId: $teacherId
      topicId: $topicId
    ) {
      id
    }
  }
`);
export function useCreateClassRoom() {
  const [createClassRoomMutation, { loading }] = useMutation(
    CreateClassRoomMutation,
  );

  const createClassRoom = useCallback(
    async (input: {
      capacity: number;
      classRoomType: ClassRoomType;
      speakingLevel: SpeakingClassLevel;
      participantIds: string[];
      startUtc: string;
      teacherId: string;
      topicId: string;
    }) => {
      const res = await createClassRoomMutation({
        variables: input,
      });

      const classRoomId = res.data?.createClassRoom?.id;
      if (!classRoomId) throw new UnableToCreateClassRoomError();

      return classRoomId;
    },
    [createClassRoomMutation],
  );

  return {
    loading,
    createClassRoom,
  };
}
