import { SpeakingClassLevel, TopicItemFragment } from "../gql/graphql.ts";
import {
  ColumnFiltersState,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline/index.js";
import { rankItem } from "@tanstack/match-sorter-utils";
import { useState } from "react";
import { TextInput } from "../UI/TextInput.tsx";
import { getAllSpeakingClassLevelOptions } from "./getAllSpeakingClassLevelOptions.ts";
import { Select } from "@normasteaching/norma-ui";
import { DropDownItem } from "../UI/DropDown/DropDownItem.tsx";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import copy from "copy-to-clipboard";

const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type Props = {
  topics: TopicItemFragment[];
  editTopic: (topic: TopicItemFragment) => void;
  deleteTopic: (topic: TopicItemFragment) => void;
};
export function TopicTable(props: Props) {
  const topics = props.topics;
  const columnHelper = createColumnHelper<TopicItemFragment>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const levelOptions = getAllSpeakingClassLevelOptions();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Name",
    }),
    columnHelper.accessor("level", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Level",
    }),
    columnHelper.accessor("pdfUrl", {
      cell: (info) => (
        <div
          onClick={() => {
            copy(info.getValue());
            window.alert("Copied to clipboard");
          }}
          className={
            "max-w-[100px] overflow-hidden overflow-ellipsis cursor-pointer"
          }
        >
          {info.getValue()}
        </div>
      ),
      footer: (info) => info.column.id,
      header: "Pdf Url",
    }),

    columnHelper.display({
      id: "actions",
      cell: (v) => (
        <div className={"flex justify-end"}>
          <div>
            <DropDown
              activator={
                <EllipsisHorizontalIcon
                  className={"text-slate-800 w-5 h-5 group-hover:text-white"}
                />
              }
            >
              <DropDownItem onClick={() => props.editTopic(v.row.original)}>
                <PencilSquareIcon className={"w-5 h-5 mr-2"} />
                Edit
              </DropDownItem>
              <DropDownItem onClick={() => props.deleteTopic(v.row.original)}>
                <TrashIcon className={"w-5 h-5 mr-2"} />
                Delete
              </DropDownItem>
            </DropDown>
          </div>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    columns,
    data: topics as Array<TopicItemFragment>,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  const rows = table.getRowModel().rows;
  return (
    <div>
      <div className={"mb-2 flex space-x-2"}>
        {table
          .getHeaderGroups()
          .map((headerGroup) => {
            return headerGroup.headers.map((header) => {
              return header.column.getCanFilter() ? (
                <div className={"flex-1"} key={header.id}>
                  {header.id === "level" ? (
                    <Select
                      placeholder={header.column.columnDef.header as string}
                      clearable
                      value={
                        (header.column.getFilterValue() as SpeakingClassLevel) ??
                        null
                      }
                      options={levelOptions}
                      onChange={(v) => header.column.setFilterValue(v)}
                    />
                  ) : (
                    <TextInput
                      value={(header.column.getFilterValue() as string) ?? ""}
                      onChange={(v) =>
                        header.column.setFilterValue(v.target.value)
                      }
                      placeholder={header.column.columnDef.header as string}
                    />
                  )}
                </div>
              ) : null;
            });
          })
          .flat()}
      </div>
      <table className={"w-full text-sm"}>
        <thead className={"text-gray-700"}>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className={"px-6 py-3 text-left bg-gray-50 uppercase"}
                      key={header.id}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            return (
              <tr
                key={row.id}
                className={
                  i < rows.length - 1 ? "border-b border-gray-100" : ""
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className={"px-6 py-3"} key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
