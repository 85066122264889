import "ag-grid-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import React from "react";
import "ag-grid-enterprise";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { configureDayJs } from "./configureDayJs.ts";
import { configureSentry } from "./Sentry/configureSentry.ts";
import { configureAgGrid } from "./AgGrid/configureAgGrid.ts";
configureSentry();
configureDayJs();
configureAgGrid();
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        scope: "admin email profile",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);
