import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const DELETE_LESSON_FEEDBACK_MUTATION = graphql(`
  mutation deleteLessonFeedback($lessonFeedbackId: String!) {
    deleteLessonFeedback(lessonFeedbackId: $lessonFeedbackId)
  }
`);
export function useDeleteLessonFeedback() {
  const [deleteLessonFeedbackMutation, { loading }] = useMutation(
    DELETE_LESSON_FEEDBACK_MUTATION,
  );
  const deleteLessonFeedback = useCallback(
    async (lessonFeedbackId: string) => {
      await deleteLessonFeedbackMutation({
        variables: {
          lessonFeedbackId,
        },
      });
    },
    [deleteLessonFeedbackMutation],
  );

  return {
    deleteLessonFeedback,
    loading,
  };
}
