import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const DeleteClassRoomMutation = graphql(`
  mutation deleteClassRoom($classRoomId: String!) {
    deleteClassRoom(classRoomId: $classRoomId)
  }
`);
export function useDeleteClassRoom() {
  const [deleteClassRoomMutation, { loading }] = useMutation(
    DeleteClassRoomMutation,
  );

  const deleteClassRoom = useCallback(
    async (classRoomId: string) => {
      await deleteClassRoomMutation({
        variables: {
          classRoomId,
        },
      });
    },
    [deleteClassRoomMutation],
  );

  return {
    loading,
    deleteClassRoom,
  };
}
