import { useAuthCtx } from "../../Auth/useAuthCtx.ts";
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { DropDown } from "../DropDown/DropDown.tsx";
import { DropDownItem } from "../DropDown/DropDownItem.tsx";

function NavBarDropDown() {
  const { user, signOut } = useAuthCtx();
  return (
    <DropDown activator={user?.email}>
      <DropDownItem onClick={signOut}>
        <ArrowLeftStartOnRectangleIcon className={"h-5 w-5 mr-2"} />
        Logout
      </DropDownItem>
    </DropDown>
  );
}
export function NavBar() {
  return (
    <div
      className={
        "p-4 shadow-xl shadow-slate-200/10 w-full flex items-center justify-end bg-white"
      }
    >
      <div>
        <NavBarDropDown />
      </div>
    </div>
  );
}
