import { getFragmentData, graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SpeakingLevel, UserFragmentFragment } from "../gql/graphql.ts";
import { UserFragment } from "./UserFragment.ts";

const CreateUserMutation = graphql(`
  mutation createUser(
    $speakingLevel: SpeakingLevel!
    $email: String!
    $givenName: String!
    $familyName: String!
    $activateAccount: Boolean
    $hideBookSpeakingClassSection: Boolean
    $hideBookProgressCheckSection: Boolean
  ) {
    createUser(
      speakingLevel: $speakingLevel
      email: $email
      givenName: $givenName
      familyName: $familyName
      activateAccount: $activateAccount
      hideBookSpeakingClassSection: $hideBookSpeakingClassSection
      hideBookProgressCheckSection: $hideBookProgressCheckSection
    ) {
      ...UserFragment
    }
  }
`);
export function useCreateUser() {
  const [createUserMutation, { loading }] = useMutation(CreateUserMutation);

  const createUser = useCallback(
    async ({
      givenName,
      familyName,
      email,
      speakingLevel,
      activateAccount,
      hideBookSpeakingClassSection,
      hideBookProgressCheckSection,
    }: {
      givenName: string;
      familyName: string;
      email: string;
      speakingLevel: SpeakingLevel;
      activateAccount?: boolean;
      hideBookSpeakingClassSection?: boolean;
      hideBookProgressCheckSection?: boolean;
    }): Promise<UserFragmentFragment> => {
      const { data } = await createUserMutation({
        variables: {
          givenName,
          familyName,
          email,
          speakingLevel,
          activateAccount,
          hideBookProgressCheckSection,
          hideBookSpeakingClassSection,
        },
      });
      const user = data?.createUser;
      if (!user) throw new Error("User not created");
      return getFragmentData(UserFragment, data?.createUser);
    },
    [createUserMutation],
  );

  return {
    createUser,
    loading,
  };
}
