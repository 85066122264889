import { PropsWithChildren } from "react";
import { useAuthCtx } from "./useAuthCtx.ts";
import { Navigate } from "react-router-dom";

type Props = PropsWithChildren;
export function RequireAuth({ children }: Props) {
  const { user } = useAuthCtx();
  // const location = useLocation();
  if (!user) {
    return (
      <Navigate
        to="/login"
        // state={{ from: location }}
        replace
      />
    );
  }

  return children ?? null;
}
