import React from "react";
import { User } from "@auth0/auth0-react";

export type AuthCtxType = {
  user?: User;
  signOut: () => Promise<void>;
};

const AuthCtx = React.createContext<AuthCtxType>({
  signOut: async () => {
    throw new Error("AuthCtx not initialized");
  },
});

export { AuthCtx };
