import { graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";

const GetUserRemainingLessonsQuery = graphql(`
  query getUserRemainingLessons($userId: String!) {
    getUserRemainingLessons(userId: $userId)
  }
`);
export function useUserRemainingLessons(userId: string) {
  const { data, loading } = useQuery(GetUserRemainingLessonsQuery, {
    variables: {
      userId,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    loading,
    remainingLessons: data?.getUserRemainingLessons ?? 0,
  };
}
