export function FeedbackReportScore({
  label,
  value,
}: {
  label: string;
  value: number;
}) {
  return (
    <div className={"space-x-3 flex flex-row items-center justify-between"}>
      <h3 className={"font-semibold"}>{label}</h3>
      <div className={"flex"}>
        <p className={"font-bold text-primary leading-4"}>{value.toFixed(1)}</p>
      </div>
    </div>
  );
}
