import { SpeakingLevel } from "../gql/graphql.ts";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAllSpeakingClassLevelOptions } from "./getAllUserSpeakingLevelOptions.ts";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { Col } from "../UI/Layout/Col.tsx";
import { Row } from "../UI/Layout/Row.tsx";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { TextInput } from "../UI/TextInput.tsx";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { BooleanInput } from "../UI/BooleanInput.tsx";
import { Select } from "@normasteaching/norma-ui";
export type UserFormInputs = {
  speakingLevel: SpeakingLevel;
  email: string;
  givenName: string;
  familyName: string;
  shouldEnrollToAllPaidContent?: boolean;
  activateAccount?: boolean;
  hideBookSpeakingClassSection?: boolean;
  hideBookProgressCheckSection?: boolean;
};

type Props = {
  defaultValues?: UserFormInputs;
  onSave: (data: UserFormInputs) => Promise<void>;
  disabledMap?: {
    [key in keyof UserFormInputs]?: boolean;
  };
  showShouldEnrollToAllPaidContent?: boolean;
};

const schema = yup
  .object({
    speakingLevel: yup
      .mixed<SpeakingLevel>()
      .oneOf(Object.values(SpeakingLevel))
      .required("Specifica un livello di speaking"),
    email: yup
      .string()
      .email("Inserisci un'email valida")
      .required("Inserisci un'email"),
    givenName: yup.string().required("Inserisci un nome"),
    familyName: yup.string().required("Inserisci un cognome"),
    hideBookSpeakingClassSection: yup.boolean(),
    hideBookProgressCheckSection: yup.boolean(),
  })
  .required();

export function UserForm({
  defaultValues,
  onSave,
  disabledMap,
  showShouldEnrollToAllPaidContent,
}: Props) {
  const speakingClassLevelOptions = getAllSpeakingClassLevelOptions();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<UserFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      speakingLevel: SpeakingLevel.Unknown,
      shouldEnrollToAllPaidContent: true,
      activateAccount: true,
      ...(defaultValues ?? {}),
    },
  });

  const { withLoading, loading } = useWithLoading();
  const onSubmit: SubmitHandler<UserFormInputs> = async (data) => {
    await withLoading(async () => onSave(data));
  };

  return (
    <form>
      <Col>
        <Row>
          <InputWithLabel label="Name">
            <TextInput
              disabled={disabledMap?.givenName}
              errorMessage={errors.givenName?.message}
              {...register("givenName")}
            />
          </InputWithLabel>
          <InputWithLabel label="Surname">
            <TextInput
              disabled={disabledMap?.familyName}
              errorMessage={errors.familyName?.message}
              {...register("familyName")}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label="Email">
            <TextInput
              disabled={disabledMap?.email}
              errorMessage={errors.email?.message}
              {...register("email")}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label="Hide book RLC section">
            <Controller
              render={({ field }) => {
                return (
                  <BooleanInput onChange={field.onChange} value={field.value} />
                );
              }}
              name={"hideBookSpeakingClassSection"}
              control={control}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label="Hide book Progress Check section">
            <Controller
              render={({ field }) => {
                return (
                  <BooleanInput onChange={field.onChange} value={field.value} />
                );
              }}
              name={"hideBookProgressCheckSection"}
              control={control}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label={"Speaking level"}>
            <Controller
              disabled={disabledMap?.speakingLevel}
              control={control}
              render={({ field }) => {
                return (
                  <Select<SpeakingLevel>
                    disabled={disabledMap?.speakingLevel}
                    errorMessage={errors.speakingLevel?.message}
                    onChange={field.onChange}
                    value={field.value}
                    options={speakingClassLevelOptions}
                  />
                );
              }}
              name={"speakingLevel"}
            />
          </InputWithLabel>
        </Row>
        {showShouldEnrollToAllPaidContent ? (
          <Row>
            <InputWithLabel
              label={"Grant user access to all paid contents (Thinkific)"}
            >
              <Controller
                disabled={disabledMap?.shouldEnrollToAllPaidContent}
                control={control}
                render={({ field }) => {
                  return (
                    <BooleanInput
                      onChange={field.onChange}
                      value={field.value}
                    />
                  );
                }}
                name={"shouldEnrollToAllPaidContent"}
              />
            </InputWithLabel>
          </Row>
        ) : null}
        <Row>
          <InputWithLabel
            label={
              "Grant user access to Fluente Per Sempre speaking platform even if they didn't pay for it"
            }
          >
            <Controller
              disabled={disabledMap?.activateAccount}
              control={control}
              render={({ field }) => {
                return (
                  <BooleanInput onChange={field.onChange} value={field.value} />
                );
              }}
              name={"activateAccount"}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <div className={"flex w-full justify-end"}>
            <PrimaryButton
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={"Save"}
            />
          </div>
        </Row>
      </Col>
    </form>
  );
}
