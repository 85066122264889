import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export function LoginScreen() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    (async function login(): Promise<void> {
      await loginWithRedirect({});
    })();
  }, [loginWithRedirect]);

  return null;
}
