import { getFragmentData, graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";
import { CompleteClassRoomFragment } from "./CompleteClassRoomFragment.ts";

const GetClassRoomByIdQuery = graphql(`
  query adminGetClassRoomById($id: String!) {
    adminGetClassRoomById(classRoomId: $id) {
      ...CompleteClassRoom
    }
  }
`);
export function useClassRoomById(id: string) {
  const { data, loading, refetch } = useQuery(GetClassRoomByIdQuery, {
    variables: {
      id,
    },
  });

  return {
    classRoom: getFragmentData(
      CompleteClassRoomFragment,
      data?.adminGetClassRoomById,
    ),
    loading,
    refetch,
  };
}
