import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export function useToEditTopicScreen() {
  const navigate = useNavigate();

  const toEditTopic = useCallback(
    (id: string) => {
      navigate(`/topics/${id}/edit`);
    },
    [navigate],
  );

  return {
    toEditTopic,
  };
}
