import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const EnrollUserMutation = graphql(`
  mutation enrollUserToAllPaidContent($userId: String!) {
    enrollUserToAllPaidContent(userId: $userId)
  }
`);
export function useEnrollUserToAllPaidContent() {
  const [enrollUserToAllContentMutation, { loading }] =
    useMutation(EnrollUserMutation);

  const enrollUserToAllContent = useCallback(
    async (userId: string) => {
      await enrollUserToAllContentMutation({
        variables: {
          userId,
        },
      });
    },
    [enrollUserToAllContentMutation],
  );

  return { enrollUserToAllContent, loading };
}
