import { useParams } from "react-router-dom";

export function useClassRoomIdOrThrow() {
  const { classRoomId } = useParams<{
    classRoomId: string;
  }>();
  if (!classRoomId) {
    throw new Error("classRoomId is required");
  }
  return {
    classRoomId,
  };
}
