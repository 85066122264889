import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const AddParticipantsToClassRoomMutation = graphql(`
  mutation addParticipantsToClassByEmail(
    $classRoomId: String!
    $emails: [String!]!
  ) {
    addParticipantsToClassByEmail(
      classRoomId: $classRoomId
      userEmails: $emails
    ) {
      ...ClassRoomWithParticipants
    }
  }
`);
export function useAddParticipantsToClassRoom() {
  const [addParticipantsToClassRoomMutation, { loading }] = useMutation(
    AddParticipantsToClassRoomMutation,
  );

  const addParticipantsToClassRoom = useCallback(
    async ({
      classRoomId,
      emails,
    }: {
      classRoomId: string;
      emails: string[];
    }) => {
      await addParticipantsToClassRoomMutation({
        variables: { classRoomId, emails },
      });
    },
    [addParticipantsToClassRoomMutation],
  );

  return {
    addParticipantsToClassRoom,
    loading,
  };
}
