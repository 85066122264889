import { getFragmentData, graphql } from "../../gql";
import { useQuery } from "@apollo/client";
import { ClassRoomPlanFragment } from "./ClassRoomPlanFragment.ts";

const GetPlanByIdAndUserIdQuery = graphql(`
  query getClassRoomPlanByIdAndUserId($planId: String!, $userId: String!) {
    getClassRoomPlanByIdAndUserId(planId: $planId, userId: $userId) {
      ...ClassRoomPlanFragment
    }
  }
`);
export function usePlanByIdAndUserId({
  planId,
  userId,
}: {
  planId: string;
  userId: string;
}) {
  const { data, loading } = useQuery(GetPlanByIdAndUserIdQuery, {
    variables: {
      planId,
      userId,
    },
  });

  const plan = getFragmentData(
    ClassRoomPlanFragment,
    data?.getClassRoomPlanByIdAndUserId,
  );

  return {
    plan,
    loading,
  };
}
