import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useAllUsers } from "./useAllUsers.ts";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import { UserTable } from "./UserTable.tsx";
import { TextButton } from "../UI/TextButton.tsx";
import { DebouncedTextInput } from "../UI/DebouncedTextInput.tsx";
import { useCallback } from "react";
import { UserFragmentFragment } from "../gql/graphql.ts";
import { useToEditUser } from "./useToEditUser.ts";
import { useToCreateUserScreen } from "./useToCreateUserScreen.ts";
import { useToUserLessonFeedbackReport } from "./LessonFeedback/useToUserLessonFeedbackReport.ts";
import { useToAllUserClassRoomPlan } from "./ClassRoomPlan/useToAllUserClassRoomPlan.ts";
import { useFilterAndPaginationController } from "../QueryFilterUtils/useFilterAndPaginationController.tsx";
import { Card } from "../UI/Card.tsx";

type UserFilter = Partial<{
  email: string;
}>;
export const AllUsersScreenPath = "/users";
export function AllUserScreen() {
  const {
    toNextPage,
    toPrevPage,
    currentNextPageToken,
    currentPrevPageToken,
    filter,
    setFilter,
  } = useFilterAndPaginationController<UserFilter>();
  const {
    users,
    hasNextPage,
    nextPageToken,
    hasPrevPage,
    prevPageToken,
    loading,
  } = useAllUsers({
    limit: 20,
    nextPageToken: currentNextPageToken,
    prevPageToken: currentPrevPageToken,
    email: filter?.email,
  });

  const { toCreateUser } = useToCreateUserScreen();

  const { toEditUser } = useToEditUser();

  const { toUserLessonFeedbackReport } = useToUserLessonFeedbackReport();

  const { toAllUserClassRoomPlan } = useToAllUserClassRoomPlan();

  const onChangeEmailFilter = useCallback(
    (email: string) => {
      setFilter({
        email,
      });
    },
    [setFilter],
  );

  function goToEditUser(user: UserFragmentFragment): void {
    toEditUser(user.id);
  }

  function goToLessonFeedbackReport(user: UserFragmentFragment): void {
    toUserLessonFeedbackReport(user.id);
  }

  function goToUserClassRoomPlan(user: UserFragmentFragment): void {
    toAllUserClassRoomPlan(user.id);
  }

  return (
    <Layout>
      <Card>
        <ScreenTitleBlock
          title={"Users"}
          ctaLabel={"New User"}
          ctaOnClick={toCreateUser}
        />
      </Card>
      <div className={"mt-4"}>
        <Card>
          <div>
            <div className={"mb-4 flex space-x-2"}>
              <DebouncedTextInput
                initialValue={filter.email ?? ""}
                debounceInMs={500}
                onChange={onChangeEmailFilter}
                placeholder={"Email"}
              />
            </div>
            {loading ? (
              <CenteredLoading />
            ) : (
              <div>
                <UserTable
                  toUserClassRoomPlan={goToUserClassRoomPlan}
                  toFeedbackReport={goToLessonFeedbackReport}
                  editUser={goToEditUser}
                  users={users}
                />

                <div className={"flex w-full justify-between mt-4"}>
                  <div>
                    {hasPrevPage ? (
                      <TextButton
                        onClick={() => toPrevPage(prevPageToken as string)}
                        label={"Previous page"}
                      />
                    ) : null}
                  </div>
                  <div>
                    {hasNextPage ? (
                      <TextButton
                        onClick={() => toNextPage(nextPageToken as string)}
                        label={"Next page"}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
}
