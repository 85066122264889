import { useAllUsers } from "../User/useAllUsers.ts";
import { useRef, useState } from "react";
import {
  SpinnerContainer,
  useCloseOnClickOutside,
  LoaderSize,
} from "@normasteaching/norma-ui";
import { SpeakingLevel, UserFragmentFragment } from "../gql/graphql.ts";
import { useDebounce } from "use-debounce";
import { Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../UI/Utils/classNames.ts";
type Props = {
  onChange: (users: UserFragmentFragment[]) => void;
  value: UserFragmentFragment[];
  errorMessage?: string;
  withSpeakingLevel?: SpeakingLevel[];
};
export function UserListInput({
  onChange,
  value,
  errorMessage,
  withSpeakingLevel,
}: Props) {
  const [email, setEmail] = useState("");
  const [debouncedEmail] = useDebounce(email, 500);
  const { users, loading } = useAllUsers({
    limit: 100,
    email: debouncedEmail,
    skip: debouncedEmail.length <= 0,
    withSpeakingLevel,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const { isOpen, setIsOpen } = useCloseOnClickOutside(
    inputRef.current,
    panelRef.current,
  );

  const usersList = loading ? [] : users;

  function select(user: UserFragmentFragment) {
    setEmail("");
    onChange([...value, user]);
    setIsOpen(false);
  }

  function formatUser(user: UserFragmentFragment) {
    return `${user.givenName} ${user.familyName} (${user.email}) - ${user.speakingLevel}`;
  }

  return (
    <div>
      <SpinnerContainer size={LoaderSize.Small} loading={loading}>
        <div>
          <input
            onClick={() => setIsOpen(true)}
            ref={inputRef}
            placeholder={"Start searching by email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classNames(
              "p-2 rounded-lg min-h-[42px] border w-full",
              errorMessage ? "border-red-500" : "border-slate-200",
            )}
          />
          {errorMessage ? (
            <p className={"text-md mt-2 text-red-500"}>{errorMessage}</p>
          ) : null}
          <div className={"relative"}>
            <Transition
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={isOpen}
            >
              <div
                ref={panelRef}
                className={
                  "absolute bg-white top-1 shadow-md shadow-slate-300/50 rounded-md"
                }
              >
                {usersList.map((u) => {
                  return (
                    <div
                      onClick={() => select(u)}
                      className={
                        "p-3 hover:bg-primary hover:text-white cursor-pointer"
                      }
                      key={u.id}
                    >
                      <p>{formatUser(u)}</p>
                    </div>
                  );
                })}
              </div>
            </Transition>
          </div>
        </div>
      </SpinnerContainer>
      <div>
        {value.map((u) => {
          return (
            <div
              key={u.id}
              className={
                "flex items-center justify-between space-x-2 p-2 border border-slate-200 rounded-lg mt-2"
              }
            >
              <p>{formatUser(u)}</p>
              <XCircleIcon
                className={"w-6 text-red-500 hover:text-red-800 cursor-pointer"}
                onClick={() => {
                  onChange(value.filter((v) => v.id !== u.id));
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
