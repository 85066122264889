import { graphql } from "../gql";

export const UserFragment = graphql(`
  fragment UserFragment on User {
    id
    email
    speakingLevel
    givenName
    familyName
    authId
    partecipatedProgressCheckCount
    partecipatedSpeakingClassCount
    lessonFeedbacks {
      id
      noShow
      participation
      targetLanguage
      noShow
      notes
      classRoomEndDateUtc
      classRoomStartDateUtc
      teacher {
        id
        givenName
        familyName
        email
      }
    }
    progressCheckResults {
      teacher {
        givenName
        familyName
        email
        id
      }
      comprehension
      accuracy
      vocabularyRange
      fluency
      notes
      recommendation
      userId
      progressCheckLevel
      progressCheckStartedAtUtc
      passed
    }
    hideBookProgressCheckSection
    hideBookSpeakingClassSection
  }
`);
