import { CalendarRepresentation } from "./CalendarRepresentation.ts";
import { Interval } from "./Interval.tsx";
import { useCallback, useEffect, useState } from "react";
import { HourRepresentation } from "./HourRepresentation.ts";
import { CalendarItemMap } from "./CalendarItemMap.ts";

function createStdHours(): HourRepresentation[] {
  return Array.from(
    {
      length: 13,
    },
    (_, i) => {
      const offset = 9;
      return new HourRepresentation(offset + i, offset + i + 1);
    },
  );
}
export function useCalendar<T>({
  interval,
  items,
  onChangeInterval,
}: {
  interval: Interval;
  items: CalendarItemMap<T>;
  onChangeInterval: (interval: Interval) => void;
}) {
  const [calendar, setCalendar] = useState<CalendarRepresentation<T>>(
    new CalendarRepresentation(
      interval.getDays(),
      createStdHours(),
      items,
      interval,
    ),
  );

  const goNextPeriod = useCallback(() => {
    const newInterval = calendar.getInterval().nextPeriod();
    onChangeInterval(newInterval);
  }, [calendar, onChangeInterval]);
  const goPrevPeriod = useCallback(() => {
    const newInterval = calendar.getInterval().prevPeriod();
    onChangeInterval(newInterval);
  }, [calendar, onChangeInterval]);

  useEffect(() => {
    setCalendar(
      new CalendarRepresentation(
        interval.getDays(),
        createStdHours(),
        items,
        interval,
      ),
    );
  }, [items, interval]);

  return {
    calendar,
    goNextPeriod,
    goPrevPeriod,
  };
}
