import { Layout } from "../../UI/Layout/Layout.tsx";
import {
  ClassRoomPlanForm,
  ClassRoomPlanFormInputs,
} from "./ClassRoomPlanForm.tsx";
import { BreadCrumbsWrapper } from "../../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../../UI/Layout/BreadCrumbsItem.tsx";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { useUserIdParamsOrThrow } from "../useUserIdParamsOrThrow.ts";
import { useUserById } from "../useUserById.ts";
import { FullPageLoading } from "../../UI/Loading/FullPageLoading.tsx";
import { useToAllUsers } from "../useToAllUsers.ts";
import { useToAllUserClassRoomPlan } from "./useToAllUserClassRoomPlan.ts";
import { useCreateClassRoomPlan } from "./useCreateClassRoomPlan.ts";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Card } from "../../UI/Card.tsx";

export const CreateClassRoomPlanScreenPath =
  "/users/:userId/class-room-plans/new";
export function CreateClassRoomPlanScreen() {
  const { userId } = useUserIdParamsOrThrow();
  const { user, loading } = useUserById(userId);
  const { toAllUsers } = useToAllUsers();
  const { toAllUserClassRoomPlan } = useToAllUserClassRoomPlan();
  const { createClassRoomPlan } = useCreateClassRoomPlan();
  const { show, title, description, showPopup } = useMessagePopupController(
    4 * 1000,
  );
  const navigate = useNavigate();
  if (loading) return <FullPageLoading />;
  if (!user) return null; //TODO return not found

  async function saveClassRoomPlan(data: ClassRoomPlanFormInputs) {
    try {
      await createClassRoomPlan({
        startedAtWeekUtc: data.startAtWeek.toISOString(),
        endedAtWeekUtc: data.endAtWeek.toISOString(),
        lessonsPerWeek: data.lessonsPerWeek,
        userId: userId,
        planToRecoverId: data.planToRecoverId ?? undefined,
        warrantyConfig: data.planToRecoverId
          ? undefined
          : data.warrantyConfig
            ? {
                enabled: data.warrantyConfig?.enabled ?? false,
                minAttendedLessons: data.warrantyConfig?.minAttendedLessons,
              }
            : undefined,
      });
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message ?? "Unknown error");
      return;
    }
    navigate(-1);
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={`New plan`}
            subTitle={
              "Enter the details of the new plan. The dates of the created plan will go from the beginning of the week of the day you specify as the start, to the end of the week of the day you specify as the end. Weeks start on Sunday and end on Saturday"
            }
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllUsers} title={"Users"} />
                <BreadCrumbsItem
                  onClick={() => toAllUserClassRoomPlan(userId)}
                  title={`${user.givenName}'s active plans`}
                />
                <BreadCrumbsItem title={"New plan"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <ClassRoomPlanForm userId={userId} onSave={saveClassRoomPlan} />
          </Card>
        </div>
      </Layout>
    </>
  );
}
