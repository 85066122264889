import { MouseEvent } from "react";
import { PrimaryLoadingIndicator } from "./Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "./Loading/LoaderSize.tsx";

type Props = {
  label: string;
  href?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  loading?: boolean;
};
export function TextButton({ label, href, onClick, loading }: Props) {
  return (
    <a
      href={href}
      onClick={(e) => {
        if (!href) e.preventDefault();
        onClick?.(e);
      }}
      className={
        "hover:cursor-pointer hover:brightness-110 text-primary flex items-center space-x-2 underline"
      }
    >
      <span>{label}</span>
      {loading ? <PrimaryLoadingIndicator size={LoaderSize.Small} /> : null}
    </a>
  );
}
