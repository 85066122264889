import { forwardRef, InputHTMLAttributes } from "react";
import { classNames } from "./Utils/classNames.ts";
export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  errorMessage?: string;
  hasError?: boolean;
};
const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { className, errorMessage, hasError, ...otherProps } = props;
  return (
    <div className={"w-full flex flex-col space-y-2"}>
      <input
        className={classNames(
          className,
          "border p-2 rounded-lg w-full min-h-[42px]",
          errorMessage || hasError ? "border-red-500" : "border-slate-200",
          otherProps.disabled ? "bg-slate-100 cursor-not-allowed" : "bg-white",
        )}
        {...otherProps}
        ref={ref}
      />
      {errorMessage ? <p className={"text-red-500"}>{errorMessage}</p> : null}
    </div>
  );
});
export { TextInput };
