import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useNavigate } from "react-router-dom";
import { useTopicById } from "./useTopicById.ts";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import { TopicForm, TopicFormInputs } from "./TopicForm.tsx";
import { useEditTopic } from "./useEditTopic.ts";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllTopics } from "./useToAllTopics.ts";
import { ApolloError } from "@apollo/client";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useTopicIdParamsOrThrow } from "./useTopicIdParamsOrThrow.ts";
import { Card } from "../UI/Card.tsx";

export const EditTopicPath = "/topics/:topicId/edit";
export function EditTopicScreen() {
  const { topicId } = useTopicIdParamsOrThrow();
  const { topic, loading } = useTopicById(topicId);
  const { editTopic } = useEditTopic();
  const { toAllTopics } = useToAllTopics();
  const navigate = useNavigate();
  const { show, title, description, showPopup } = useMessagePopupController(
    4 * 1000,
  );
  async function onSave(t: TopicFormInputs) {
    try {
      await editTopic({
        id: topicId as string,
        nameIt: t.name,
        pdfUrl: t.pdfUrl,
      });
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message ?? "Unknown error");
      return;
    }

    navigate(-1);
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={"Edit Topic"}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllTopics} title={"Topics"} />
                <BreadCrumbsItem title={"Edit topic"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            {loading ? (
              <CenteredLoading />
            ) : (
              <TopicForm
                disabledMap={{
                  level: true,
                }}
                onSave={onSave}
                defaultValues={
                  topic
                    ? {
                        name: topic.name,
                        level: topic.level,
                        pdfUrl: topic.pdfUrl,
                      }
                    : undefined
                }
              />
            )}
          </Card>
        </div>
      </Layout>
    </>
  );
}
