import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { ClassRoomTeacherGraphqlFragment } from "./Graphql/ClassRoomTeacherGraphqlFragment.ts";
import dayjs from "dayjs";

const GetAvailableTeachersInHour = graphql(`
  query getAvailableTeachersInHour($startUtc: String!, $timezone: String!) {
    getAvailableTeachersInHour(startUtc: $startUtc, timezone: $timezone) {
      ...ClassRoomTeacherFragment
    }
  }
`);
export function useAvailableTeachersInHour({
  startDate,
}: {
  startDate?: Date;
}) {
  const { data, loading } = useQuery(GetAvailableTeachersInHour, {
    variables: {
      timezone: dayjs.tz.guess(),
      startUtc: startDate?.toISOString() as string,
    },
    skip: !startDate,
  });

  return {
    teachers: getFragmentData(
      ClassRoomTeacherGraphqlFragment,
      data?.getAvailableTeachersInHour ?? [],
    ),
    loading,
  };
}
