import { graphql } from "../../gql";

export const ClassRoomTeacherGraphqlFragment = graphql(`
  fragment ClassRoomTeacherFragment on ClassRoomTeacher {
    id
    email
    givenName
    familyName
    phone {
      countryCode
      number
    }
  }
`);
