import { ClassCalendarItemDto } from "./CalendarItemSlot.tsx";
import dayjs from "dayjs";
import { DropDown } from "../../UI/DropDown/DropDown.tsx";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline/index.js";
import { DropDownItem } from "../../UI/DropDown/DropDownItem.tsx";
import { useToClassRoomDetail } from "../../ClassRoom/useToClassRoomDetail.ts";
import { ClassRoomType, SpeakingClassLevel } from "../../gql/graphql.ts";
import { classNames } from "../../UI/Utils/classNames.ts";
import { useDeleteClassRoom } from "../../ClassRoom/useDeleteClassRoom.ts";
import { useWithLoading } from "../../UI/Loading/useWithLoading.ts";
import { PrimaryLoadingIndicator } from "../../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../../UI/Loading/LoaderSize.tsx";

type Props = {
  classRoom: ClassCalendarItemDto;
  onClassRoomDeleted: () => Promise<void>;
};

function getSpeakingLevelColorClass(speakingLevel: SpeakingClassLevel): string {
  switch (speakingLevel) {
    case SpeakingClassLevel.Explorers:
      return "border-norma-explorer bg-norma-explorer";
    case SpeakingClassLevel.Navigators:
      return "border-norma-navigator bg-norma-navigator";
    case SpeakingClassLevel.Pioneers:
      return "border-norma-pioneer bg-norma-pioneer";
    case SpeakingClassLevel.Voyagers:
      return "border-norma-voyager bg-norma-voyager";
    case SpeakingClassLevel.Starters:
      return "border-norma-starter bg-norma-starter";
    default:
      return "";
  }
}
export function ClassRoomCalendarItem({
  classRoom,
  onClassRoomDeleted,
}: Props) {
  const { toClassRoomDetail } = useToClassRoomDetail();
  const { deleteClassRoom } = useDeleteClassRoom();

  const { withLoading, loading } = useWithLoading();
  function formatHour(date: string) {
    return dayjs(date).format("HH:mm");
  }

  async function deleteClass() {
    if (window.confirm("Are you sure you want to delete this class?")) {
      await withLoading(
        async () => await deleteClassRoom(classRoom.classRoomId),
      );
      await onClassRoomDeleted();
    }
  }
  return (
    <div
      className={classNames(
        "border-2 p-2 rounded-lg bg-opacity-15 flex justify-between space-x-2",
        getSpeakingLevelColorClass(classRoom.speakingLevel),
      )}
    >
      <p className={"flex flex-col space-y-1 flex-1"}>
        <span className={"text-sm"}>{classRoom.speakingLevel}</span>
        <span>
          {formatHour(classRoom.startDateUtc)} -{" "}
          {formatHour(classRoom.endDateUtc)}
        </span>
        <span className={"font-semibold text-sm"}>
          {classRoom.classType === ClassRoomType.SpeakingClass
            ? classRoom.topic.name
            : "Progress Check"}
        </span>
      </p>
      {loading ? (
        <div className={"flex-shrink-0"}>
          <PrimaryLoadingIndicator size={LoaderSize.Small} />
        </div>
      ) : (
        <DropDown
          activator={<EllipsisHorizontalIcon className={"w-5 flex-shrink-0"} />}
        >
          <DropDownItem
            onClick={() => toClassRoomDetail(classRoom.classRoomId)}
          >
            Classroom details
          </DropDownItem>
          <DropDownItem onClick={deleteClass}>Delete classroom</DropDownItem>
        </DropDown>
      )}
    </div>
  );
}
