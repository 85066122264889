import { graphql } from "../gql/index.ts";

export const ClassRoomWithoutParticipantFragment = graphql(`
  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {
    id
    startedAtUtc
    endedAtUtc
    speakingLevel
    topic {
      name
    }
    participantsCount
    classRoomTimezone
    type
  }
`);
