import { ClassRoomPlanFragmentFragment } from "../../gql/graphql.ts";
import dayjs, { Dayjs } from "dayjs";
import { NormaPopover } from "../../UI/NormaPopover.tsx";
import { Card } from "../../UI/Card.tsx";
import {
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline/index.js";
import { PrimaryLoadingIndicator } from "../../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../../UI/Loading/LoaderSize.tsx";

type Props = {
  plan: ClassRoomPlanFragmentFragment;
  onDelete: (planId: string) => void;
  onEdit: (plan: ClassRoomPlanFragmentFragment) => void;
  deleting?: boolean;
};

export function ClassRoomPlanListItem({
  plan,
  onDelete,
  deleting,
  onEdit,
}: Props) {
  const startDate = dayjs(plan.startedAtWeekUtc);
  const endDate = dayjs(plan.endedAtWeekUtc);

  function printWeek(weekDate: Dayjs): string {
    return `${weekDate.week()} of ${weekDate.year()}`;
  }

  function formatWeekDate(weekDate: Dayjs): string {
    return `${weekDate.utc().format("ddd DD MMM YYYY")}`;
  }

  return (
    <Card>
      <div className={"flex space-x-2 justify-between"}>
        <div className={"space-y-2 flex-1"}>
          <p>
            From week{" "}
            <NormaPopover
              activator={
                <b className={"underline decoration-dotted underline-offset-4"}>
                  {printWeek(startDate)}
                </b>
              }
            >
              <p className={"whitespace-nowrap"}>{formatWeekDate(startDate)}</p>
            </NormaPopover>{" "}
            to week{" "}
            <NormaPopover
              activator={
                <b className={"underline decoration-dotted underline-offset-4"}>
                  {printWeek(endDate)}
                </b>
              }
            >
              <p className={"whitespace-nowrap"}>{formatWeekDate(endDate)}</p>
            </NormaPopover>{" "}
            with <b>{plan.lessonsPerWeek}</b> lessons per week
          </p>
          <p>{plan.numberOfSlotIncluded} included hours in this plan</p>
        </div>
        <div className={"flex space-x-2 items-start"}>
          <PencilSquareIcon
            onClick={() => onEdit(plan)}
            className={
              "w-5 h-5 text-slate-600 hover:text-slate-800 cursor-pointer"
            }
          />
          {deleting ? (
            <PrimaryLoadingIndicator size={LoaderSize.Small} />
          ) : (
            <TrashIcon
              onClick={() => onDelete(plan.id)}
              className={"w-5 text-red-600 cursor-pointer"}
            />
          )}
        </div>
      </div>
    </Card>
  );
}
