import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { TopicForm, TopicFormInputs } from "./TopicForm.tsx";
import { useCreateTopic } from "./useCreateTopic.ts";
import { useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllTopics } from "./useToAllTopics.ts";
import { Card } from "../UI/Card.tsx";
export const CreateTopicScreenPath = "/topics/new";
export function CreateTopicScreen() {
  const { createTopic } = useCreateTopic();
  const navigate = useNavigate();
  const { show, title, description, showPopup } = useMessagePopupController(
    4 * 1000,
  );
  const { toAllTopics } = useToAllTopics();
  async function saveTopic(topic: TopicFormInputs) {
    try {
      await createTopic({
        level: topic.level,
        nameIt: topic.name,
        pdfUrl: topic.pdfUrl,
      });
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message ?? "Unknown error");
      return;
    }

    navigate(-1);
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={"New topic"}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllTopics} title={"Topics"} />
                <BreadCrumbsItem title={"New topic"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <TopicForm onSave={saveTopic} />
          </Card>
        </div>
      </Layout>
    </>
  );
}
