import { PropsWithChildren, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { classNames } from "../Utils/classNames.ts";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Interval } from "./Interval.tsx";
type Props = {
  selectedDate: Date;
  onChange: (date: Date) => void;
  isDisabled?: (date: Date) => boolean;
  canNavigateBack?: (currentInterval: Interval) => boolean;
  canNavigateForward?: (currentInterval: Interval) => boolean;
  isSelectedDay?: (date: Date) => boolean;
};

function getDaysOfWeek(): Dayjs[] {
  const now = dayjs();
  const startDate = now.startOf("week");
  const endDate = now.endOf("week");
  const days: Dayjs[] = [];
  for (let i = startDate; i.isBefore(endDate); i = i.add(1, "day")) {
    days.push(i);
  }
  return days;
}

function MonthNavigationButton({
  onClick,
  children,
  disabled,
}: PropsWithChildren<{
  onClick: () => void;
  disabled?: boolean;
}>) {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={classNames(
        "p-2 hover:bg-slate-100",
        disabled ? "opacity-10 cursor-not-allowed" : "cursor-pointer",
      )}
    >
      {children}
    </div>
  );
}

export function CalendarDatePicker({
  selectedDate,
  onChange,
  isDisabled,
  canNavigateBack,
  isSelectedDay,
  canNavigateForward,
}: Props) {
  const [interval, setInterval] = useState(
    Interval.monthIntervalFromDate(dayjs(selectedDate)),
  );
  return (
    <div className={"space-y-4 select-none"}>
      <div className={"flex items-center space-x-2 justify-between"}>
        <div>
          <MonthNavigationButton
            disabled={!(canNavigateBack?.(interval) ?? true)}
            onClick={() => setInterval(interval.add(-1, "month"))}
          >
            <ArrowLeftIcon className={"w-5"} />
          </MonthNavigationButton>
        </div>
        <h3 className={"font-bold text-center"}>
          {interval.fromDate.format("MMMM YYYY")}
        </h3>
        <div>
          <MonthNavigationButton
            disabled={!(canNavigateForward?.(interval) ?? true)}
            onClick={() => setInterval(interval.add(1, "month"))}
          >
            <ArrowRightIcon className={"w-5"} />
          </MonthNavigationButton>
        </div>
      </div>
      <div className={"grid gap-2 grid-cols-7"}>
        {getDaysOfWeek().map((d) => (
          <div
            key={d.valueOf()}
            className={"flex justify-center font-medium text-sm xs:text-base"}
          >
            <span>{d.format("ddd")}</span>
          </div>
        ))}
        {new Interval(
          interval.fromDate.startOf("week"),
          interval.toDate.endOf("week"),
        )
          .getDays()
          .map((d) => {
            const isSelected = isSelectedDay
              ? isSelectedDay(d.toDate())
              : dayjs(selectedDate).isSame(d, "day");
            const isDisabledDay = isDisabled?.(d.toDate()) ?? false;
            return (
              <div
                className={"flex justify-center items-center"}
                key={d.valueOf()}
              >
                <div
                  onClick={
                    isDisabledDay ? undefined : () => onChange(d.toDate())
                  }
                  className={classNames(
                    "text-sm p-1 xs:p-2 w-8 h-8 xs:w-10 xs:h-10 flex  shrink-0 items-center justify-center rounded-full border-slate-200 border",
                    isSelected ? "bg-primary text-white" : "bg-white",
                    isDisabledDay
                      ? "opacity-30 cursor-not-allowed"
                      : "hover:bg-primary hover:brightness-110 hover:text-white cursor-pointer",
                  )}
                >
                  <span>{d.format("D")}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
