import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateClassRoomPlanScreenPath } from "./UpdateClassRoomPlanScreen.tsx";

export function useToUpdateClassRoomPlanScreen() {
  const navigate = useNavigate();
  const toEditPlan = useCallback(
    ({ planId, userId }: { planId: string; userId: string }) => {
      navigate(
        UpdateClassRoomPlanScreenPath.replace(":userId", userId).replace(
          ":planId",
          planId,
        ),
      );
    },
    [navigate],
  );

  return {
    toUpdatePlanScreen: toEditPlan,
  };
}
