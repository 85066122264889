import { PropsWithChildren, ReactNode } from "react";

export function InputWithLabel({
  children,
  label,
  smallText,
  rightElement,
}: PropsWithChildren<{
  label: string;
  smallText?: string;
  rightElement?: ReactNode;
}>) {
  return (
    <div className={"w-full"}>
      <div className={"flex flex-col"}>
        <div className={"flex justify-between space-x-2"}>
          <span className={"flex flex-col mb-2"}>
            <span className={"font-bold"}>{label}</span>
            {smallText ? (
              <span className={"text-sm text-slate-600"}>{smallText}</span>
            ) : null}
          </span>
          {rightElement ?? null}
        </div>
        {children}
      </div>
    </div>
  );
}
