import { decode, encode } from "js-base64";

export class UrlBase64Obj<T> {
  constructor(public readonly value: T) {}

  public toString(): string {
    return encode(JSON.stringify(this.value));
  }

  public static fromString<T>(s: string): UrlBase64Obj<T> {
    const v = JSON.parse(decode(s)) as T;
    return new UrlBase64Obj<T>(v);
  }
}
