import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { LessonFeedbackReportScreenPath } from "./LessonFeedbackReportScreen.tsx";

export function useToUserLessonFeedbackReport() {
  const navigate = useNavigate();

  const toUserLessonFeedbackReport = useCallback(
    (userId: string) => {
      navigate(LessonFeedbackReportScreenPath.replace(":userId", userId));
    },
    [navigate],
  );

  return {
    toUserLessonFeedbackReport,
  };
}
