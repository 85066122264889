import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetNotResolvedSpeakingLevelSuggestionsQuery } from "./useNotResolvedSpeakingLevelSuggestions.ts";

const ResolveLevelSuggestionMutation = graphql(`
  mutation resolveLevelSuggestion($suggestionId: String!) {
    resolveLevelSuggestion(suggestionId: $suggestionId)
  }
`);
export function useResolveLevelSuggestion() {
  const [resolveLevelSuggestionMutation, { loading }] = useMutation(
    ResolveLevelSuggestionMutation,
    {
      refetchQueries: [GetNotResolvedSpeakingLevelSuggestionsQuery],
    },
  );

  const resolveLevelSuggestion = useCallback(
    async (suggestionId: string) => {
      await resolveLevelSuggestionMutation({
        variables: {
          suggestionId,
        },
      });
    },
    [resolveLevelSuggestionMutation],
  );

  return {
    loading,
    resolveLevelSuggestion,
  };
}
