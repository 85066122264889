import useSWR from "swr";
import { useSpeakingClassAxiosInstance } from "../useSpeakingClassAxiosInstance.ts";
import dayjs from "dayjs";
import { CapacityReportEntryDTO } from "./CapacityReportEntryDTO.ts";

export function useAvailabilityEntries({
  fromDate,
  toDate,
}: {
  fromDate: Date;
  toDate: Date;
}) {
  const format = "YYYY-MM-DD";
  const instance = useSpeakingClassAxiosInstance();
  const { data, isLoading } = useSWR(
    `/capacity-report/${fromDate.toISOString()}/${toDate.toISOString()}`,
    async () => {
      const { data } = await instance.get<CapacityReportEntryDTO[]>(
        "/capacity-report",
        {
          params: {
            timezone: dayjs.tz.guess(),
            startDate: dayjs(fromDate).format(format),
            endDate: dayjs(toDate).format(format),
          },
        },
      );
      return data;
    },
  );

  return {
    entries: data ?? [],
    isLoading,
  };
}
