import { ProgressCheckRecommendation } from "../../gql/graphql.ts";

export function getRecommendationLabel(r: ProgressCheckRecommendation): string {
  switch (r) {
    case ProgressCheckRecommendation.MoreAccuracy:
      return "More accuracy: We recommend more exercises and keeping a journal of your mistakes when the teacher corrects you";
    case ProgressCheckRecommendation.MoreFluency:
      return "More fluency: We recommend more practice speaking in and outside of the classroom to improve your general fluency.";
    case ProgressCheckRecommendation.MoreImmersion:
      return "More immersion: We recommend listening to podcasts, tv shows, music, movies and videos in English in order to improve your comprehension.";
    case ProgressCheckRecommendation.MoreVocabulary:
      return "More vocabulary: We recommend reading articles, watching TED talks, keeping a vocabulary journal or creating flashcards to widen your range of vocabulary.";
    default:
      throw new Error(`Unknown recommendation: ${r}`);
  }
}
