import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { AllUserClassRoomPlanScreenPath } from "./AllUserClassRoomPlanScreen.tsx";

export function useToAllUserClassRoomPlan() {
  const navigate = useNavigate();

  const toAllUserClassRoomPlan = useCallback(
    (userId: string) => {
      navigate(AllUserClassRoomPlanScreenPath.replace(":userId", userId));
    },
    [navigate],
  );

  return {
    toAllUserClassRoomPlan,
  };
}
