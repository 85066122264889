import {
  LevelChangeSuggestionDto,
  UserFragmentFragment,
} from "../../gql/graphql.ts";
import { BasicTable } from "../../UI/Table/BasicTable.tsx";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DropDown } from "../../UI/DropDown/DropDown.tsx";
import {
  CheckCircleIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { DropDownItem } from "../../UI/DropDown/DropDownItem.tsx";

type Props = {
  suggestions: LevelChangeSuggestionDto[];
  toUser: (user: UserFragmentFragment) => void;
  onResolveSuggestion: (suggestion: LevelChangeSuggestionDto) => Promise<void>;
};
export function SpeakingLevelSuggestionTable({
  suggestions,
  toUser,
  onResolveSuggestion,
}: Props) {
  const columnHelper = createColumnHelper<LevelChangeSuggestionDto>();
  const columns = [
    columnHelper.accessor("user.email", {
      cell: (info) => info.getValue(),
      header: "Email",
    }),
    columnHelper.accessor("suggestedLevels", {
      cell: (info) => info.getValue()?.join(", ") ?? "",
      header: "Suggested Levels",
    }),
    columnHelper.accessor("user.lessonFeedbacks", {
      cell: (info) => info.getValue()?.length ?? 0,
      header: "Lesson Feedbacks",
    }),
    columnHelper.display({
      id: "actions",
      cell: (v) => (
        <div className={"flex justify-end"}>
          <div>
            <DropDown
              activator={
                <EllipsisHorizontalIcon
                  className={"text-slate-800 w-5 h-5 group-hover:text-white"}
                />
              }
            >
              <DropDownItem onClick={() => toUser(v.row.original.user)}>
                <EyeIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
                Go to User
              </DropDownItem>
              <DropDownItem onClick={() => onResolveSuggestion(v.row.original)}>
                <CheckCircleIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
                Resolve Suggestion
              </DropDownItem>
            </DropDown>
          </div>
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    columns,
    data: suggestions,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
  });
  return <BasicTable table={table} />;
}
