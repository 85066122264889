import { flexRender, Table } from "@tanstack/react-table";

export function BasicTable<T>({ table }: { table: Table<T> }) {
  const rows = table.getRowModel().rows;
  return (
    <table className={"w-full text-sm"}>
      <thead className={"text-gray-700"}>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={"px-6 py-3 text-left bg-gray-50 uppercase"}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr
              key={row.id}
              className={i < rows.length - 1 ? "border-b border-gray-100" : ""}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td className={"px-6 py-3"} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
