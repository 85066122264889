import { PrimaryButton } from "../PrimaryButton.tsx";
import { ReactElement, ReactNode } from "react";

export function ScreenTitleBlock({
  title,
  ctaLabel,
  ctaOnClick,
  loading,
  breadCrumbs,
  subTitle,
}: {
  title: string;
  ctaLabel?: string;
  ctaOnClick?: () => void;
  loading?: boolean;
  breadCrumbs?: ReactElement;
  subTitle?: string | ReactNode;
}) {
  function hasCta(): boolean {
    return !!(ctaLabel && ctaOnClick);
  }
  return (
    <>
      <div className={"flex flex-col space-y-3"}>
        {breadCrumbs ?? null}
        <div className={"flex items-center justify-between"}>
          <h1 className={"font-bold text-2xl"}>{title}</h1>
          {hasCta() ? (
            <PrimaryButton
              loading={loading}
              onClick={ctaOnClick}
              label={ctaLabel ?? ""}
            />
          ) : null}
        </div>
        {subTitle ? (
          typeof subTitle === "string" ? (
            <p>{subTitle}</p>
          ) : (
            subTitle
          )
        ) : null}
      </div>
    </>
  );
}
