import { Fragment, PropsWithChildren, ReactNode } from "react";
import { Popover, Transition } from "@headlessui/react";

export function NormaPopover({
  activator,
  children,
}: PropsWithChildren<{
  activator: ReactNode;
}>) {
  return (
    <div className="inline-block">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button className={""}>{activator}</Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 bg-white p-4">
                  {children ?? null}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
