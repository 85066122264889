import { useParams } from "react-router-dom";

export function useUserIdParamsOrThrow() {
  const { userId } = useParams<{
    userId: string;
  }>();
  if (!userId) throw new Error(`userId is required`);

  return {
    userId,
  };
}
