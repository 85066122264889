import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function useToEditUser() {
  const navigate = useNavigate();
  const toEditUser = useCallback(
    (userId: string) => {
      navigate(`/users/${userId}`);
    },
    [navigate],
  );

  return {
    toEditUser,
  };
}
