import { graphql } from "../gql/index.ts";

export const FranchiseeFragment = graphql(`
  fragment FranchiseeItem on Franchisee {
    id
    email
    familyName
    givenName
    phoneNumber {
      countryCode
      number
    }
  }
`);
