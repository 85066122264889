import { useQuery } from "@apollo/client";
import { UserFragment } from "./UserFragment.ts";
import { getFragmentData, graphql } from "../gql";

const GetUserUserByIdQuery = graphql(`
  query getUserById($userId: String!) {
    getUserById(userId: $userId) {
      ...UserFragment
    }
  }
`);
export function useUserById(id: string) {
  const { data, loading, refetch } = useQuery(GetUserUserByIdQuery, {
    variables: {
      userId: id,
    },
  });

  return {
    user: getFragmentData(UserFragment, data?.getUserById),
    loading,
    refetch,
  };
}
