import { graphql } from "../gql/index.ts";

export const CompleteClassRoomFragment = graphql(`
  fragment CompleteClassRoom on CompleteClassRoom {
    id
    speakingLevel
    startedAtUtc
    endedAtUtc
    topic {
      name
    }
    participants {
      id
      givenName
      familyName
      email
    }
    capacity
    teacher {
      email
      id
      familyName
      givenName
    }
    conferenceUri
    feedbackUrl
    feedbackReportUrl
  }
`);
