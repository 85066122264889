import { Dayjs } from "dayjs";

import { HourRepresentation } from "./HourRepresentation.ts";

export class SlotDayAndHourKey {
  public static readonly formatDateString = "YYYY-MM-DD";
  constructor(
    public readonly dateString: string,
    public readonly hourString: string,
  ) {}

  public static fromDate(date: Dayjs): SlotDayAndHourKey {
    return new SlotDayAndHourKey(
      date.format(this.formatDateString),
      date.hour().toString(),
    );
  }

  public static fromDateAndHourRepresentation(
    date: Dayjs,
    hour: HourRepresentation,
  ): SlotDayAndHourKey {
    return new SlotDayAndHourKey(
      date.format(this.formatDateString),
      hour.from.toString(),
    );
  }
}
