import { MouseEvent } from "react";
import { WhiteLoadingIndicator } from "./Loading/WhiteLoadingIndicator.tsx";
import { classNames } from "./Utils/classNames.ts";

type Props = {
  label: string;
  href?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  loading?: boolean;
  disabled?: boolean;
};
export function OutlinedButton({
  label,
  href,
  onClick,
  loading,
  disabled,
}: Props) {
  return (
    <a
      href={href}
      onClick={(e) => {
        if (!href) e.preventDefault();
        if (disabled) return;
        onClick?.(e);
      }}
      className={classNames(
        "border rounded-lg text-primary px-6 py-2 flex items-center space-x-2",
        disabled
          ? "border-slate-400 cursor-not-allowed text-slate-400"
          : "hover:cursor-pointer hover:brightness-110 hover:bg-primary hover:text-white border-primary",
      )}
    >
      <span>{label}</span>
      {loading ? <WhiteLoadingIndicator /> : null}
    </a>
  );
}
