import { graphql } from "../../gql";

export const LevelChangeSuggestionFragment = graphql(`
  fragment LevelChangeSuggestion on LevelChangeSuggestionDto {
    id
    suggestedLevels
    user {
      ...UserFragment
    }
  }
`);
