import { FranchiseeItemFragment } from "../gql/graphql.ts";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { BasicTable } from "../UI/Table/BasicTable.tsx";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { DropDownItem } from "../UI/DropDown/DropDownItem.tsx";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline/index.js";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import { useToTeacherAvailability } from "./TeacherAvailability/useToTeacherAvailability.ts";

export function FranchiseeTable(props: {
  franchisees: FranchiseeItemFragment[];
  editFranchisee: (franchisee: FranchiseeItemFragment) => void;
}) {
  const franchisees = props.franchisees;
  const columnHelper = createColumnHelper<FranchiseeItemFragment>();
  const { toTeacherAvailability } = useToTeacherAvailability();
  const columns = [
    columnHelper.accessor("givenName", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Name",
    }),
    columnHelper.accessor("familyName", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Surname",
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Email",
    }),
    columnHelper.accessor("phoneNumber", {
      cell: (info) => {
        const v = info.getValue();
        return v ? `${v.countryCode} ${v.number}` : "";
      },
      footer: (info) => info.column.id,
      header: "Phone number",
    }),
    columnHelper.display({
      id: "actions",
      cell: (v) => (
        <div className={"flex justify-end"}>
          <div>
            <DropDown
              activator={
                <EllipsisHorizontalIcon
                  className={"text-slate-800 w-5 h-5 group-hover:text-white"}
                />
              }
            >
              <DropDownItem
                onClick={() => toTeacherAvailability(v.row.original.id)}
              >
                <CalendarIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
                Availability
              </DropDownItem>
            </DropDown>
          </div>
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    columns,
    data: franchisees as Array<FranchiseeItemFragment>,
    getCoreRowModel: getCoreRowModel(),
  });
  return <BasicTable table={table} />;
}
