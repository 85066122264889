import { useNavigate } from "react-router-dom";
import { AllTopicScreenPath } from "./TopicScreen.tsx";
import { useCallback } from "react";

export function useToAllTopics() {
  const navigate = useNavigate();

  const toAllTopics = useCallback(() => {
    navigate(AllTopicScreenPath);
  }, [navigate]);

  return {
    toAllTopics,
  };
}
