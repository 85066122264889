import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { UserFragment } from "./UserFragment.ts";
import { SpeakingLevel } from "../gql/graphql.ts";

const GetALlUsersQuery = graphql(`
  query listAllUsers(
    $nextPageToken: String
    $limit: Int!
    $email: String
    $prevPageToken: String
    $withSpeakingLevel: [SpeakingLevel]
  ) {
    listAllUsers(
      nextPageToken: $nextPageToken
      limit: $limit
      email: $email
      prevPageToken: $prevPageToken
      withSpeakingLevel: $withSpeakingLevel
    ) {
      users {
        ...UserFragment
      }
      hasNextPage
      nextPageToken
      hasPrevPage
      prevPageToken
    }
  }
`);
export function useAllUsers({
  nextPageToken,
  prevPageToken,
  limit,
  email,
  skip,
  withSpeakingLevel,
}: {
  limit: number;
  nextPageToken?: string;
  prevPageToken?: string;
  email?: string;
  skip?: boolean;
  withSpeakingLevel?: SpeakingLevel[];
}) {
  const { data, loading } = useQuery(GetALlUsersQuery, {
    variables: {
      limit,
      nextPageToken,
      email,
      prevPageToken,
      withSpeakingLevel,
    },
    skip,
    fetchPolicy: "cache-and-network",
  });

  return {
    users: getFragmentData(UserFragment, data?.listAllUsers?.users ?? []),
    hasNextPage: data?.listAllUsers?.hasNextPage ?? false,
    nextPageToken: data?.listAllUsers?.nextPageToken,
    hasPrevPage: data?.listAllUsers?.hasPrevPage ?? false,
    prevPageToken: data?.listAllUsers?.prevPageToken,
    loading,
  };
}
