import {
  AgColumn,
  CellStyleFunc,
  ColDef,
  ColTypeDef,
  GetContextMenuItemsParams,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  IMenuActionParams,
} from "ag-grid-enterprise";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useApolloClient } from "@apollo/client";
import { ClassRoomAgGridDTO } from "./ClassRoomAgGridDTO.ts";
import dayjs from "dayjs";
import { ClassRoomListDataSource } from "./ClassRoomListDataSource.tsx";
import { ClassRoomType, SpeakingLevel } from "../../gql/graphql.ts";
import { OutlinedButton } from "../../UI/OutlinedButton.tsx";
import { AgGridColumnStateRepository } from "../../AgGrid/AgGridColumnStateRepository.ts";
import { GlobalSpinner } from "../../UI/Loading/GlobalSpinner.tsx";
import { isInteger } from "../../DataUtils/isInteger.ts";

const agGridId = "classRoomAgGrid";
function renderDate(date: string): string {
  return date ? dayjs(date).format("YYYY-MM-DD [at] HH:mm") : "";
}
const classRoomTypeValueFormatter = (params: { value: string }) => {
  return params.value === ClassRoomType.SpeakingClass
    ? "Real Life Conversation"
    : "Progress Check";
};

type Props = {
  toClassRoomDetail: (classRoomId: string) => void;
  deleteClassRoom: (classRoom: ClassRoomAgGridDTO) => Promise<void>;
  rightButtons?: ReactNode[];
};
export function ClassRoomAgGrid({
  toClassRoomDetail,
  deleteClassRoom,
  rightButtons,
}: Props) {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const apolloClient = useApolloClient();
  const gridRef = useRef<AgGridReact<ClassRoomAgGridDTO>>(null);
  const columnStateRepo = useMemo(() => new AgGridColumnStateRepository(), []);
  const [deletingClassroom, setDeletingClassRoom] = useState(false);
  const deletedClassCellStyle: CellStyleFunc = useCallback(
    (params: { data: ClassRoomAgGridDTO }) => {
      if (!params.data.deletedAt)
        return {
          backgroundColor: "",
        };
      return {
        backgroundColor: "#fda3a3",
      };
    },
    [],
  );
  const [columnDefs] = useState<ColDef<ClassRoomAgGridDTO>[]>([
    {
      initialHide: true,
      headerName: "ID",
      field: "id",
      type: "text",
    },
    {
      headerName: "Type",
      field: "type",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      filterParams: {
        filterOptions: ["contains"],
        values: [ClassRoomType.SpeakingClass, ClassRoomType.Exam],
        valueFormatter: classRoomTypeValueFormatter,
      },
      valueFormatter: classRoomTypeValueFormatter,
    },
    {
      headerName: "Fluency Personality",
      field: "level",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      filterParams: {
        filterOptions: ["contains"],
        values: [
          SpeakingLevel.Voyagers,
          SpeakingLevel.Navigators,
          SpeakingLevel.Pioneers,
          SpeakingLevel.Explorers,
          SpeakingLevel.Starters,
        ],
      },
    },
    {
      headerName: "Teacher Email",
      field: "teacher.email",
      type: "text",
      sortable: false,
      filterParams: {
        filterOptions: ["contains"],
      },
    },
    {
      headerName: "Teacher Name",
      field: "teacher.givenName",
      type: "text",
      sortable: false,
      filter: false,
    },
    {
      headerName: "Teacher Surname",
      field: "teacher.familyName",
      type: "text",
      sortable: false,
      filter: false,
    },
    {
      headerName: "Topic",
      field: "topic.name.it",
      type: "text",
      filterParams: {
        filterOptions: ["contains"],
      },
    },
    {
      headerName: "Participants",
      field: "participantsCount",
      type: "number",
      sortable: false,
      enableValue: true,
      cellRenderer: (params: {
        value: number;
        data: {
          capacity: number;
        };
        column: AgColumn;
      }) => {
        if (params.column.isValueActive())
          return isInteger(params.value)
            ? params.value
            : params.value?.toFixed(2);
        const capacity = params.data?.capacity ?? 0;
        if (capacity <= 0) return "";
        return `${params.value ?? 0}/${capacity}`;
      },
    },
    {
      headerName: "Start date",
      field: "startUtc",
      cellRenderer: (params: { value: string }) => {
        return renderDate(params.value);
      },
      type: "date",
    },
    {
      headerName: "End date",
      field: "endUtc",
      cellRenderer: (params: { value: string }) => {
        return renderDate(params.value);
      },
      type: "date",
    },
    {
      headerName: "Deleted at",
      field: "deletedAt",
      cellRenderer: (params: { value: string }) => {
        return params.value ? renderDate(params.value) : "";
      },
      type: "date",
    },
    {
      headerName: "Progress check feedback",
      field: "progressCheckResultCount",
      sortable: false,
      type: "number",
      cellRenderer: (params: {
        value: number;
        data: {
          userIds: string[];
        };
      }) => {
        const userCount = params.data.userIds?.length ?? 0;
        if (userCount <= 0) return "";
        return `${params.value ?? 0}/${userCount}`;
      },
    },
    {
      headerName: "Lesson feedback",
      field: "lessonFeedbackCount",
      sortable: false,
      type: "number",
      cellRenderer: (params: {
        value: number;
        data: {
          userIds: string[];
        };
      }) => {
        const userCount = params.data.userIds?.length ?? 0;
        if (userCount <= 0) return "";
        return `${params.value ?? 0}/${userCount}`;
      },
    },
    {
      headerName: "Created at",
      field: "createdAt",
      cellRenderer: (params: { value: string }) => {
        return renderDate(params.value);
      },
      type: "date",
      sort: "desc",
    },
  ]);
  const defaultColDef = useMemo<ColDef<ClassRoomAgGridDTO>>(() => {
    return {
      flex: 1,
      minWidth: 150,
      floatingFilter: true,
      cellStyle: deletedClassCellStyle,
    };
  }, [deletedClassCellStyle]);

  const columnTypes = useMemo<{
    [key: string]: ColTypeDef;
  }>(() => {
    return {
      text: { filter: "agTextColumnFilter" },
      date: {
        filter: "agDateColumnFilter",
      },
      number: {
        filter: "agNumberColumnFilter",
      },
    };
  }, []);
  const getRowId: GetRowIdFunc<ClassRoomAgGridDTO> = useCallback(function (
    params: GetRowIdParams<ClassRoomAgGridDTO>,
  ) {
    return params.data.id;
  }, []);

  const saveColumnState = useCallback(() => {
    const state = gridRef.current?.api?.getColumnState();
    columnStateRepo.saveState(agGridId, state);
  }, [columnStateRepo]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.setGridOption(
        "serverSideDatasource",
        new ClassRoomListDataSource(apolloClient),
      );
      params.api.applyColumnState({
        state: columnStateRepo.getState(agGridId),
        applyOrder: true,
      });
    },
    [apolloClient, columnStateRepo],
  );
  const resetColumnState = () => {
    columnStateRepo.clearState(agGridId);
    gridRef.current?.api?.resetColumnState();
  };

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      return [
        ...(params.defaultItems || []),
        "separator",
        {
          name: "View Classroom Details",
          action: (params: IMenuActionParams<ClassRoomAgGridDTO>) => {
            if (!params.node?.data) return;
            toClassRoomDetail(params.node.data.id);
          },
        },
        {
          name: "Delete Classroom",
          action: async (params: IMenuActionParams<ClassRoomAgGridDTO>) => {
            if (!params.node?.data) return;
            try {
              setDeletingClassRoom(true);
              await deleteClassRoom(params.node.data);
              gridRef.current?.api.refreshServerSide();
            } finally {
              setDeletingClassRoom(false);
            }
          },
        },
      ];
    },
    [toClassRoomDetail, deleteClassRoom],
  );

  const getChildCount = useCallback((row: { childCount: number }) => {
    return row.childCount;
  }, []);

  return (
    <div className={"w-full h-full flex flex-col"}>
      <GlobalSpinner
        loading={deletingClassroom}
        message={"Deleting classroom"}
      />
      <div className={"mb-4 mr-auto flex space-x-2 justify-between w-full"}>
        <OutlinedButton
          onClick={resetColumnState}
          label={"Reset column state"}
        />
        <div className={"space-x-2"}>{rightButtons ?? null}</div>
      </div>
      <div className={"flex-1"}>
        <div style={containerStyle}>
          <div style={gridStyle} className={"ag-theme-quartz"}>
            <AgGridReact<ClassRoomAgGridDTO>
              ref={gridRef}
              getContextMenuItems={getContextMenuItems}
              enableCellTextSelection
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowModelType={"serverSide"}
              cacheBlockSize={100}
              cacheOverflowSize={2}
              maxConcurrentDatasourceRequests={2}
              infiniteInitialRowCount={1}
              maxBlocksInCache={2}
              getRowId={getRowId}
              onGridReady={onGridReady}
              columnTypes={columnTypes}
              onSortChanged={saveColumnState}
              onColumnResized={saveColumnState}
              onColumnVisible={saveColumnState}
              onColumnPivotChanged={saveColumnState}
              onColumnRowGroupChanged={saveColumnState}
              onColumnValueChanged={saveColumnState}
              onColumnMoved={saveColumnState}
              onColumnPinned={saveColumnState}
              rowGroupPanelShow={"onlyWhenGrouping"}
              getChildCount={getChildCount}
              sideBar
              initialState={{
                filter: {
                  filterModel: {
                    deletedAt: {
                      filterType: "date",
                      type: "blank",
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
