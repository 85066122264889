import { Layout } from "../UI/Layout/Layout.tsx";
import { useAllTopics } from "./useAllTopics.ts";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useToCreateTopicScreen } from "./useToCreateTopicScreen.ts";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import { TopicTable } from "./TopicTable.tsx";
import { useToEditTopicScreen } from "./useToEditTopicScreen.ts";
import { Card } from "../UI/Card.tsx";
import { useLogicallyDeleteTopic } from "./useLogicallyDeleteTopic.ts";
import { GlobalSpinner } from "../UI/Loading/GlobalSpinner.tsx";
import { TopicItemFragment } from "../gql/graphql.ts";
export const AllTopicScreenPath = "/topics";
export function TopicScreen() {
  const { topics, loading } = useAllTopics();
  const { toCreateTopic } = useToCreateTopicScreen();
  const { toEditTopic } = useToEditTopicScreen();
  const { logicallyDeleteTopic, loading: deletingTopic } =
    useLogicallyDeleteTopic();

  async function deleteTopic(topic: TopicItemFragment) {
    if (
      window.confirm(
        `Are you sure you want to delete ${topic.name} of level ${topic.level}`,
      )
    ) {
      await logicallyDeleteTopic(topic.id);
    }
  }

  return (
    <>
      <GlobalSpinner loading={deletingTopic} message={"Deleting topic"} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={"Topics"}
            ctaOnClick={() => toCreateTopic()}
            ctaLabel={"New Topic"}
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            {loading ? (
              <CenteredLoading />
            ) : (
              <TopicTable
                deleteTopic={deleteTopic}
                topics={topics}
                editTopic={(topic) => toEditTopic(topic.id)}
              />
            )}
          </Card>
        </div>
      </Layout>
    </>
  );
}
