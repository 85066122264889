import { ClassRoomType, SpeakingClassLevel, Topic } from "../../gql/graphql.ts";

export enum CalendarItemType {
  AvailabilitySlot = "AvailabilitySlot",
  ClassCalendarItem = "ClassCalendarItem",
}

export type ClassCalendarItemDto = {
  type: CalendarItemType.ClassCalendarItem;
  classRoomId: string;
  startDateUtc: string;
  endDateUtc: string;
  classTimezone: string;
  topic: Topic;
  speakingLevel: SpeakingClassLevel;
  classType: ClassRoomType;
};
export type AvailabilitySlotCalendarItem = {
  slotId: string;
  startDateUtc: string;
  endDateUtc: string;
  type: CalendarItemType.AvailabilitySlot;
  slotTimezone: string;
};
export type CalendarItemSlot =
  | AvailabilitySlotCalendarItem
  | ClassCalendarItemDto;
