import { Layout } from "../../UI/Layout/Layout.tsx";
import { Card } from "../../UI/Card.tsx";
import { CreateClassroomForm } from "./CreateClassroomForm.tsx";
import { useCreateClassRoom } from "./useCreateClassRoom.ts";
import { useToClassRoomDetail } from "../useToClassRoomDetail.ts";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { BreadCrumbsWrapper } from "../../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../../UI/Layout/BreadCrumbsItem.tsx";
import { useNavigate } from "react-router-dom";
import { AgGridClassRoomListPath } from "../AgGridClassRoomListScreen.tsx";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
export const CreateClassroomScreenPath = "/class-rooms/new";
export function CreateClassroomScreen() {
  const { createClassRoom } = useCreateClassRoom();

  const { toClassRoomDetail } = useToClassRoomDetail();
  const navigate = useNavigate();
  const { show, showPopup, description, title } = useMessagePopupController(
    4 * 1000,
  );
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={"New classroom"}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem
                  onClick={() => navigate(AgGridClassRoomListPath)}
                  title={"All classrooms"}
                />
                <BreadCrumbsItem title={"New classroom"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <CreateClassroomForm
              onCreate={async (data) => {
                try {
                  const classRoomId = await createClassRoom({
                    capacity: data.capacity,
                    classRoomType: data.classRoomType,
                    participantIds: data.participants.map((p) => p.id),
                    speakingLevel: data.speakingLevel,
                    startUtc: data.startUtc.toISOString(),
                    teacherId: data.teacherId,
                    topicId: data.topicId,
                  });

                  toClassRoomDetail(classRoomId);
                } catch (e) {
                  const error = e as ApolloError;
                  showPopup(
                    "Error",
                    `An error occurred during classroom creation: ${error.message ?? "Unknown error"}`,
                  );
                }
              }}
            />
          </Card>
        </div>
      </Layout>
    </>
  );
}
