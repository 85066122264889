import { Layout } from "../../UI/Layout/Layout.tsx";
import { WeeklyCalendar } from "../../Calendar/WeeklyCalendar.tsx";
import { useState } from "react";
import { createTodayInterval } from "../../Calendar/createTodayInterval.ts";
import dayjs from "dayjs";
import { useTeacherIdParamsOrThrow } from "../useTeacherIdParamsOrThrow.ts";
import { useTeacherAvailability } from "./useTeacherAvailability.ts";
import { createTeacherCalendarItemMap } from "./createTeacherCalendarItemMap.tsx";
import { AvailabilitySlotItem } from "./AvailabilitySlotItem.tsx";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid/index.js";
import { useNavigate } from "react-router-dom";
import { AllFranchiseeScreenPath } from "../FranchiseeScreen.tsx";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { useFranchiseeById } from "../useFranchiseeById.ts";
import { FullPageLoading } from "../../UI/Loading/FullPageLoading.tsx";
import { useTeacherClassRooms } from "./useTeacherClassRooms.ts";
import { CalendarItemType } from "./CalendarItemSlot.tsx";
import { ClassRoomCalendarItem } from "./ClassRoomCalendarItem.tsx";

export const TeacherAvailabilityScreenPath = "/teacher/:teacherId/availability";

export function TeacherAvailabilityScreen() {
  const [interval, setInterval] = useState(createTodayInterval());
  const { teacherId } = useTeacherIdParamsOrThrow();
  const { franchisee, loading: loadingTeacher } = useFranchiseeById(teacherId);
  const {
    slots,
    loading: loadingAvailableSlots,
    refetch: refetchSlots,
  } = useTeacherAvailability({
    fromDate: interval.fromDate,
    toDate: interval.toDate,
    teacherId,
  });
  const {
    classRooms,
    loading: loadingTeacherClassRooms,
    refetch: refetchClasses,
  } = useTeacherClassRooms({
    fromDate: interval.fromDate,
    toDate: interval.toDate,
    teacherId,
  });
  const navigate = useNavigate();
  const loading = loadingAvailableSlots || loadingTeacherClassRooms;
  if (loadingTeacher) return <FullPageLoading />;
  if (!franchisee) return null;
  return (
    <Layout maxWClass={"max-w-full"}>
      <div
        onClick={() => navigate(AllFranchiseeScreenPath)}
        className={
          "mb-4 text-sm space-x-2 flex items-center text-slate-500 cursor-pointer"
        }
      >
        <ArrowLongLeftIcon className={"w-5"} />
        <p className={""}>Back to teacher list</p>
      </div>
      <div className={"mb-4"}>
        <ScreenTitleBlock
          title={`Availability for ${franchisee.givenName} ${franchisee.familyName}`}
        />
      </div>
      <WeeklyCalendar
        interval={interval}
        items={createTeacherCalendarItemMap(slots, classRooms)}
        loading={loading}
        onChangeInterval={(i) => setInterval(i)}
        itemRenderer={(item) => {
          return item.type === CalendarItemType.AvailabilitySlot ? (
            <AvailabilitySlotItem
              onSlotDeleted={async () => {
                await refetchSlots();
              }}
              slot={item}
            />
          ) : (
            <ClassRoomCalendarItem
              onClassRoomDeleted={async () => {
                await refetchClasses();
                await refetchSlots();
              }}
              classRoom={item}
            />
          );
        }}
        emptyDayComponent={
          <div>
            <p className={"text-center"}>No availability on this date</p>
          </div>
        }
        canGoToPrevWeek={interval.fromDate.isAfter(dayjs())}
      />
    </Layout>
  );
}
