import { UserForm, UserFormInputs } from "./UserForm.tsx";
import { Layout } from "../UI/Layout/Layout.tsx";
import { useUserById } from "./useUserById.ts";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllUsers } from "./useToAllUsers.ts";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useUpdateUser } from "./useUpdateUser.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
import { useUserIdParamsOrThrow } from "./useUserIdParamsOrThrow.ts";
import { useNavigate } from "react-router-dom";
import { useIsActiveUserForAdmin } from "./useIsActiveUserForAdmin.ts";
import { Card } from "../UI/Card.tsx";

export const EditUserScreenPath = "/users/:userId";
export function EditUserScreen() {
  const { userId } = useUserIdParamsOrThrow();
  const { show, title, description, showPopup } = useMessagePopupController(
    4 * 1000,
  );
  const navigate = useNavigate();
  const { user, loading: loadingUser } = useUserById(userId);
  const { isActive, loading: loadingActiveStatus } =
    useIsActiveUserForAdmin(userId);
  const loading = loadingUser || loadingActiveStatus;
  const { updateUser } = useUpdateUser();

  const { toAllUsers } = useToAllUsers();

  async function saveUser(data: UserFormInputs) {
    try {
      await updateUser({
        speakingLevel: data.speakingLevel,
        userId: userId as string,
        givenName: data.givenName,
        familyName: data.familyName,
        activateAccount: data.activateAccount,
        hideBookProgressCheckSection: data.hideBookProgressCheckSection,
        hideBookSpeakingClassSection: data.hideBookSpeakingClassSection,
      });
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Errore", error?.message ?? "Unknown error");
      return;
    }
    navigate(-1);
  }
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={"Edit user"}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllUsers} title={"Users"} />
                <BreadCrumbsItem title={"Edit user"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            {loading ? (
              <CenteredLoading />
            ) : (
              <UserForm
                disabledMap={{
                  email: true,
                }}
                defaultValues={
                  user ? { ...user, activateAccount: isActive } : undefined
                }
                onSave={saveUser}
              />
            )}
          </Card>
        </div>
      </Layout>
    </>
  );
}
