import { getFragmentData, graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";
import { FranchiseeFragment } from "./FranchiseeFragment.ts";

const GetFranchiseeByIdQuery = graphql(`
  query getFranchiseeById($id: String!) {
    getFranchiseeById(id: $id) {
      ...FranchiseeItem
    }
  }
`);
export function useFranchiseeById(franchiseeId: string) {
  const { data, loading } = useQuery(GetFranchiseeByIdQuery, {
    variables: {
      id: franchiseeId as string,
    },
  });
  return {
    franchisee: getFragmentData(FranchiseeFragment, data?.getFranchiseeById),
    loading,
  };
}
