import { useUserFrozenWeeks } from "./useUserFrozenWeeks.ts";
import { useUserFreezableWeeks } from "./useUserFreezableWeeks.ts";
import { CenteredLoading } from "../../../UI/Loading/CenteredLoading.tsx";
import { SpinnerContainer } from "../../../UI/Loading/SpinnerContainer.tsx";
import { FreezeWeekComponent } from "./FreezeWeekComponent.tsx";
import { ErrorFeedback } from "../../../UI/Feedback/ErrorFeedback.tsx";
import { HorizontalLineDivider } from "../../../UI/HorizontalLineDivider.tsx";
import { FrozenWeekItem } from "./FrozenWeekItem.tsx";
import { InfoFeedback } from "../../../UI/Feedback/InfoFeedback.tsx";

type Props = {
  userId: string;
};
export function UserFrozenWeekComponent({ userId }: Props) {
  const { frozenWeeks, loading: loadingFrozenWeeks } =
    useUserFrozenWeeks(userId);
  const { freezableWeeksResponse, loading: loadingFreezableWeeks } =
    useUserFreezableWeeks(userId);

  const loading = loadingFrozenWeeks || loadingFreezableWeeks;

  if (loading && !freezableWeeksResponse) return <CenteredLoading />;

  if (!freezableWeeksResponse)
    return (
      <ErrorFeedback
        title={"Ops, something went wrong!"}
        message={
          "An error occurred while trying to load user frozen weeks. Please, reload the page and try again."
        }
      />
    );

  return (
    <SpinnerContainer loading={loading}>
      <div className={"space-y-4"}>
        <FreezeWeekComponent
          userId={userId}
          freezableWeeks={freezableWeeksResponse}
        />
        <HorizontalLineDivider />
        {frozenWeeks.length > 0 ? (
          <div className={"space-y-4"}>
            {frozenWeeks.map((w, i) => {
              return <FrozenWeekItem key={i} week={w} userId={userId} />;
            })}
          </div>
        ) : (
          <div className={"flex justify-center"}>
            <InfoFeedback
              title={"No frozen weeks"}
              message={"There are not frozen weeks for this user"}
            />
          </div>
        )}
      </div>
    </SpinnerContainer>
  );
}
