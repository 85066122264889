import dayjs from "dayjs";
import { useRemoveAvailabilitySlot } from "./useRemoveAvailabilitySlot.ts";
import { PrimaryLoadingIndicator } from "../../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../../UI/Loading/LoaderSize.tsx";
import { XMarkIcon } from "@heroicons/react/24/outline/index.js";
import { useWithLoading } from "../../UI/Loading/useWithLoading.ts";
import { AvailabilitySlotCalendarItem } from "./CalendarItemSlot.tsx";

export function AvailabilitySlotItem({
  slot,
  onSlotDeleted,
}: {
  slot: AvailabilitySlotCalendarItem;
  onSlotDeleted: () => Promise<void>;
}) {
  const { removeAvailabilitySlot } = useRemoveAvailabilitySlot();

  const { withLoading, loading } = useWithLoading();
  function formatHour(date: string) {
    return dayjs(date).format("HH:mm");
  }

  async function deleteSlot() {
    if (window.confirm("Are you sure you want to delete this slot?")) {
      await withLoading(async () => {
        await removeAvailabilitySlot(slot.slotId);
        await onSlotDeleted();
      });
    }
  }

  return (
    <div
      className={
        "border-2 border-primary p-2 rounded-lg bg-primary bg-opacity-15 flex justify-between space-x-2"
      }
    >
      <p>
        {formatHour(slot.startDateUtc)} - {formatHour(slot.endDateUtc)}
      </p>
      {loading ? (
        <div className={"flex-shrink-0"}>
          <PrimaryLoadingIndicator size={LoaderSize.Small} />
        </div>
      ) : (
        <XMarkIcon
          onClick={deleteSlot}
          className={"w-5 text-red-600 cursor-pointer flex-shrink-0"}
        />
      )}
    </div>
  );
}
