import { useCallback } from "react";
import { useSpeakingClassAxiosInstance } from "./useSpeakingClassAxiosInstance.ts";
import dayjs from "dayjs";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { AxiosResponse } from "axios";

export function useGenerateAvailabilityCsv() {
  const axiosInstance = useSpeakingClassAxiosInstance();
  const { withLoading, loading } = useWithLoading<AxiosResponse>();
  const generateAvailabilityCsvCall = useCallback(
    async ({ fromIncl, toIncl }: { fromIncl: Date; toIncl: Date }) => {
      const dateFormat = "YYYY-MM-DD";

      return await axiosInstance.post(
        `/availability/generate-availability-csv`,
        {
          fromIncl: dayjs(fromIncl).format(dateFormat),
          toIncl: dayjs(toIncl).format(dateFormat),
          clientTimezone: dayjs.tz.guess(),
        },
        {
          responseType: "blob",
        },
      );
    },
    [axiosInstance],
  );

  const generateAvailabilityCsv = useCallback(
    async ({ fromIncl, toIncl }: { fromIncl: Date; toIncl: Date }) => {
      return await withLoading(() =>
        generateAvailabilityCsvCall({ fromIncl, toIncl }),
      );
    },
    [withLoading, generateAvailabilityCsvCall],
  );

  return {
    generateAvailabilityCsv,
    loading,
  };
}
