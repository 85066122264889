import useSWR from "swr";
import dayjs from "dayjs";
import { useSpeakingClassAxiosInstance } from "../useSpeakingClassAxiosInstance.ts";
import { MissingSlotToBookEntryDTO } from "./missingSlotToBookEntryDTO.ts";

export function useMissingSlotsToBook({
  fromDate,
  toDate,
}: {
  fromDate: Date;
  toDate: Date;
}) {
  const format = "YYYY-MM-DD";
  const instance = useSpeakingClassAxiosInstance();
  const { data, isLoading } = useSWR(
    `/capacity-report/missing-slots-to-book/${fromDate.toISOString()}/${toDate.toISOString()}`,
    async () => {
      const { data } = await instance.get<MissingSlotToBookEntryDTO[]>(
        "/capacity-report/missing-slot-to-book",
        {
          params: {
            timezone: dayjs.tz.guess(),
            startDate: dayjs(fromDate).format(format),
            endDate: dayjs(toDate).format(format),
          },
        },
      );
      return data;
    },
  );

  return {
    reportEntries: data ?? [],
    isLoading,
  };
}
