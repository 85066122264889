import { Select } from "@normasteaching/norma-ui";
import { countryCodes } from "./countryCodes.ts";
import _sortBy from "lodash.sortby";
import { TextInput } from "../TextInput.tsx";
export type PhoneNumberDTO = {
  countryCode: string;
  number: string;
};
type Props = {
  value?: PhoneNumberDTO;
  onChange: (value: PhoneNumberDTO) => void;
  errorMessage?: string;
};
export function PhoneInput({ value, onChange, errorMessage }: Props) {
  function createEmptyValue(): PhoneNumberDTO {
    return {
      number: "",
      countryCode: "",
    };
  }

  return (
    <div className={"space-y-2"}>
      <div className={"flex items-start space-x-2"}>
        <div className={"min-w-[200px]"}>
          <Select<string>
            hasError={!!errorMessage}
            value={value?.countryCode ?? ""}
            options={_sortBy(countryCodes, (c) =>
              parseInt(c.dial_code.replace("+", "")),
            ).map((code) => ({
              id: code.dial_code,
              name: `${code.dial_code} (${code.name})`,
            }))}
            onChange={(v) => {
              if (!v) return;
              onChange({
                ...(value ?? createEmptyValue()),
                countryCode: v,
              });
            }}
          />
        </div>
        <div className={"flex-1"}>
          <TextInput
            hasError={!!errorMessage}
            value={value?.number ?? ""}
            onChange={(v) => {
              onChange({
                ...(value ?? createEmptyValue()),
                number: v.target.value ?? "",
              });
            }}
          />
        </div>
      </div>
      {errorMessage ? <p className={"text-red-500"}>{errorMessage}</p> : null}
    </div>
  );
}
