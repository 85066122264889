import { useAllFranchisees } from "../Franchisee/useAllFranchisees.ts";
import { Select, SpinnerContainer } from "@normasteaching/norma-ui";
import { useAvailableTeachersInHour } from "../Franchisee/useAvailableTeachersInHour.ts";

type Props = {
  teacherId?: string;
  onChange: (value: string | null) => void;
  errorMessage?: string;
  useOnlyAvailableTeacherConfig?: {
    useOnlyAvailableTeachers: boolean;
    startDate?: Date;
  };
};
export function TeacherSelect({
  teacherId,
  onChange,
  errorMessage,
  useOnlyAvailableTeacherConfig,
}: Props) {
  const { franchisees, loading } = useAllFranchisees();
  const { teachers, loading: loadingAvailableTeachers } =
    useAvailableTeachersInHour({
      startDate: useOnlyAvailableTeacherConfig?.startDate,
    });

  const teachersToUse = useOnlyAvailableTeacherConfig?.useOnlyAvailableTeachers
    ? teachers
    : franchisees;

  return (
    <SpinnerContainer loading={loading || loadingAvailableTeachers}>
      <Select<string>
        searchable
        errorMessage={errorMessage}
        value={teacherId}
        placeholder={"Select a teacher"}
        options={
          teachersToUse?.map((t) => {
            return {
              name: `${t.givenName} ${t.familyName} ${t.email}`,
              id: t.id,
            };
          }) ?? []
        }
        onChange={onChange}
      />
    </SpinnerContainer>
  );
}
