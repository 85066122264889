import { UserFragmentFragment } from "../../gql/graphql.ts";
import { Card } from "../../UI/Card.tsx";
import dayjs from "dayjs";
import { FeedbackReportScore } from "./FeedbackReportScore.tsx";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";
import { getRecommendationLabel } from "../ProgressCheck/getRecommendationLabel.tsx";

type Props = {
  user: UserFragmentFragment;
};
export function UserProgressCheckResultComponent({ user }: Props) {
  return user.progressCheckResults.map((r, i) => {
    return (
      <Card key={i}>
        <div className={"space-y-4"}>
          <div className={"flex justify-between space-x-2 items-center"}>
            <h3 className={""}>
              Taken on{" "}
              <b>
                {dayjs(r.progressCheckStartedAtUtc).format(
                  "ddd DD MMMM YYYY [at] HH:ss",
                )}
              </b>{" "}
              - Level <b>{r.progressCheckLevel}</b> - Teacher{" "}
              <b>
                {r.teacher.givenName} {r.teacher.familyName}
              </b>
            </h3>
            {r.passed ? (
              <div
                className={
                  "py-1 px-2 rounded-md bg-green-400 text-sm text-slate-700"
                }
              >
                Passed
              </div>
            ) : (
              <div
                className={"py-1 text-white px-2 rounded-md bg-red-400 text-sm"}
              >
                Not passed
              </div>
            )}
          </div>
          <div className={"space-y-3"}>
            <FeedbackReportScore label={"Accuracy"} value={r.accuracy} />
            <HorizontalLineDivider />

            <FeedbackReportScore
              label={"Comprehension"}
              value={r.comprehension}
            />
            <HorizontalLineDivider />
            <FeedbackReportScore
              label={"Vocabulary Range"}
              value={r.vocabularyRange}
            />
            <HorizontalLineDivider />
            <FeedbackReportScore label={"Fluency"} value={r.fluency} />
            {r.recommendation.length > 0 ? (
              <>
                <HorizontalLineDivider />
                <div className={"space-y-3"}>
                  <h3 className={"font-semibold"}>Recommendations</h3>
                  <ul className={"space-y-3"}>
                    {r.recommendation.map((rec, i) => {
                      return (
                        <li key={i}>
                          <p className={"flex space-x-2"}>
                            <span
                              className={
                                "block h-2 w-2 bg-primary rounded-full relative top-2 flex-shrink-0"
                              }
                            />
                            <span>{getRecommendationLabel(rec)}</span>
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            ) : null}

            {r.notes ? (
              <>
                <HorizontalLineDivider />
                <div className={"space-y-3"}>
                  <h3 className={"font-semibold"}>Notes</h3>
                  <p>{r.notes}</p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Card>
    );
  });
}
