import {
  ClassRoomType,
  SpeakingClassLevel,
  SpeakingLevel,
  UserFragmentFragment,
} from "../../gql/graphql.ts";
import * as yup from "yup";
import { TeacherSelect } from "../TeacherSelect.tsx";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputWithLabel } from "../../UI/InputWithLabel.tsx";
import { DateTimeInput, Select } from "@normasteaching/norma-ui";
import { TopicSelect } from "../TopicSelect.tsx";
import dayjs from "dayjs";
import { UserListInput } from "../UserListInput.tsx";
import { useWithLoading } from "../../UI/Loading/useWithLoading.ts";
import { TextInput } from "../../UI/TextInput.tsx";
import { PrimaryButton } from "../../UI/PrimaryButton.tsx";
import { TextButton } from "../../UI/TextButton.tsx";
import { getMinUsedTopicInLevelAmongParticipants } from "./getMinUsedTopicInLevelAmongParticipants.ts";
import { useApolloClient } from "@apollo/client";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import _shuffle from "lodash.shuffle";
import { Checkbox } from "../../UI/Checkbox.tsx";
import { useState } from "react";
export type CreateClassroomFormInputs = {
  speakingLevel: SpeakingClassLevel;
  teacherId: string;
  startUtc: Date;
  participants: UserFragmentFragment[];
  capacity: number;
  topicId: string;
  classRoomType: ClassRoomType;
};

const schema = yup
  .object({
    speakingLevel: yup
      .mixed<SpeakingClassLevel>()
      .oneOf(Object.values(SpeakingClassLevel))
      .required("Specify classroom level"),
    classRoomType: yup
      .mixed<ClassRoomType>()
      .oneOf(Object.values(ClassRoomType))
      .required("Specify classroom type"),
    teacherId: yup.string().required("Select a teacher"),
    startUtc: yup.date().required("Specify start date"),
    participants: yup
      .array()
      .required()
      .min(1, "Select at least one participant"),
    capacity: yup
      .number()
      .typeError("Specify classroom capacity")
      .required("Specify classroom capacity"),
    topicId: yup.string().required("Select a topic"),
  })
  .required();
export function CreateClassroomForm({
  onCreate,
}: {
  onCreate: (data: CreateClassroomFormInputs) => Promise<void>;
}) {
  function getDefaultStartDate() {
    return dayjs().add(1, "day").startOf("day").hour(9).toDate();
  }

  const [useOnlyAvailableTeachers, setUseOnlyAvailableTeachers] =
    useState(true);

  const apolloClient = useApolloClient();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
    setValue,
    resetField,
  } = useForm<CreateClassroomFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      speakingLevel: SpeakingClassLevel.Starters,
      classRoomType: ClassRoomType.SpeakingClass,
      startUtc: getDefaultStartDate(),
      participants: [],
      capacity: 1,
    },
  });

  const { withLoading, loading } = useWithLoading();
  const { withLoading: withLoadingSuggestTopic, loading: loadingSuggestTopic } =
    useWithLoading();

  const onSubmit: SubmitHandler<CreateClassroomFormInputs> = async (data) => {
    await withLoading(async () => onCreate(data));
  };
  const speakingLevel = watch("speakingLevel");
  const startDate = watch("startUtc");
  const participantIds = watch("participants").map((p) => p.id);
  const endDate = dayjs(startDate).add(1, "hour").toDate();

  const { show, showPopup, description, title } = useMessagePopupController(
    4 * 1000,
  );
  async function suggestTopic() {
    try {
      if (participantIds.length <= 0)
        return showPopup(
          "Can't suggest topic",
          "Select at least one participant",
        );
      const validTopics = await getMinUsedTopicInLevelAmongParticipants({
        apolloClient,
        participantIds,
        speakingLevel,
      });

      if (validTopics.length <= 0)
        return showPopup(
          "Can't suggest topic",
          "We're unable to find a topic that hasn't been used by all participants in this level",
        );

      const randomTopic = _shuffle(validTopics)[0];
      setValue("topicId", randomTopic.id);
    } catch (e) {
      const error = e as Error;
      showPopup(
        "Error",
        `Failed to suggest topic ${error?.message ?? "Unknown error"}`,
      );
    }
  }
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <div className={"flex flex-col space-y-3"}>
        <InputWithLabel label={"ClassRoom Type"}>
          <Controller
            control={control}
            name={"classRoomType"}
            render={({ field }) => {
              return (
                <Select<ClassRoomType>
                  errorMessage={errors.classRoomType?.message}
                  placeholder={"Select a class room type"}
                  options={Object.values(ClassRoomType).map((level) => ({
                    name: level,
                    id: level,
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </InputWithLabel>
        <InputWithLabel label={"Speaking Level"}>
          <Controller
            control={control}
            name={"speakingLevel"}
            render={({ field }) => {
              return (
                <Select<SpeakingClassLevel>
                  errorMessage={errors.speakingLevel?.message}
                  placeholder={"Select a speaking level"}
                  options={Object.values(SpeakingClassLevel).map((level) => ({
                    name: level,
                    id: level,
                  }))}
                  value={field.value}
                  onChange={(v) => {
                    resetField("topicId");
                    field.onChange(v);
                  }}
                />
              );
            }}
          />
        </InputWithLabel>
        <InputWithLabel label={"Start date"}>
          <Controller
            control={control}
            name={"startUtc"}
            render={({ field }) => {
              return (
                <DateTimeInput
                  hourFromTo={{ from: 9, to: 21 }}
                  value={field.value}
                  onChange={(v) => {
                    if (useOnlyAvailableTeachers) resetField("teacherId");
                    field.onChange(v);
                  }}
                />
              );
            }}
          />
        </InputWithLabel>
        <InputWithLabel label={"End date"}>
          <DateTimeInput
            disabled
            value={endDate}
            onChange={() => {
              //Do nothing
            }}
          />
        </InputWithLabel>
        <InputWithLabel label={"Capacity"}>
          <TextInput
            {...register("capacity")}
            errorMessage={errors.capacity?.message}
            type="number"
            onWheel={() => {
              if (!document.activeElement) return;
              if (
                "blur" in document.activeElement &&
                typeof document.activeElement.blur === "function"
              )
                document.activeElement.blur();
            }}
          />
        </InputWithLabel>
        <InputWithLabel
          rightElement={
            <div className={"flex space-x-2"}>
              <p>Use only available teachers</p>
              <Checkbox
                value={useOnlyAvailableTeachers}
                onChange={(v) => {
                  if (v) resetField("teacherId");
                  setUseOnlyAvailableTeachers(v);
                }}
              />
            </div>
          }
          label={"Teacher"}
        >
          <Controller
            control={control}
            name={"teacherId"}
            render={({ field }) => {
              return (
                <TeacherSelect
                  useOnlyAvailableTeacherConfig={{
                    useOnlyAvailableTeachers,
                    startDate,
                  }}
                  errorMessage={errors.teacherId?.message}
                  onChange={field.onChange}
                  teacherId={field.value}
                />
              );
            }}
          />
        </InputWithLabel>
        <InputWithLabel
          label={"Topic"}
          rightElement={
            <div>
              <TextButton
                onClick={async () =>
                  await withLoadingSuggestTopic(suggestTopic)
                }
                loading={loadingSuggestTopic}
                label={"Suggest topic"}
              />
            </div>
          }
        >
          <Controller
            control={control}
            name={"topicId"}
            render={({ field }) => {
              return (
                <TopicSelect
                  errorMessage={errors.topicId?.message}
                  filterByLevel={[speakingLevel]}
                  onChange={field.onChange}
                  topicId={field.value}
                />
              );
            }}
          />
        </InputWithLabel>

        <InputWithLabel label={"Participants"}>
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <UserListInput
                  errorMessage={errors.participants?.message}
                  value={field.value}
                  onChange={field.onChange}
                  withSpeakingLevel={Object.values(SpeakingLevel).filter(
                    (l) => l !== SpeakingLevel.Unknown,
                  )}
                />
              );
            }}
            name={"participants"}
          />
        </InputWithLabel>
        <div className={"flex justify-end"}>
          <div>
            <PrimaryButton
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={"Create"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
