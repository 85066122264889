import axios, { AxiosInstance } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";

export function useSpeakingClassAxiosInstance(): AxiosInstance {
  const { getAccessTokenSilently } = useAuth0();
  const instance = useMemo(() => {
    return axios.create({
      baseURL: import.meta.env.VITE_SPEAKING_CLASS_SERVICE_BASE_URL,
    });
  }, []);

  instance.interceptors.request.use(async (config) => {
    config.headers.set(
      `Authorization`,
      `Bearer ${await getAccessTokenSilently()}`,
    );
    return config;
  });

  return instance;
}
