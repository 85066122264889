import { graphql } from "../../../gql/index.ts";

export const GetFreezableWeeksResponseFragment = graphql(`
  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {
    weeks {
      planId
      week {
        startOfWeekUtc
        endOfWeekUtc
      }
    }
    remainingWeeksToFreeze {
      remainingCount
      planId
    }
  }
`);
