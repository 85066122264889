import { Col } from "../UI/Layout/Col.tsx";
import { Row } from "../UI/Layout/Row.tsx";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { TextInput } from "../UI/TextInput.tsx";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { PhoneInput } from "../UI/Phone/PhoneInput.tsx";

export type FranchiseeFormInputs = {
  email: string;
  givenName: string;
  familyName: string;
  phoneNumber: {
    countryCode: string;
    number: string;
  };
};
const schema = yup
  .object({
    email: yup
      .string()
      .email("Insert a valid email")
      .required("Insert an email"),
    givenName: yup.string().required("Insert a name"),
    familyName: yup.string().required("Insert a surname"),
    phoneNumber: yup.object({
      countryCode: yup.string().required("Select a mobile country code"),
      number: yup
        .string()
        .matches(/\d+/, {
          message: "Insert a valid phone number",
        })
        .required(),
    }),
  })
  .required();

export function FranchiseeForm({
  onSave,
  disabledMap,
  defaultValues,
}: {
  onSave: (data: FranchiseeFormInputs) => Promise<void>;
  disabledMap?: {
    [key in keyof FranchiseeFormInputs]?: boolean;
  };
  defaultValues?: FranchiseeFormInputs;
}) {
  const { withLoading, loading } = useWithLoading();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<FranchiseeFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: {
        number: "",
        countryCode: "+39",
      },
      ...defaultValues,
    },
  });

  const onSubmit: SubmitHandler<FranchiseeFormInputs> = async (data) => {
    await withLoading(async () => onSave(data));
  };

  return (
    <form>
      <Col>
        <Row>
          <InputWithLabel label="Name">
            <TextInput
              disabled={disabledMap?.givenName}
              errorMessage={errors.givenName?.message}
              {...register("givenName")}
            />
          </InputWithLabel>
          <InputWithLabel label="Surname">
            <TextInput
              disabled={disabledMap?.familyName}
              errorMessage={errors.familyName?.message}
              {...register("familyName")}
            />
          </InputWithLabel>
        </Row>
        <InputWithLabel label="Email">
          <TextInput
            disabled={disabledMap?.email}
            errorMessage={errors.email?.message}
            type={"email"}
            {...register("email")}
          />
        </InputWithLabel>
        <InputWithLabel label="Phone">
          <Controller
            control={control}
            render={({ field }) => {
              function getErrorMessage() {
                const parts: string[] = [];
                if (errors.phoneNumber?.number?.message)
                  parts.push(errors.phoneNumber?.number?.message);
                if (errors.phoneNumber?.countryCode?.message)
                  parts.push(errors.phoneNumber?.countryCode?.message);
                return parts.join(" | ");
              }
              return (
                <PhoneInput
                  errorMessage={getErrorMessage()}
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
            name={"phoneNumber"}
          />
        </InputWithLabel>
        <div className={"flex justify-end"}>
          <PrimaryButton
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            label={"Save"}
          />
        </div>
      </Col>
    </form>
  );
}
