import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useState } from "react";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { useImportAvailability } from "./useImportAvailability.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { AxiosError } from "axios";
import { UploadComponent } from "../UI/UploadComponent.tsx";
import { Card } from "../UI/Card.tsx";

export const AvailabilityImportScreenPath = "/availability/import";

export function AvailabilityImportScreen() {
  const [fileToUpload, setFileToUpload] = useState<File[]>();
  const { importAvailability, loading } = useImportAvailability();
  const { show, showPopup, description, title } = useMessagePopupController(
    5 * 1000,
  );
  async function importAva() {
    if (!fileToUpload || !fileToUpload.length) return;
    try {
      await importAvailability(fileToUpload);
      setFileToUpload([]);
    } catch (e) {
      const err = e as AxiosError<{
        message: string;
      }>;
      showPopup("Error", err?.response?.data?.message ?? "Unknown error");
      throw e;
    }
  }
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock title={"Import availability"} />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <UploadComponent
              allowedMimeType={["text/csv"]}
              onChange={(fileList) => setFileToUpload(fileList)}
              text={"Drag here the CSVs"}
              value={fileToUpload}
            />
            <div className={"flex justify-end mt-2"}>
              <PrimaryButton
                loading={loading}
                onClick={importAva}
                label={"Upload"}
              />
            </div>
          </Card>
        </div>
      </Layout>
    </>
  );
}
