import { Interval } from "./Interval.tsx";
import { useCalendar } from "./useCalendar.tsx";
import {
  CalendarDaysIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid/index.js";
import { Fragment, ReactNode } from "react";
import { SpinnerContainer } from "../UI/Loading/SpinnerContainer.tsx";
import { CalendarItemMap } from "./CalendarItemMap.ts";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import { CalendarDayLabel } from "./CalendarDayLabel.tsx";
import { OutlinedButton } from "../UI/OutlinedButton.tsx";

export function DesktopCalendarComponent<T>({
  items,
  itemRenderer,
  onChangeInterval,
  interval,
  loading,
  previousPeriodLabel,
  nextPeriodLabel,
  emptyDayComponent,
  canGoToPreviousPeriod,
  canGoToNextPeriod,
  datePickerComponent,
  disabled,
}: {
  items: CalendarItemMap<T>;
  interval: Interval;
  itemRenderer: (item: T) => ReactNode;
  onChangeInterval: (interval: Interval) => void;
  loading: boolean;
  previousPeriodLabel: string;
  nextPeriodLabel: string;
  emptyDayComponent: ReactNode;
  canGoToPreviousPeriod: boolean;
  canGoToNextPeriod: boolean;
  datePickerComponent: (renderProps: { close: () => void }) => ReactNode;
  disabled?: {
    disabled: boolean;
    disabledComponent: ReactNode;
  };
}) {
  const { calendar, goNextPeriod, goPrevPeriod } = useCalendar({
    interval,
    items,
    onChangeInterval,
  });
  return (
    <div className={"flex-1 flex flex-col"}>
      <div className={"flex items-center justify-between mb-2"}>
        <div className={"shrink-0"}>
          <OutlinedButton
            disabled={!canGoToPreviousPeriod}
            label={previousPeriodLabel}
            onClick={goPrevPeriod}
          />
        </div>

        <DropDown
          activator={
            <h1
              className={
                "font-bold text-2xl flex items-center justify-center space-x-2"
              }
            >
              <span>{calendar.getInterval().formatMonth("en")}</span>
              <ChevronDownIcon className={"w-6"} />
            </h1>
          }
        >
          {({ close }) => (
            <div className={"p-3 min-w-[300px]"}>
              {datePickerComponent({
                close,
              })}
            </div>
          )}
        </DropDown>

        <div className={"shrink-0"}>
          <OutlinedButton
            disabled={!canGoToNextPeriod}
            label={nextPeriodLabel}
            onClick={goNextPeriod}
          />
        </div>
      </div>
      {disabled?.disabled ? (
        disabled.disabledComponent
      ) : (
        <SpinnerContainer loading={loading}>
          <div
            className={
              "grid gap-4 md:grid-cols-[min-content_repeat(7,minmax(200px,1fr))] xl:grid-cols-[min-content_repeat(7,minmax(0,1fr))] p-2 overflow-x-scroll"
            }
          >
            <div className={"inline-flex items-center justify-end p-1"}>
              <CalendarDaysIcon className={"h-5 w-5"} />
            </div>
            {calendar.getAllDays().map((day) => {
              const slotCount = calendar.countSlotOnDate(day);
              return (
                <CalendarDayLabel
                  key={day.valueOf()}
                  day={day}
                  slotCount={slotCount}
                />
              );
            })}
            {calendar.getAllHours().map((h, index) => {
              return (
                <Fragment key={index}>
                  <div className={"inline-flex justify-end white p-2"}>
                    <span className={"text-sm"}>{h.from}:00</span>
                  </div>

                  {calendar.getAllDays().map((day, dayColumnIndex) => {
                    const slot = calendar.getSlotOfDate(day, h);
                    const slotCount = calendar.countSlotOnDate(day);
                    const isFirstHour = calendar.isFirstHour(h);

                    if (slotCount <= 0 && isFirstHour) {
                      return (
                        <div
                          key={day.valueOf() + h.from}
                          className={`flex items-center justify-center row-[2_/_15] border-2 p-2 border-dashed text-slate-400 border-slate-300 rounded-2xl`}
                          style={{
                            gridColumn: dayColumnIndex + 2,
                          }}
                        >
                          {emptyDayComponent}
                        </div>
                      );
                    } else if (slotCount <= 0) {
                      return null;
                    }
                    return (
                      <div
                        key={day.valueOf() + h.from}
                        className={"p-2 rounded-md flex flex-col"}
                      >
                        {slot ? itemRenderer(slot) : <div />}
                      </div>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        </SpinnerContainer>
      )}
    </div>
  );
}
