import { UrlBase64Obj } from "../UrlUtils/UrlBase64Obj.ts";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import _filter from "lodash.filter";
import { isDefined } from "../isDefined.ts";

export function useFilterController<T extends object>() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParamName = "f";
  const filterString = searchParams.get(filterParamName);
  const filter: Partial<T> = filterString
    ? UrlBase64Obj.fromString<T>(filterString).value
    : {};

  const setFilter = useCallback(
    (filter: T) => {
      const newSearchParams = new URLSearchParams(searchParams);

      if (
        _filter(filter, (f) => {
          if (!isDefined(f)) return false;
          return !(typeof f === "string" && f.length <= 0);
        }).length <= 0
      ) {
        newSearchParams.delete(filterParamName);
        setSearchParams(newSearchParams);
        return;
      }

      newSearchParams.set(filterParamName, new UrlBase64Obj(filter).toString());
      setSearchParams(newSearchParams);
    },
    [setSearchParams, searchParams],
  );

  return {
    setFilter,
    filter,
  };
}
