import { getFragmentData, graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";
import { TopicFragment } from "./TopicFragment.ts";

const GetTopicByIdQuery = graphql(`
  query getTopicById($id: String!) {
    getTopicById(id: $id) {
      ...TopicItem
    }
  }
`);
export function useTopicById(topicId: string) {
  const { data, loading } = useQuery(GetTopicByIdQuery, {
    variables: {
      id: topicId as string,
    },
  });

  return {
    topic: getFragmentData(TopicFragment, data?.getTopicById),
    loading,
  };
}
