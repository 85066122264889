import { Layout } from "../../UI/Layout/Layout";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { useUserIdParamsOrThrow } from "../useUserIdParamsOrThrow.ts";
import { useUserById } from "../useUserById.ts";
import { FullPageLoading } from "../../UI/Loading/FullPageLoading.tsx";
import { useToAllUsers } from "../useToAllUsers.ts";
import { BreadCrumbsWrapper } from "../../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../../UI/Layout/BreadCrumbsItem.tsx";
import { InfoFeedback } from "../../UI/Feedback/InfoFeedback.tsx";
import { Card } from "../../UI/Card.tsx";
import { UserLessonFeedbackComponent } from "./UserLessonFeedbackComponent.tsx";
import { UserProgressCheckResultComponent } from "./UserProgressCheckResultComponent.tsx";

export const LessonFeedbackReportScreenPath =
  "/users/:userId/lesson-feedback-report";

export function LessonFeedbackReportScreen() {
  const { userId } = useUserIdParamsOrThrow();
  const { user, loading: loadingUser, refetch } = useUserById(userId);
  const { toAllUsers } = useToAllUsers();

  if (loadingUser) return <FullPageLoading />;
  if (!user) return null; //TODO return not found
  const userFullName =
    user.givenName.length > 0 || user.familyName.length > 0
      ? `${user.givenName} ${user.familyName}`
      : "No name";
  return (
    <Layout>
      <div className={"space-y-4"}>
        <Card>
          <ScreenTitleBlock
            title={`Progress of ${userFullName}`}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllUsers} title={"Users"} />
                <BreadCrumbsItem title={"User progress"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"space-y-4"}>
          <div>
            <h4 className={"font-bold"}>Lesson feedback</h4>
          </div>
          {user.lessonFeedbacks.length > 0 ? (
            <UserLessonFeedbackComponent
              onFeedbackDeleted={() => refetch()}
              user={user}
            />
          ) : (
            <Card>
              <div className={"flex justify-center"}>
                <InfoFeedback
                  title={"No feedback"}
                  message={`${userFullName} has not received any feedback since last progress check yet.`}
                />
              </div>
            </Card>
          )}
        </div>
        <div className={"space-y-4"}>
          <h4 className={"font-bold"}>Progress check results</h4>

          {user.progressCheckResults.length > 0 ? (
            <UserProgressCheckResultComponent user={user} />
          ) : (
            <Card>
              <div className={"w-full"}>
                <InfoFeedback
                  title={"No progress check"}
                  message={`${userFullName} has not taken any progress check`}
                />
              </div>
            </Card>
          )}
        </div>
      </div>
    </Layout>
  );
}
