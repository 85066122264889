import { PropsWithChildren } from "react";
import { NavBar } from "./NavBar.tsx";
import { SideMenu } from "./SideMenu.tsx";
import { classNames } from "../Utils/classNames.ts";

type Props = PropsWithChildren<{ maxWClass?: string }>;
export function Layout({ children, maxWClass }: Props) {
  return (
    <div className={"flex min-h-screen relative"}>
      <div className={"sticky top-0 h-screen"}>
        <SideMenu />
      </div>
      <div className={"flex-1 flex flex-col items-stretch bg-slate-100"}>
        <div className={"mb-4"}>
          <NavBar />
        </div>
        <div
          className={classNames(
            "p-4 mx-auto flex-1 w-full",
            maxWClass ? maxWClass : "max-w-[1024px]",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
