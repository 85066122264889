import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetAllFranchiseesQuery } from "./useAllFranchisees.ts";

const CreateFranchiseeMutation = graphql(`
  mutation createFranchisee(
    $email: String!
    $familyName: String!
    $givenName: String!
    $phoneNumber: PhoneNumberInput!
  ) {
    createFranchisee(
      email: $email
      familyName: $familyName
      givenName: $givenName
      phoneNumber: $phoneNumber
    ) {
      ...FranchiseeItem
    }
  }
`);

export function useCreateFranchisee() {
  const [createFranchiseeMutation, { loading }] = useMutation(
    CreateFranchiseeMutation,
    {
      refetchQueries: [GetAllFranchiseesQuery],
    },
  );

  const createFranchisee = useCallback(
    async (variables: {
      email: string;
      givenName: string;
      familyName: string;
      phoneNumber: {
        countryCode: string;
        number: string;
      };
    }) => {
      await createFranchiseeMutation({
        variables,
      });
    },
    [createFranchiseeMutation],
  );

  return {
    createFranchisee,
    loading,
  };
}
