import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { CreateTopicScreenPath } from "./CreateTopicScreen.tsx";

export function useToCreateTopicScreen() {
  const navigate = useNavigate();

  const toCreateTopic = useCallback(() => {
    navigate(CreateTopicScreenPath);
  }, [navigate]);

  return {
    toCreateTopic,
  };
}
