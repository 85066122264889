import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputWithLabel } from "../../UI/InputWithLabel.tsx";
import { ClassRoomPlanSelect } from "./ClassRoomPlanSelect.tsx";
import { PrimaryButton } from "../../UI/PrimaryButton.tsx";
import { useWithLoading } from "../../UI/Loading/useWithLoading.ts";
import { ClassRoomPlanWarrantyConfigDTO } from "./ClassRoomPlanWarrantyConfigDTO.tsx";
import { ClassRoomPlanWarrantyConfigInput } from "./ClassRoomPlanWarrantyConfigInput.tsx";
import { HorizontalLineDivider } from "../../UI/HorizontalLineDivider.tsx";

export type UpdateUserClassRoomPlanFormInputs = {
  planToRecoverId?: string | null;
  warrantyConfig?: ClassRoomPlanWarrantyConfigDTO;
};

const schema = yup
  .object({
    planToRecoverId: yup.string().optional().nullable(),
    warrantyConfig: yup
      .object()
      .shape({
        enabled: yup.boolean().optional(),
        minAttendedLessons: yup.number().when("enabled", {
          is: (val: boolean) => val,
          then: (yup) => yup.required(),
        }),
      })
      .optional(),
  })
  .required();
export function UpdateUserClassRoomPlanForm({
  userId,
  onSave,
  defaultValues,
  planId,
}: {
  userId: string;
  onSave: (data: UpdateUserClassRoomPlanFormInputs) => Promise<void>;
  defaultValues?: UpdateUserClassRoomPlanFormInputs;
  planId: string;
}) {
  const { withLoading, loading: updating } = useWithLoading();
  const { handleSubmit, watch, control } =
    useForm<UpdateUserClassRoomPlanFormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const planToRecoverId = watch("planToRecoverId");

  const onSubmit: SubmitHandler<UpdateUserClassRoomPlanFormInputs> = async (
    data,
  ) => {
    await withLoading(async () => onSave(data));
  };

  return (
    <>
      <InputWithLabel label={"Plan to recover"}>
        <Controller
          render={({ field }) => (
            <ClassRoomPlanSelect
              selectedPlanId={field.value ?? undefined}
              onChange={field.onChange}
              filterPlans={(plans) =>
                plans.filter((p) => p.id !== planId && !p.planToRecoverId)
              }
              userId={userId}
            />
          )}
          name={"planToRecoverId"}
          control={control}
        />
      </InputWithLabel>
      {planToRecoverId ? null : (
        <div>
          <div className={"flex items-center space-x-4"}>
            <HorizontalLineDivider />
            <p className={"whitespace-nowrap font-bold"}>
              Warranty Configuration
            </p>
            <HorizontalLineDivider />
          </div>
          <Controller
            render={({ field }) => (
              <ClassRoomPlanWarrantyConfigInput
                warrantyConfig={field.value}
                onChange={field.onChange}
              />
            )}
            name={"warrantyConfig"}
            control={control}
          />
        </div>
      )}
      <div className={"flex justify-end"}>
        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          loading={updating}
          label={"Update plan"}
        />
      </div>
    </>
  );
}
