import { useAllTopics } from "../Topic/useAllTopics.ts";
import { Select, SpinnerContainer } from "@normasteaching/norma-ui";

type Props = {
  filterByLevel?: string[];
  topicId?: string;
  onChange: (value: string | null) => void;
  errorMessage?: string;
};
export function TopicSelect({
  topicId,
  onChange,
  filterByLevel,
  errorMessage,
}: Props) {
  const { topics, loading } = useAllTopics();

  function getTopics() {
    if (!topics) return [];
    if (!filterByLevel) return topics;
    return topics.filter((t) => filterByLevel.includes(t.level));
  }

  return (
    <SpinnerContainer loading={loading}>
      <Select<string>
        searchable
        onChange={onChange}
        errorMessage={errorMessage}
        placeholder={"Select a topic"}
        value={topicId}
        options={getTopics().map((t) => {
          return {
            name: t.name,
            id: t.id,
          };
        })}
      />
    </SpinnerContainer>
  );
}
