import { ColumnState } from "ag-grid-enterprise";

export class AgGridColumnStateRepository {
  private getKey(agGridId: string) {
    return `agGridColState-${agGridId}`;
  }
  saveState(agGridId: string, state: ColumnState[] | undefined) {
    if (!state) return;
    localStorage.setItem(this.getKey(agGridId), JSON.stringify(state));
  }

  getState(agGridId: string): ColumnState[] | undefined {
    const state = localStorage.getItem(this.getKey(agGridId));
    if (!state) return undefined;
    return JSON.parse(state);
  }

  clearState(agGridId: string) {
    localStorage.removeItem(this.getKey(agGridId));
  }
}
