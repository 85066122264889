import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SpeakingClassLevel } from "../gql/graphql.ts";

const CreateTopicMutation = graphql(`
  mutation createTopic(
    $nameIt: String!
    $level: SpeakingClassLevel!
    $pdfUrl: String!
  ) {
    createTopic(nameIt: $nameIt, level: $level, pdfUrl: $pdfUrl) {
      id
      name
      level
    }
  }
`);
export function useCreateTopic() {
  const [createTopicMutation, { loading }] = useMutation(CreateTopicMutation);

  const createTopic = useCallback(
    async (variables: {
      nameIt: string;
      level: SpeakingClassLevel;
      pdfUrl: string;
    }) => {
      await createTopicMutation({
        variables,
      });
    },
    [createTopicMutation],
  );

  return {
    loading,
    createTopic,
  };
}
