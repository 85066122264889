import dayjs from "dayjs";
import { SelectOption } from "@normasteaching/norma-ui";
import { MonthKey } from "./MonthKey.tsx";

export function generateMonthOptions() {
  const startDate = dayjs();
  const endDate = dayjs(startDate).add(3, "month");
  const res: SelectOption<string>[] = [];
  for (
    let date = startDate;
    date.isBefore(endDate);
    date = date.add(1, "month")
  ) {
    res.push({
      id: new MonthKey(date.month(), date.year()).toString(),
      name: date.format("MMMM YYYY"),
    });
  }

  return res;
}
