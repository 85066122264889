import { useFranchiseeById } from "./useFranchiseeById.ts";
import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { FranchiseeForm, FranchiseeFormInputs } from "./FranchiseeForm.tsx";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllFranchisees } from "./useToAllFranchisees.ts";
import { useFranchiseeIdParamsOrThrow } from "./useFranchiseeIdParamsOrThrow.ts";

export const EditFranchiseeScreenPath = "/franchisees/:franchiseeId/edit";
export function EditFranchiseeScreen() {
  const { franchiseeId } = useFranchiseeIdParamsOrThrow();
  const { franchisee, loading } = useFranchiseeById(franchiseeId);
  const { toAllFranchisees } = useToAllFranchisees();
  async function update(f: FranchiseeFormInputs): Promise<void> {
    console.log(f);
  }

  return (
    <Layout>
      <ScreenTitleBlock
        breadCrumbs={
          <BreadCrumbsWrapper>
            <BreadCrumbsItem onClick={toAllFranchisees} title={"Teachers"} />
            <BreadCrumbsItem title={"Edit teacher"} />
          </BreadCrumbsWrapper>
        }
        title={`Edit teacher`}
      />
      {loading ? (
        <CenteredLoading />
      ) : (
        <FranchiseeForm
          disabledMap={{
            givenName: true,
            familyName: true,
            email: true,
          }}
          onSave={update}
          defaultValues={{
            givenName: franchisee?.givenName ?? "",
            familyName: franchisee?.familyName ?? "",
            email: franchisee?.email ?? "",
            phoneNumber: {
              countryCode: "",
              number: "",
            },
          }}
        />
      )}
    </Layout>
  );
}
