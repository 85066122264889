import { useParams } from "react-router-dom";

export function useTeacherIdParamsOrThrow() {
  const { teacherId } = useParams<{
    teacherId: string;
  }>();

  if (!teacherId) throw new Error("teacherId is required");
  return {
    teacherId,
  };
}
