import { MouseEvent } from "react";
import { WhiteLoadingIndicator } from "./Loading/WhiteLoadingIndicator.tsx";
import { classNames } from "./Utils/classNames.ts";

type Props = {
  label: string;
  href?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  disabledReason?: string;
};
export function PrimaryButton({
  label,
  href,
  onClick,
  loading,
  disabledReason,
  disabled,
}: Props) {
  return (
    <a
      title={disabledReason}
      href={href}
      onClick={(e) => {
        if (disabled) return;
        if (!href) e.preventDefault();
        onClick?.(e);
      }}
      className={classNames(
        "rounded-lg text-white px-10 py-2 flex items-center space-x-2",
        disabled
          ? "bg-slate-300 cursor-not-allowed"
          : "hover:cursor-pointer hover:brightness-110 bg-primary",
      )}
    >
      <span>{label}</span>
      {loading ? <WhiteLoadingIndicator /> : null}
    </a>
  );
}
