import { getFragmentData, graphql } from "../../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { FrozenWeekFragment } from "./FrozenWeekFragment.ts";

export const GetUserFrozenWeeksQuery = graphql(`
  query getFrozenWeeksByUser($userId: String!) {
    getFrozenWeeksByUser(userId: $userId) {
      ...FrozenWeek
    }
  }
`);

export function useUserFrozenWeeks(userId: string) {
  const { data, loading } = useQuery(GetUserFrozenWeeksQuery, {
    variables: {
      userId,
    },
  });

  return {
    frozenWeeks:
      getFragmentData(FrozenWeekFragment, data?.getFrozenWeeksByUser) ?? [],
    loading,
  };
}
