import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-enterprise";
import { ApolloClient } from "@apollo/client";
import { sendSpeakingClassServiceAgGridQuery } from "../../AgGrid/sendSpeakingClassServiceAgGridQuery.ts";

export class ClassRoomListDataSource implements IServerSideDatasource {
  constructor(private readonly apolloClient: ApolloClient<object>) {}

  async getRows(params: IServerSideGetRowsParams): Promise<void> {
    try {
      const { result, hasNext } = await sendSpeakingClassServiceAgGridQuery({
        endRow: params.request.endRow ?? 0,
        startRow: params.request.startRow ?? 0,
        apolloClient: this.apolloClient,
        queryId: "classrooms",
        sortModel: params.request.sortModel,
        filterModel:
          (params.request.filterModel as Record<string, unknown>) ?? {},
        rowGroupCols: params.request.rowGroupCols ?? [],
        groupKeys: params.request.groupKeys ?? [],
        valueCols: params.request.valueCols ?? [],
      });
      params.success({
        rowData: result,
        rowCount: hasNext ? undefined : result.length,
      });
    } catch (e) {
      console.error(e);
      params.fail();
    }
  }
}
