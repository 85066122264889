import { useQuery } from "@apollo/client";
import { getFragmentData, graphql } from "../gql";
import { FranchiseeFragment } from "./FranchiseeFragment.ts";
import { FranchiseeItemFragment } from "../gql/graphql.ts";

export const GetAllFranchiseesQuery = graphql(`
  query getAllFranchisees {
    getAllFranchisees {
      ...FranchiseeItem
    }
  }
`);
export function useAllFranchisees() {
  const { data, loading } = useQuery(GetAllFranchiseesQuery, {
    fetchPolicy: "cache-and-network",
  });

  return {
    franchisees: (getFragmentData(
      FranchiseeFragment,
      data?.getAllFranchisees,
    ) ?? []) as FranchiseeItemFragment[],
    loading,
  };
}
