import { CapacityReportEntryDTO } from "./CapacityReportEntryDTO.ts";
import { useState } from "react";
import { GeneralAvailabilityChart } from "./GeneralAvailabilityChart.tsx";
import { MissingCapacityReportChart } from "./MissingCapacityReportChart.tsx";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid/index.js";
import { formatWeek } from "./formatWeek.ts";

enum ReportToShowType {
  General = "General",
  Missing = "Missing",
}
type ReportToShow = {
  type: ReportToShowType;
  weekAndYear?: {
    week: number;
    year: number;
  };
};
export function GeneralAvailabilityReportChart({
  entries,
}: {
  entries: CapacityReportEntryDTO[];
}) {
  const [reportToShow, setReportToShow] = useState<ReportToShow>({
    type: ReportToShowType.General,
  });

  function onClickGeneralChart(
    weekAndYear: { week: number; year: number },
    missingCount: number,
    overcapacityCount: number,
  ) {
    console.log(
      `Clicked on week ${weekAndYear.week} ${weekAndYear.year}. Missing: ${missingCount}, Overcapacity: ${overcapacityCount}`,
    );
    if (missingCount > overcapacityCount)
      setReportToShow({ type: ReportToShowType.Missing, weekAndYear });
  }
  return (
    <div className={"flex flex-col w-full space-y-6"}>
      <div className={"space-y-2"}>
        {reportToShow.type === ReportToShowType.Missing ? (
          <div className={""}>
            <a
              className={
                "text-sm underline flex items-center space-x-2 text-slate-500 cursor-pointer"
              }
              onClick={(e) => {
                e.preventDefault();
                setReportToShow({ type: ReportToShowType.General });
              }}
            >
              <ArrowLongLeftIcon className={"w-4"} /> <span>Back</span>
            </a>
          </div>
        ) : null}
        <h3 className={"font-bold"}>
          {reportToShow.type === ReportToShowType.Missing ? (
            <span>
              Missing capacity overview for week{" "}
              {reportToShow.weekAndYear?.week} {reportToShow.weekAndYear?.year}{" "}
              (
              {formatWeek(
                reportToShow.weekAndYear as { week: number; year: number },
              )}
              )
            </span>
          ) : (
            <span>Month report</span>
          )}
        </h3>
      </div>
      {reportToShow.type === ReportToShowType.General ? (
        <GeneralAvailabilityChart
          onClick={onClickGeneralChart}
          entries={entries}
        />
      ) : null}
      {reportToShow.type === ReportToShowType.Missing ? (
        <MissingCapacityReportChart
          entries={entries}
          weekAndYear={
            reportToShow.weekAndYear as { week: number; year: number }
          }
        />
      ) : null}
    </div>
  );
}
