import { Layout } from "../UI/Layout/Layout.tsx";
import { UserForm, UserFormInputs } from "./UserForm.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { BreadCrumbsWrapper } from "../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllUsers } from "./useToAllUsers.ts";
import { useCreateUser } from "./useCreateUser.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useEnrollUserToAllPaidContent } from "./useEnrollUserToAllPaidContent.ts";
import { Card } from "../UI/Card.tsx";

export const CreateUserScreenPath = "/users/new";
export function CreateUserScreen() {
  const { toAllUsers } = useToAllUsers();
  const { createUser } = useCreateUser();
  const { show, title, description, showPopup } = useMessagePopupController(
    4 * 1000,
  );
  const { enrollUserToAllContent } = useEnrollUserToAllPaidContent();
  const navigate = useNavigate();
  async function newUser(data: UserFormInputs) {
    try {
      const createdUser = await createUser({
        givenName: data.givenName ?? "",
        familyName: data.familyName ?? "",
        email: data.email,
        speakingLevel: data.speakingLevel,
        activateAccount: data.activateAccount,
        hideBookSpeakingClassSection: data.hideBookSpeakingClassSection,
        hideBookProgressCheckSection: data.hideBookProgressCheckSection,
      });
      if (data.shouldEnrollToAllPaidContent)
        await enrollUserToAllContent(createdUser.id);
    } catch (e) {
      const error = e as ApolloError;
      showPopup("Error", error?.message ?? "Unknown error");
      return;
    }
    navigate(-1);
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock
            title={"New user"}
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllUsers} title={"Users"} />
                <BreadCrumbsItem title={"New user"} />
              </BreadCrumbsWrapper>
            }
          />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <UserForm showShouldEnrollToAllPaidContent onSave={newUser} />
          </Card>
        </div>
      </Layout>
    </>
  );
}
