import { classNames } from "./Utils/classNames.ts";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};
export function Checkbox({ value, onChange }: Props) {
  return (
    <div
      onClick={() => onChange(!value)}
      className={classNames(
        "border p-0.5 flex items-center justify-center border-primary h-6 w-6 rounded-md cursor-pointer",
      )}
    >
      {value ? <div className={"bg-primary w-full h-full rounded-md"} /> : null}
    </div>
  );
}
