import { graphql } from "../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const EditTopicMutation = graphql(`
  mutation editTopic($id: String!, $nameIt: String, $pdfUrl: String) {
    editTopic(id: $id, nameIt: $nameIt, pdfUrl: $pdfUrl) {
      ...TopicItem
    }
  }
`);
export function useEditTopic() {
  const [editTopicMutation, { loading }] = useMutation(EditTopicMutation);

  const editTopic = useCallback(
    async (variables: { id: string; nameIt?: string; pdfUrl?: string }) => {
      await editTopicMutation({
        variables,
      });
    },
    [editTopicMutation],
  );

  return {
    editTopic,
    loading,
  };
}
