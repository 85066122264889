import { Layout } from "../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import { useState } from "react";
import dayjs from "dayjs";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { DateInput } from "../UI/Date/DateInput.tsx";
import { useGenerateAvailabilityCsv } from "./useGenerateAvailabilityCsv.ts";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { Card } from "../UI/Card.tsx";

export const GenerateAvailabilityCsvScreenPath =
  "/availability/generate-availability-csv";

function safelyParseJson<T>(json: string): {
  parsed: boolean;
  value?: T;
} {
  try {
    return {
      parsed: true,
      value: JSON.parse(json),
    };
  } catch (e) {
    console.error(e);
    return {
      parsed: false,
    };
  }
}

export function GenerateAvailabilityCsvScreen() {
  const [fromDateIncl, setFromDateIncl] = useState(new Date());
  const [toDateIncl, setToDateIncl] = useState(new Date());
  const { generateAvailabilityCsv, loading } = useGenerateAvailabilityCsv();
  const { show, description, title, showPopup } = useMessagePopupController(
    5 * 1000,
  );
  function onChangeStartDate(date: Date) {
    if (dayjs(toDateIncl).isBefore(date)) {
      setFromDateIncl(toDateIncl);
      setToDateIncl(date);
    } else {
      setFromDateIncl(date);
    }
  }

  function onChangeEndDate(date: Date) {
    if (dayjs(fromDateIncl).isAfter(date)) {
      setToDateIncl(fromDateIncl);
      setFromDateIncl(date);
    } else {
      setToDateIncl(date);
    }
  }

  function extractFileNameOrThrow(contentDisposition: string): string {
    if (!contentDisposition) throw new Error(`Missing content disposition`);
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (!fileNameMatch || fileNameMatch.length !== 2)
      throw new Error(`Invalid content disposition: ${contentDisposition}`);
    return fileNameMatch[1];
  }
  function downloadFile(response: AxiosResponse): void {
    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    const fileName = extractFileNameOrThrow(contentDisposition);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
  async function generateCsv() {
    try {
      const response = await generateAvailabilityCsv({
        fromIncl: fromDateIncl,
        toIncl: toDateIncl,
      });
      downloadFile(response);
    } catch (e) {
      const err = e as AxiosError<Blob>;
      const parsedJson = safelyParseJson<{
        message: string;
      }>((await err?.response?.data?.text()) ?? "");

      showPopup(
        "Error",
        parsedJson.parsed
          ? (parsedJson.value?.message as string)
          : "Unknown error",
      );
      throw e;
    }
  }

  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <Card>
          <ScreenTitleBlock title={"Generate CSV for availability request"} />
        </Card>
        <div className={"mt-4"}>
          <Card>
            <div className={"flex items-center space-x-2 w-full"}>
              <div className={"flex-1 space-y-1"}>
                <p className={"font-semibold"}>From date (included)</p>
                <DateInput value={fromDateIncl} onChange={onChangeStartDate} />
              </div>
              <div className={"flex-1 space-y-1"}>
                <p className={"font-semibold"}>To date (included)</p>
                <DateInput value={toDateIncl} onChange={onChangeEndDate} />
              </div>
            </div>
            <div className={"flex justify-end mt-2"}>
              <PrimaryButton
                loading={loading}
                onClick={generateCsv}
                label={"Generate CSV"}
              />
            </div>
          </Card>
        </div>
      </Layout>
    </>
  );
}
