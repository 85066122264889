import { CalendarItemMap } from "./CalendarItemMap.ts";
import { Interval } from "./Interval.tsx";
import { ReactNode, useEffect, useRef } from "react";
import { useCalendar } from "./useCalendar.tsx";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import { ChevronDownIcon } from "@heroicons/react/24/solid/index.js";
import { SpinnerContainer } from "../UI/Loading/SpinnerContainer.tsx";
import { CalendarDayLabel } from "./CalendarDayLabel.tsx";
import { Dayjs } from "dayjs";

function MobileCalendarDay<T>({
  slots,
  day,
  itemRenderer,
  emptyDayComponent,
  scrollToDay,
}: {
  slots: T[];
  day: Dayjs;
  itemRenderer: (item: T) => ReactNode;
  emptyDayComponent: ReactNode;
  scrollToDay?: Dayjs;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const alreadyScrolledRef = useRef(false);
  useEffect(() => {
    if (alreadyScrolledRef.current) return;
    if (!scrollToDay) return;
    if (scrollToDay.isSame(day, "day") && ref.current) {
      const y = ref.current.getBoundingClientRect().top - 80;
      window.scrollTo({ top: y, behavior: "smooth" });
      alreadyScrolledRef.current = true;
    }
  }, [scrollToDay, day]);
  return (
    <div ref={ref} className={"flex flex-col space-y-2"}>
      <CalendarDayLabel slotCount={slots.length} day={day} />
      {slots.length > 0 ? (
        <div className={"flex flex-col space-y-2"}>
          {slots.map((s, i) => (
            <div key={i}>{itemRenderer(s)}</div>
          ))}
        </div>
      ) : (
        <div
          className={
            "flex items-center justify-center border-2 px-3 py-6 border-dashed border-slate-300 text-slate-400 rounded-2xl"
          }
        >
          {emptyDayComponent}
        </div>
      )}
    </div>
  );
}

export function MobileCalendarComponent<T>({
  items,
  itemRenderer,
  onChangeInterval,
  interval,
  loading,
  emptyDayComponent,
  datePickerComponent,
  scrollToDay,
  disabled,
}: {
  items: CalendarItemMap<T>;
  interval: Interval;
  itemRenderer: (item: T) => ReactNode;
  onChangeInterval: (interval: Interval) => void;
  loading: boolean;
  emptyDayComponent: ReactNode;
  datePickerComponent: (renderProps: { close: () => void }) => ReactNode;
  scrollToDay?: Dayjs;
  disabled?: {
    disabled: boolean;
    disabledComponent: ReactNode;
  };
}) {
  const { calendar } = useCalendar({
    interval,
    items,
    onChangeInterval,
  });

  return (
    <div className={"flex flex-col flex-1"}>
      <DropDown
        minWidthFull
        activator={
          <h1
            className={
              "font-bold text-2xl flex items-center justify-center space-x-2"
            }
          >
            <span>{calendar.getInterval().formatMonth("it")}</span>
            <ChevronDownIcon className={"w-6"} />
          </h1>
        }
      >
        {({ close }) => (
          <div className={"p-3 md:min-w-[300px]"}>
            {datePickerComponent({
              close,
            })}
          </div>
        )}
      </DropDown>
      {disabled?.disabled ? (
        disabled?.disabledComponent
      ) : (
        <SpinnerContainer loading={loading}>
          <div className={"flex flex-col space-y-4 mt-3"}>
            {calendar.getAllDays().map((day) => {
              const slots = calendar.getAllSlotOfADate(day);

              return (
                <MobileCalendarDay
                  key={day.valueOf()}
                  slots={slots}
                  day={day}
                  emptyDayComponent={emptyDayComponent}
                  itemRenderer={itemRenderer}
                  scrollToDay={scrollToDay}
                />
              );
            })}
          </div>
        </SpinnerContainer>
      )}
    </div>
  );
}
