import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { CreateUserScreenPath } from "./CreateUserScreen.tsx";

export function useToCreateUserScreen() {
  const navigate = useNavigate();

  const toCreateUser = useCallback(() => {
    navigate(CreateUserScreenPath);
  }, [navigate]);

  return {
    toCreateUser,
  };
}
