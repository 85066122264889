import { graphql } from "../gql/index.ts";
import { useQuery } from "@apollo/client";

const IsActiveUserForAdminQuery = graphql(`
  query isActiveUserForAdminById($userId: String!) {
    isActiveUserForAdminById(userId: $userId)
  }
`);
export function useIsActiveUserForAdmin(userId: string) {
  const { data, loading } = useQuery(IsActiveUserForAdminQuery, {
    variables: { userId },
    fetchPolicy: "cache-and-network",
  });

  return {
    isActive: data?.isActiveUserForAdminById ?? false,
    loading,
  };
}
