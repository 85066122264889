import { useMissingSlotsToBook } from "./useMissingSlotsToBook.ts";
import { generateMonthOptions } from "./generateMonthOptions.tsx";
import { useState } from "react";
import { MonthKey } from "./MonthKey.tsx";
import dayjs from "dayjs";
import { SpinnerContainer } from "../../UI/Loading/SpinnerContainer.tsx";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MissingSlotToBookEntryDTO } from "./missingSlotToBookEntryDTO.ts";
import { Select } from "@normasteaching/norma-ui";
import { formatWeek } from "./formatWeek.ts";
import { labelToWeek } from "./labelToWeek.tsx";
type ChartDataDTO = {
  date: string;
  total: number;
  missingToBook: number;
};
export function MissingSlotsToBookGraph() {
  const options = generateMonthOptions();
  const [selectedMonth, setSelectedMonth] = useState<string>(options[0].id);
  const monthKey = MonthKey.fromString(selectedMonth);
  const fromDate = dayjs()
    .month(monthKey.month)
    .year(monthKey.year)
    .startOf("month")
    .toDate();

  const toDate = dayjs()
    .month(monthKey.month)
    .year(monthKey.year)
    .endOf("month")
    .toDate();
  const { reportEntries, isLoading } = useMissingSlotsToBook({
    fromDate,
    toDate,
  });

  function convertToChartData(
    entries: MissingSlotToBookEntryDTO[],
  ): ChartDataDTO[] {
    return entries.map((entry) => {
      const d = dayjs(entry.startOfWeek);
      return {
        date: `Week ${d.week()} ${d.year()}`,
        total: entry.total - entry.missingToBook,
        missingToBook: entry.missingToBook,
      };
    });
  }

  return (
    <div className={"space-y-6"}>
      <div>
        <Select
          options={options}
          value={selectedMonth}
          onChange={(v) => {
            setSelectedMonth(v ?? options[0].id);
          }}
        />
      </div>
      <h3 className={"font-bold"}>Missing hours to book</h3>
      <SpinnerContainer loading={isLoading}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={convertToChartData(reportEntries)}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Bar stackId={"s1"} fill={"#88ff73"} dataKey="total" />
            <Bar stackId={"s1"} fill={"#ff6363"} dataKey="missingToBook" />
            <Tooltip
              labelFormatter={(v) => {
                const { week, year } = labelToWeek(v);
                return formatWeek({ week, year });
              }}
              formatter={(v, name) => [
                v,
                name === "missingToBook" ? "To be booked" : "Already booked",
              ]}
            />
          </BarChart>
        </ResponsiveContainer>
      </SpinnerContainer>
    </div>
  );
}
