import { useParams } from "react-router-dom";

export function useTopicIdParamsOrThrow() {
  const { topicId } = useParams<{
    topicId: string;
  }>();
  if (!topicId) throw new Error("topicId is required");

  return {
    topicId,
  };
}
