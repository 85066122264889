import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function useToEditFranchisee() {
  const navigate = useNavigate();
  const toEditFranchisee = useCallback(
    (franchiseeId: string) => {
      navigate(`/franchisees/${franchiseeId}/edit`);
    },
    [navigate],
  );

  return {
    toEditFranchisee,
  };
}
