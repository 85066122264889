import dayjs, { Dayjs } from "dayjs";

export class Interval {
  constructor(
    public readonly fromDate: Dayjs,
    public readonly toDate: Dayjs,
  ) {}

  getDays(): Dayjs[] {
    const days = [];
    let current = this.fromDate;
    while (current.isBefore(this.toDate)) {
      days.push(current);
      current = current.add(1, "day");
    }
    return days;
  }

  formatMonth(): string {
    if (this.fromDate.isSame(this.toDate, "month")) {
      return this.fromDate.format("MMMM YYYY");
    }

    return `${this.fromDate.format("MMMM YYYY")} - ${this.toDate.format("MMMM YYYY")}`;
  }

  nextPeriod(): Interval {
    const diffInDays = this.diffInDays();
    return new Interval(
      this.fromDate.add(diffInDays, "day"),
      this.toDate.add(diffInDays, "day"),
    );
  }
  prevPeriod(): Interval {
    const diffInDays = this.diffInDays();
    return new Interval(
      this.fromDate.subtract(diffInDays, "day"),
      this.toDate.subtract(diffInDays, "day"),
    );
  }

  private diffInDays(): number {
    return Math.round(this.toDate.diff(this.fromDate, "days", true));
  }

  static monthIntervalFromDate(date: Dayjs): Interval {
    return new Interval(date.startOf("month"), date.endOf("month"));
  }

  add(val: number, unit: "month"): Interval {
    return new Interval(
      this.fromDate.add(val, unit),
      this.toDate.add(val, unit),
    );
  }

  static now(): Interval {
    const now = dayjs();
    return new Interval(now, now);
  }

  static isValid(fromDate: string, toDate: string) {
    return dayjs(toDate).isSameOrAfter(fromDate);
  }
}
