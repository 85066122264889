import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ClassRoomDetailScreenPath } from "./ClassRoomDetailScreen.tsx";

export function useToClassRoomDetail() {
  const navigate = useNavigate();
  const toClassRoomDetail = useCallback(
    (id: string) => {
      navigate(ClassRoomDetailScreenPath.replace(":classRoomId", id));
    },
    [navigate],
  );

  return {
    toClassRoomDetail,
  };
}
