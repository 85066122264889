import { useAuth0 } from "@auth0/auth0-react";
import { AuthCtx } from "./AuthCtx.ts";
import { PropsWithChildren, useCallback } from "react";
import { CenteredLoading } from "../UI/Loading/CenteredLoading.tsx";

type Props = PropsWithChildren;
export function AuthCtxProvider({ children }: Props) {
  const { user, isLoading, logout } = useAuth0();

  const signOut = useCallback(async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  if (isLoading)
    return (
      <div
        className={
          "flex items-center justify-center p-4 min-h-screen ming-w-screen"
        }
      >
        <CenteredLoading />
      </div>
    );

  return (
    <AuthCtx.Provider
      value={{
        user,
        signOut,
      }}
    >
      {children ?? null}
    </AuthCtx.Provider>
  );
}
