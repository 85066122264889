import { forwardRef, useRef, useState } from "react";
import { TextInput, TextInputProps } from "./TextInput.tsx";

type Props = Omit<TextInputProps, "onChange" | "value"> & {
  debounceInMs: number;
  onChange: (value: string) => void;
  initialValue?: string;
};

const DebouncedTextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { debounceInMs, initialValue, onChange, ...textInputProps } = props;

  const [value, setValue] = useState(initialValue ?? "");

  const timeoutRef = useRef<NodeJS.Timeout>();

  function debounceOnChange(value: string) {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onChange(value);
      timeoutRef.current = undefined;
    }, debounceInMs);
  }

  return (
    <TextInput
      {...textInputProps}
      ref={ref}
      onChange={(v) => {
        setValue(v.target.value);
        debounceOnChange(v.target.value);
      }}
      value={value}
    />
  );
});

export { DebouncedTextInput };
