import {
  CapacityReportEntryDTO,
  CapacityReportEntryStatus,
  MissingCapacityReportEntryDTO,
} from "./CapacityReportEntryDTO.ts";
import { ClassRoomType } from "../../gql/graphql.ts";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type EntryDTO = {
  count: number;
  name: string;
};

function extractEntries(
  entries: CapacityReportEntryDTO[],
  weekAndYear: { week: number; year: number },
): EntryDTO[] {
  return entries
    .filter(
      (e) =>
        e.week === weekAndYear.week &&
        e.year === weekAndYear.year &&
        e.status === CapacityReportEntryStatus.MissingCapacity,
    )
    .map((e) => {
      const entry = e as CapacityReportEntryDTO & MissingCapacityReportEntryDTO;
      return {
        count: entry.count,
        name:
          entry.classroomType === ClassRoomType.SpeakingClass
            ? `${entry.topic.name["it"]} - ${entry.topic.level.substring(0, 3)}`
            : `PC - ${entry.topic.level}`,
      };
    });
}
export function MissingCapacityReportChart({
  entries,
  weekAndYear,
}: {
  entries: CapacityReportEntryDTO[];
  weekAndYear: { week: number; year: number };
}) {
  const data = extractEntries(entries, weekAndYear);
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={(v) => [v, "Missing Seats"]} />
        <Bar dataKey="count" fill={"#ff6363"}>
          <LabelList dataKey="count" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
