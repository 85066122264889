import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UserClassRoomPlanWarrantyConfigInputDto } from "../../gql/graphql.ts";

const UpdateUserClassRoomPlanMutation = graphql(`
  mutation setUserClassRoomPlanAsRecoveryOf(
    $planToRecoverId: String
    $planId: String!
    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto
  ) {
    updateUserClassRoomPlan(
      planToRecoverId: $planToRecoverId
      planId: $planId
      warrantyConfig: $warrantyConfig
    ) {
      ...ClassRoomPlanFragment
    }
  }
`);
export function useUpdateUserClassRoomPlan() {
  const [setUserClassRoomPlanAsRecoveryOfMutation, { loading }] = useMutation(
    UpdateUserClassRoomPlanMutation,
  );

  const updateUserClassRoomPlan = useCallback(
    async (variables: {
      planToRecoverId?: string;
      planId: string;
      warrantyConfig?: UserClassRoomPlanWarrantyConfigInputDto;
    }) => {
      await setUserClassRoomPlanAsRecoveryOfMutation({
        variables,
      });
    },
    [setUserClassRoomPlanAsRecoveryOfMutation],
  );

  return {
    updateUserClassRoomPlan,
    loading,
  };
}
