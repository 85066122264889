import { ClassRoomAgGrid } from "./AgGrid/ClassRoomAgGrid.tsx";
import { Layout } from "../UI/Layout/Layout.tsx";
import dayjs from "dayjs";
import { useDeleteClassRoom } from "./useDeleteClassRoom.ts";
import { useToClassRoomDetail } from "./useToClassRoomDetail.ts";
import { ClassRoomAgGridDTO } from "./AgGrid/ClassRoomAgGridDTO.ts";
import { useCallback } from "react";
import { OutlinedButton } from "../UI/OutlinedButton.tsx";
import { useNavigate } from "react-router-dom";
import { CreateClassroomScreenPath } from "./CreateClassroom/CreateClassroomScreen.tsx";

export const AgGridClassRoomListPath = "/classrooms";
export function AgGridClassRoomListScreen() {
  const { deleteClassRoom } = useDeleteClassRoom();
  const { toClassRoomDetail } = useToClassRoomDetail();
  const navigate = useNavigate();
  const deleteClass = useCallback(
    async (classRoom: ClassRoomAgGridDTO) => {
      if (dayjs(classRoom.startUtc).isBefore(dayjs()))
        return window.alert(
          `You can't delete a class room that has already started`,
        );
      if (
        window.confirm(
          `Are you sure you want to delete this class room? Both the students and teachers will be notified`,
        )
      ) {
        await deleteClassRoom(classRoom.id);
      }
    },
    [deleteClassRoom],
  );
  return (
    <Layout maxWClass={"max-w-full"}>
      <ClassRoomAgGrid
        deleteClassRoom={deleteClass}
        toClassRoomDetail={toClassRoomDetail}
        rightButtons={[
          <OutlinedButton
            onClick={() => navigate(CreateClassroomScreenPath)}
            key={"new-class"}
            label={"New class"}
          />,
        ]}
      />
    </Layout>
  );
}
