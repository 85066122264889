import {
  AvailabilitySlotDto,
  ClassRoomWithoutParticipantDto,
} from "../../gql/graphql.ts";
import { CalendarItemMap } from "../../Calendar/CalendarItemMap.ts";
import { SlotDayAndHourKey } from "../../Calendar/SlotDayAndHourKey.ts";
import dayjs from "dayjs";
import { CalendarItemSlot, CalendarItemType } from "./CalendarItemSlot.tsx";

export function createTeacherCalendarItemMap(
  slots: AvailabilitySlotDto[],
  classRooms: ClassRoomWithoutParticipantDto[],
): CalendarItemMap<CalendarItemSlot> {
  const map = new CalendarItemMap<CalendarItemSlot>();
  slots.forEach((s) => {
    const key = SlotDayAndHourKey.fromDate(
      dayjs(s.startDateUtc).tz(s.slotTimezone),
    );
    const cItem: CalendarItemSlot = {
      endDateUtc: s.endDateUtc,
      slotId: s.slotId,
      startDateUtc: s.startDateUtc,
      type: CalendarItemType.AvailabilitySlot,
      slotTimezone: s.slotTimezone,
    };
    map.set(key, cItem);
  });

  classRooms.forEach((c) => {
    const key = SlotDayAndHourKey.fromDate(
      dayjs(c.startedAtUtc).tz(c.classRoomTimezone),
    );
    const cItem: CalendarItemSlot = {
      classRoomId: c.id,
      classTimezone: c.classRoomTimezone,
      endDateUtc: c.endedAtUtc,
      startDateUtc: c.startedAtUtc,
      type: CalendarItemType.ClassCalendarItem,
      topic: c.topic,
      speakingLevel: c.speakingLevel,
      classType: c.type,
    };
    map.set(key, cItem);
  });
  return map;
}
