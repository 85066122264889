import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { AllUsersScreenPath } from "./AllUserScreen.tsx";

export function useToAllUsers() {
  const navigate = useNavigate();

  const toAllUsers = useCallback(() => {
    navigate(AllUsersScreenPath);
  }, [navigate]);

  return {
    toAllUsers,
  };
}
