export class MonthKey {
  constructor(
    public readonly month: number,
    public readonly year: number,
  ) {}

  public toString(): string {
    return `${this.month}|${this.year}`;
  }

  public static fromString(str: string): MonthKey {
    const [month, year] = str.split("|");
    return new MonthKey(parseInt(month), parseInt(year));
  }
}
