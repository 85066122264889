import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { useCallback } from "react";
import { useSpeakingClassAxiosInstance } from "./useSpeakingClassAxiosInstance.ts";

export function useImportAvailability() {
  const { withLoading, loading } = useWithLoading();
  const axiosInstance = useSpeakingClassAxiosInstance();
  const importAvailabilityCall = useCallback(
    async (file: File[]): Promise<void> => {
      const formData = new FormData();
      file.forEach((f) => {
        formData.append("files", f);
      });
      await axiosInstance.post(`/availability/import-availability`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance],
  );

  const importAvailability = useCallback(
    async (file: File[]) => {
      await withLoading(() => importAvailabilityCall(file));
    },
    [withLoading, importAvailabilityCall],
  );

  return {
    importAvailability,
    loading,
  };
}
