import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { TopicFragment } from "./TopicFragment.ts";

export const GetAllTopicsQuery = graphql(`
  query getAllTopics {
    getAllTopics {
      ...TopicItem
    }
  }
`);
export function useAllTopics() {
  const { data, loading } = useQuery(GetAllTopicsQuery, {
    fetchPolicy: "cache-and-network",
  });

  return {
    topics: getFragmentData(TopicFragment, data?.getAllTopics ?? []),
    loading,
  };
}
