import {
  CapacityReportEntryDTO,
  CapacityReportEntryStatus,
} from "./CapacityReportEntryDTO.ts";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatWeek } from "./formatWeek.ts";
import { labelToWeek } from "./labelToWeek.tsx";
import _sortBy from "lodash.sortby";
import dayjs from "dayjs";

type EntryDTO = {
  date: string;
  missingCount: number;
  overCapacityCount: number;
};

function extractGeneralEntry(entries: CapacityReportEntryDTO[]): EntryDTO[] {
  const d = entries.reduce((acc, entry) => {
    const key = `w:${entry.week}|y:${entry.year}`;
    const v = acc.get(key) || {
      date: `Week ${entry.week} ${entry.year}`,
      missingCount: 0,
      overCapacityCount: 0,
    };
    if (entry.status === CapacityReportEntryStatus.MissingCapacity) {
      v.missingCount += entry.count;
    } else {
      v.overCapacityCount += entry.count;
    }

    acc.set(key, v);

    return acc;
  }, new Map<string, EntryDTO>());
  const finalData: EntryDTO[] = [];
  for (const [, v] of d.entries()) {
    if (v.missingCount > 0) {
      finalData.push({
        ...v,
        overCapacityCount: 0,
      });
    } else {
      finalData.push(v);
    }
  }
  return _sortBy(finalData, (d) => {
    const { week, year } = labelToWeek(d.date);
    const startDateUtc = dayjs().week(week).year(year).startOf("week");
    return startDateUtc.valueOf();
  });
}

export function GeneralAvailabilityChart({
  entries,
  onClick,
}: {
  entries: CapacityReportEntryDTO[];
  onClick: (
    weekAndYear: { week: number; year: number },
    missingCount: number,
    overcapacityCount: number,
  ) => void;
}) {
  const finalData = extractGeneralEntry(entries);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        onClick={(it) => {
          const missingCapacity =
            it.activePayload?.find((p) => p.dataKey === "missingCount")
              ?.value ?? 0;
          const overCapacity =
            it.activePayload?.find((p) => p.dataKey === "overCapacityCount")
              ?.value ?? 0;
          const { week, year } = labelToWeek(it.activeLabel ?? "");
          onClick({ week, year }, missingCapacity, overCapacity);
        }}
        data={finalData}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip
          labelFormatter={(v) => {
            const { week, year } = labelToWeek(v);
            return formatWeek({ week, year });
          }}
          formatter={(v, name) => [
            v,
            name === "missingCount" ? "Missing Seats" : "Extra Slots",
          ]}
        />
        <Bar dataKey="missingCount" fill={"#ff6363"}>
          <LabelList dataKey="missingCount" position="top" />
        </Bar>
        <Bar dataKey="overCapacityCount" fill={"#88ff73"}>
          <LabelList dataKey="overCapacityCount" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
