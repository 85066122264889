import { useParams } from "react-router-dom";

export function usePlanIdParamsOrThrow() {
  const { planId } = useParams<{
    planId: string;
  }>();
  if (!planId) throw new Error(`planId is required`);
  return {
    planId,
  };
}
