import { DropDown } from "../DropDown/DropDown.tsx";
import dayjs from "dayjs";
import { CalendarDatePicker } from "./CalendarDatePicker.tsx";

export function DateInput({
  value,
  onChange,
}: {
  value: Date;
  onChange: (date: Date) => void;
}) {
  return (
    <DropDown
      activator={
        <div className={"w-full"}>
          <p
            className={
              "border p-2 rounded-lg w-full min-h-[42px] border-slate-200 bg-slate-100 text-left"
            }
          >
            {dayjs(value).format("DD/MM/YYYY")}
          </p>
        </div>
      }
    >
      <CalendarDatePicker selectedDate={value} onChange={onChange} />
    </DropDown>
  );
}
