import { UserFragmentFragment } from "../../gql/graphql.ts";
import { Card } from "../../UI/Card.tsx";
import { FeedbackReportScore } from "./FeedbackReportScore.tsx";
import dayjs from "dayjs";
import { TrashIcon } from "@heroicons/react/24/outline/index.js";
import { useDeleteLessonFeedback } from "./useDeleteLessonFeedback.ts";
import { PrimaryLoadingIndicator } from "../../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../../UI/Loading/LoaderSize.tsx";

type Props = {
  user: UserFragmentFragment;
  onFeedbackDeleted: () => void;
};
export function UserLessonFeedbackComponent({
  user,
  onFeedbackDeleted,
}: Props) {
  const { deleteLessonFeedback, loading } = useDeleteLessonFeedback();
  function formatDate(dateString: string): string {
    const format = "ddd DD MMM YYYY [at] HH:mm";
    return dayjs(dateString).format(format);
  }

  async function deleteFeedback(feedbackId: string) {
    if (
      window.confirm(
        "Do you really want to delete this feedback? This action cannot be undone",
      )
    ) {
      await deleteLessonFeedback(feedbackId);
      onFeedbackDeleted();
    }
  }

  const showedFeedbacks = user.lessonFeedbacks.filter((lf) => !lf.noShow);

  const avgParticipation =
    showedFeedbacks.length <= 0
      ? 0
      : showedFeedbacks.reduce((acc, lf) => acc + lf.participation, 0) /
        showedFeedbacks.length;

  const avgTargetLanguage =
    showedFeedbacks.length <= 0
      ? 0
      : showedFeedbacks.reduce((acc, lf) => acc + lf.targetLanguage, 0) /
        showedFeedbacks.length;
  return (
    <div className={"space-y-4"}>
      <Card>
        <FeedbackReportScore
          label={"Avg Participation"}
          value={avgParticipation}
        />
      </Card>
      <Card>
        <FeedbackReportScore
          label={"Avg Target language"}
          value={avgTargetLanguage}
        />
      </Card>
      {user.lessonFeedbacks.map((lf, i) => (
        <Card key={i}>
          <div>
            <div className={"flex justify-between"}>
              <p>
                Taken on <b>{formatDate(lf.classRoomStartDateUtc)}</b> with
                teacher{" "}
                <b>
                  {lf.teacher.givenName} {lf.teacher.familyName}
                </b>
              </p>
              {loading ? (
                <PrimaryLoadingIndicator size={LoaderSize.Small} />
              ) : (
                <div
                  className={"ml-2 cursor-pointer"}
                  onClick={async () => {
                    await deleteFeedback(lf.id);
                  }}
                >
                  <TrashIcon className={"w-5 text-red-600"} />
                </div>
              )}
            </div>
            {lf.noShow ? (
              <p className={"text-red-600 font-bold"}>No Show</p>
            ) : (
              <div>
                <p>Participation: {lf.participation}</p>
                <p>Target language: {lf.targetLanguage}</p>
                {lf.notes ? <p>Notes: {lf.notes}</p> : null}
              </div>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
}
