import { Select, SpinnerContainer } from "@normasteaching/norma-ui";
import { useUserClassRoomPlans } from "./useUserClassRoomPlans.ts";
import { ClassRoomPlanFragmentFragment } from "../../gql/graphql.ts";
import dayjs, { Dayjs } from "dayjs";
type Props = {
  userId: string;
  filterPlans?: (
    plans: ClassRoomPlanFragmentFragment[],
  ) => ClassRoomPlanFragmentFragment[];
  onChange: (planId: string | null) => void;
  selectedPlanId?: string;
};
export function ClassRoomPlanSelect({
  userId,
  onChange,
  selectedPlanId,
  filterPlans = (plans) => plans,
}: Props) {
  const { classRoomPlans, loading } = useUserClassRoomPlans(userId);
  function formatWeekDate(weekDate: Dayjs): string {
    return `${weekDate.utc().format("ddd DD MMM YYYY")}`;
  }
  return (
    <SpinnerContainer loading={loading}>
      <Select
        clearable
        value={selectedPlanId}
        options={filterPlans(classRoomPlans).map((plan) => {
          return {
            id: plan.id,
            name: `${formatWeekDate(dayjs(plan.startedAtWeekUtc))} - ${formatWeekDate(dayjs(plan.endedAtWeekUtc))}`,
          };
        })}
        onChange={(planId: string | null) => {
          onChange(planId);
        }}
      />
    </SpinnerContainer>
  );
}
