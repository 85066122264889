import { useEffect, useRef } from "react";
import { ComputerDesktopIcon } from "@heroicons/react/24/solid/index.js";
import { classNames } from "./Utils/classNames.ts";
import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/outline/index.js";

export function UploadComponent({
  text,
  onChange,
  value,
  allowedMimeType,
}: {
  text: string;
  value?: File[];
  onChange: (value: File[]) => void;
  allowedMimeType?: string[];
}) {
  useEffect(() => {
    function handler(e: DragEvent) {
      e.preventDefault();
    }

    window.addEventListener("dragover", handler, false);
    window.addEventListener("drop", handler, false);

    return () => {
      window.removeEventListener("dragover", handler, false);
      window.removeEventListener("drop", handler, false);
    };
  }, []);
  const fileInputRef = useRef<HTMLInputElement>(null);

  function isValidMime(type: string): boolean {
    if (!allowedMimeType) return true;
    return allowedMimeType.includes(type);
  }

  function validateFilesOrThrow(fileList: FileList): void {
    for (const file of fileList) {
      if (!isValidMime(file.type))
        throw new Error(`Invalid file type: ${file.type} of file ${file.name}`);
    }
  }

  function convertFileListToFile(fileList: FileList): File[] {
    return Array.from({
      length: fileList.length,
    }).map((_, i) => {
      const f = fileList.item(i);
      if (!f) throw new Error(`File not found for index ${i}`);
      return f;
    });
  }

  return (
    <div>
      <div
        onDrop={(e) => {
          const files = e.dataTransfer.files;
          validateFilesOrThrow(files);
          onChange(convertFileListToFile(files));
        }}
        className={
          "flex items-center justify-center flex-col text-center p-4 border border-dashed border-slate-300 rounded-lg space-y-2"
        }
      >
        <p>{text}</p>
        <p className={"text-slate-400"}>or</p>
        <a
          onClick={(e) => {
            e.preventDefault();
            fileInputRef.current?.click();
          }}
          className={
            "px-4 py-1.5 border border-primary rounded-lg text-primary hover:bg-primary hover:text-white flex items-center space-x-2"
          }
          href={""}
        >
          <span>Search in files</span>
          <ComputerDesktopIcon className={"w-5 h-5"} />
        </a>
        <input
          onChange={(e) => {
            const files = e.target.files;
            console.log(`files`, files);
            if (!files) return;
            validateFilesOrThrow(files);
            onChange(convertFileListToFile(files));
          }}
          ref={fileInputRef}
          accept={allowedMimeType?.join(" ")}
          type={"file"}
          className={"invisible w-px h-px"}
        />
      </div>
      {value ? (
        <div className={"flex flex-wrap mt-2"}>
          {value.map((file, i) => {
            return (
              <div
                title={file.name}
                key={i}
                className={classNames(i > 0 ? "mr-2" : "")}
              >
                <div
                  className={classNames(
                    "px-3 py-6 rounded-lg border border-slate-300 flex items-center justify-center relative",
                  )}
                >
                  <DocumentIcon className={"h-5 w-5"} />
                  <div>
                    <XMarkIcon
                      className={
                        "w-4 h-4 text-red-700 absolute right-1.5 top-1.5 cursor-pointer"
                      }
                      onClick={() => {
                        onChange(value.filter((_, index) => index !== i));
                      }}
                    />
                  </div>
                </div>
                <p
                  className={
                    "overflow-ellipsis overflow-hidden max-w-[70px] whitespace-nowrap"
                  }
                >
                  {file.name}
                </p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
