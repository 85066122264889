import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../Utils/classNames.ts";

export function SideMenuButton({
  label,
  to,
  icon,
  isActive,
}: {
  to: string;
  label: string;
  icon: ReactNode;
  isActive?: boolean;
}) {
  const navigate = useNavigate();

  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        navigate(to);
      }}
      className={classNames(
        "px-4 cursor-pointer py-2 shadow-slate-200/40 rounded-lg w-full text-center flex items-center space-x-2",
        isActive
          ? "bg-primary text-white hover:brightness-110"
          : "hover:bg-slate-200",
      )}
    >
      {icon} <span>{label}</span>
    </a>
  );
}
